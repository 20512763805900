import React from 'react';
import './styles.css';

export default props => (
     
    <section className='contentBody container-fluid'>
        {props.children}
    </section>
   
);

