export const localConstants = {
  //resultpage-detailspage
  setDetailsModelId: "LOCAL_SET_DETAILS_MODEL_ID",
  deleteDetailsModelsId:"USER_DELETE_DETAILS_MODEL_ID",

  setLocation:"LOCAL_SET_LOCATION",
  
  toggleSideBar:"LOCAL_TOGGLE_SIDE_BAR",
  setReportList:"LOCAL_REPORT_LIST",

  setLastRoute:"LOCAL_SET_LAST_ACCESS_ROUTE",
  clearLastRoute:"LOCAL_CLEAR_LAST_ACCESS_ROUTE",

  startTimeToken: "LOCAL_START_TIME_TOKEN",
  updateTimeToken: "LOCAL_UPDATE_TIME_TOKEN",

}