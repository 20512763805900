import React, { Component } from 'react';

import './styles.css';

import { getNestedValue, formatDateLong, getFieldName } from './index';
import ItemListDefault from '../components/list_Item_Default';
import ItemListRelated from '../components/list_Item_Related';
import { djangoActions } from '../redux/actions';
import { indexesOfList, fieldsToImage, fieldsToLabels } from '../config'
class RenderEntityItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
        entityList: [],
        valor_index: '',
        }
        this.setData = this.setData.bind(this);
    }
    setData(data) {
        const { dispatch } = this.props;
        dispatch(djangoActions.dataStore(data));
    }
    render() {
        const { entity } = this.props;
        const valor_index = entity._index.split('_')[0]; 
        if (indexesOfList[valor_index]) {
            let { image, title, subtitle, url, description, date } = indexesOfList[valor_index];
            let labelTitle=title
            let labelSubtitle=subtitle
            let labelDescription=description
            let labelDate=date
            let imgPath = getNestedValue(entity, image) ? fieldsToImage[image] : "";
            title = getNestedValue(entity, title);
            subtitle = getNestedValue(entity, subtitle);
            image = getNestedValue(entity, image);
            description = getNestedValue(entity, description);
            date = getNestedValue(entity, date);
            url = getNestedValue(entity, url);
            let highlight = entity.highlight;
            return (this.props.list_right ? 
            <ItemListRelated
                _id={entity._id}
                _index={entity._index} 
                title={title}
                subtitle={subtitle}
                />  :    
            <ItemListDefault
                allfields={entity}
                _id={entity._id}
                _index={entity._index}
                title={title}
                labelTitle={getFieldName(fieldsToLabels[valor_index], labelTitle)}
                subtitle={subtitle}
                labelSubtitle={getFieldName(fieldsToLabels[valor_index], labelSubtitle)}
                image={imgPath+image}
                description={description}
                labelDescription={getFieldName(fieldsToLabels[valor_index], labelDescription)}
                highlight={highlight}
                date={formatDateLong(date)}
                labelDate={getFieldName(fieldsToLabels[valor_index], labelDate)}
                url={url}
            />)
        } else {
            return <h6>Componente não encontrado.</h6>
        }
    }
}



export default (RenderEntityItem);


