const vinculos = {
	cpf: ['vitima_cpf', 'autor_cpf', 'cpf', 'CPF', 'CPF_RESPONSAVEL_INFORMACAO', 'co_cpf'],
	nome: ['vitima_nome', 'autor_nome', 'nome', 'Nome_Interno', 'Nome_Visitante', 'nome_socio', 
	'name', 'envolvido_nome', 'no_pessoa' ]
}
export const itensRelated = {
	'sisbo-personagens': [
	// ComponenteID------Label------Indice/Field
		{id_group:'codigo'},
		{list_hight: ['Boletim', 'codigo','sisbo-boletins']},
		{list_hight: ['Personagens', 'codigo', 'sisbo-personagens']},
		{list_vinculated: ['Vinculados ao CPF', 'cpf', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'nome', vinculos.nome ]},
	],
	'sisbo-boletins': [ 
		{id_group:'codigo'},
		{list_hight: ['Personagens', 'codigo', 'sisbo-personagens']},
	],
	'sisprocep-procedimentos': [ 
		{id_group:'codigo'},
		{list_hight: ['Vítima(s)', 'codigo', 'sisprocep-vitimas']},
		{list_hight: ['Indiciado(s)', 'codigo', 'sisprocep-autores']},
	],
	'sisprocep-vitimas': [ 
		{id_group:'codigo'},
		{list_hight: ['Inquérito(s)', 'codigo', 'sisprocep-procedimentos']},
		{list_vinculated: ['Vinculados ao CPF', 'vitima_cpf', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'vitima_nome', vinculos.nome ]},
		
	],
	
	'sisprocep-autores': [ 
		{id_group:'codigo'},
		{list_hight: ['Procedimento(s)', 'codigo', 'sisprocep-procedimentos']},
		{list_vinculated: ['Vinculados ao CPF', 'autor_cpf', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'autor_nome', vinculos.nome ]},
	],
	'sic-pessoas': [
		{id_group: 'id'},
		{list_vinculated: ['Vinculados ao CPF', 'cpf', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'name', vinculos.nome ]},
		{list_img: {
			label:'Imagens', 
			index:'sic-fotos', 
			fieldName:'character_id.raw', 
			filePath:'avatar_file_patch', 
			fileName:'avatar_file_name',
		 	}
		},
		// {list_img: ['Imagens', 'sic-fotos', 'avatar_file_patch', 'avatar_file_name', vinculos.nome ]},
	],
	'sicaf-pessoas': [
		{id_group: 'id'},
		{list_vinculated: ['Vinculados ao CPF', 'cpf', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'nome', vinculos.nome ]},
		{list_img: {
			label:'Imagens', 
			index:'sicaf-fotos', 
			fieldName:'pessoa_id', 
			filePath:'', 
			fileName:'foto',
		 	}
		},
	],
	'agp-usuarios': [
		{id_group: 'id'},
		{list_vinculated: ['Vinculados ao CPF', 'cpf', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'nome', vinculos.nome ]},
	],
	'receita-pf': [
		{id_group: 'cnpj'},
		{list_hight: ['Empresa(s)', 'cnpj', 'receita-pj']},
	],
	'receita-pj': [
		{id_group: 'cnpj'},
		{list_hight: ['Sócio(s)', 'cnpj', 'receita-pf']},
	],
	'siapen-internos': [
		{id_group: 'Id_Interno'},
		{list_hight: ['Visitante(s)', 'Id_Interno', 'siapen-visitantes']},
		{list_vinculated: ['Vinculados ao CPF', 'CPF', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'Nome_Interno', vinculos.nome ]},
	],
	'siapen-visitantes': [
		{id_group: 'Id_Interno'},
		{list_hight: ['Interno visitado', 'Id_Interno', 'siapen-internos']},
		{list_vinculated: ['Vinculados ao CPF', 'CPF', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'Nome_Visitante', vinculos.nome ]},
	],
	'licitacoes-licitacoes': [
		{id_group: 'ID_LICITACAO'},
		{list_hight: ['Responsáveis', 'ID_LICITACAO', 'licitacoes-responsaveis']},
		{list_hight: ['Publicações', 'ID_LICITACAO', 'licitacoes-publicacao']},
		{list_vinculated: ['Vinculados ao CPF', 'CPF_RESPONSAVEL_INFORMACAO', vinculos.cpf ]},
		// Buscar itens associados
		// key-----------Label------------index_associado------param_associado----index_encadeiado-----param_encadeiado
		{list_chain: ['Licitantes', 'ass-licitacoes_participantes', 'ID_LICITANTE', 'licitacoes-licitantes', 'ID_LICITANTE', 'ID_LICITACAO' ]},
		{list_table: ['Itens da Licitação', 'ID_LICITACAO', 'licitacoes-itens' ]},
	],
	'licitacoes-licitantes': [
		{list_vinculated: ['Vinculados ao CPF', 'CPF', vinculos.cpf ]},
		// Buscar itens associados e encadeados
		// key-----------Label------------index_associado------param_associado----index_encadeiado-----param_encadeiado
		{list_chain: ['Licitações', 'ass-licitacoes_participantes', 'ID_LICITACAO', 'licitacoes-licitacoes', 'ID_LICITACAO', 'ID_LICITANTE' ]},
	],
	'licitacoes-responsaveis': [
		{id_group: 'ID_LICITACAO'},
		{list_hight: ['Licitações', 'ID_LICITACAO', 'licitacoes-licitacoes']},
		{list_vinculated: ['Vinculados ao CPF', 'CPF', vinculos.cpf ]},
	],
	'licitacoes-publicacao': [
		{id_group: 'ID_LICITACAO'},
		{list_hight: ['Licitações', 'ID_LICITACAO', 'licitacoes-licitacoes']},
		// {list_vinculated: ['CPF', 'CPF', vinculos.cpf ]},
	],
	'pmpimobile-atendimentos': [
		{id_group: 'id_atendimento'},
		{list_hight: ['Envolvido(s)', 'id_atendimento', 'pmpimobile-envolvidos']},
		// {list_vinculated: ['CPF', 'CPF', vinculos.cpf ]},
	],
	'pmpimobile-envolvidos': [
		{id_group: 'id_atendimento'},
		{list_hight: ['Atendimento', 'id_atendimento', 'pmpimobile-atendimentos']},
		{list_vinculated: ['Vinculados ao CPF', 'cpf', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'envolvido_nome', vinculos.nome ]},
	],
	'contratos-contratos': [
		// Identificação do grupo relacionado
		{id_group: 'ID_CONTRATO'},
		{list_hight: ['Responsáveis', 'ID_CONTRATO','contratos-responsaveis']},
		{list_table: ['Itens do Contrato', 'ID_CONTRATO', 'contratos-itens' ]},
		// {list_vinculated: ['CPF', 'CPF', vinculos.cpf ]},

	],
	'contratos-responsaveis': [
		{id_group: 'ID_CONTRATO'},
		{list_hight: ['Responsáveis', 'ID_CONTRATO', 'contratos-contratos']},
		{list_vinculated: ['Vinculados ao CPF', 'CPF', vinculos.cpf ]},
	],
	'arquivos-doe': [
		{id_group: 'file_path'}, 
		{download_list:['Itens Para Download','arquivos-doe' ]}
	],
	
	'sinesp-procedimentos': [
		// Identificação do grupo relacionado
		{id_group: 'id_procedimento'},
		{list_hight: ['Personagens', 'id_procedimento', 'sinesp-personagens']},
	],
	'sinesp-personagens': [
		{id_group: 'id_procedimento'},
		// Buscar itens associados
		{list_vinculated: ['Vinculados ao CPF', 'co_cpf', vinculos.cpf ]},
		{list_vinculated: ['Vinculados ao Nome', 'no_pessoa', vinculos.nome ]},
		{list_hight: ['Procedimentos', 'id_procedimento', 'sinesp-procedimentos']},
	],
}