import React, { Component } from "react";
import { connect } from 'react-redux';
import { globalConstants } from '../../redux/constants'
import { djangoActions, alertActions } from '../../redux/actions';
import { RedirectComponent } from './redirectComponent';
// import { utils } from '../../services/utils';
import { EmailModal } from '../loginPage/emailModal';
import './styles.css';
import Apresentacao from "../apresentacao/apresentacao";
import { Message } from '../../components/message_alert';
import { Link } from 'react-router-dom'
import Logo from "../../assets/sinfor_logo.png";

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
      password2: "",
      redirect: false,
      modal: false,
      modalType: ""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    const { password, password2 } = this.state;
    const { dispatch } = this.props;
    const { uid, token } = this.props.match.params;
    if (!password || !password2) {
      dispatch(alertActions.error("Preencha todos os campos para continuar!"));
    } else {
      if (password === password2) {
        const data = {
          "uid": uid,
          "token": token,
          "new_password": password
        }
        dispatch(djangoActions.recoveryPassword(data));
        this.setState.redirect = true;
      } else {
        dispatch(alertActions.error("As senhas não correspondem!"));
      }

    }
  }

  handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value
    });
  }


  reset_password(email) {
    const { dispatch } = this.props;
    dispatch(djangoActions.resetPassword(email))
    this.closeModal()
  }




  showModal(type, event) {
    event.preventDefault();
    this.setState({ modal: true, modalType: type });
  }


  closeModal() {
    const { dispatch } = this.props;
    // fechando modal
    this.setState({
      modal: false
    });

    //limpando o alert após um tempo
    setTimeout(() => {
      dispatch(alertActions.clear());
    }, 5000);
  }




  render() {
    const { from } = { from: { pathname: `${globalConstants.baseAppName + '/'}` } }
    const { alert } = this.props;
    const { redirect,
      //  redirectMsg,
      // time, 
      modal,
      modalType } = this.state

    const modals = {
      "recoveryPass": {
        "request": (email) => this.reset_password(email),
        "show": this.state.modal,
        "onHide": (event) => this.closeModal(event),
        "textTitle": "Esqueceu sua senha?",
        "textBody": "Caso tenha esquecido a senha, solicite a troca digitando o email cadastrado.",
      }
    }
    let alertComponent = alert.message ? <Message icon={alert.icon} text={alert.message} alert={alert.type} /> : ""

    return (
      <div className="container-login">
        
        <Apresentacao idHref={'#alterar'} label={'Alterar Senha'} />
        

        <div id="alterar" className="div-hight">
          <div className="imgLogoSinfor">
            <img  src={Logo} alt="Sinfor logo" />
          </div>
          <div className="div-hight-center">
            <h2>Alterar Senha</h2>
          <h6>Preencha os campos, para redefinir sua senha.</h6>
         {alertComponent}
          {redirect && <RedirectComponent from={from} dispatch={this.props.dispatch} />}
          <form onSubmit={this.handleSubmit}>
            <input className='login-input' type="password" name="password" value={this.state.password} placeholder="Nova Senha" onChange={this.handleChange} />
            <input className='login-input' type="password" name="password2" value={this.state.password2} placeholder="Confirmação da senha" onChange={this.handleChange} />
            <button className='activate-button' type="submit"><h5>Enviar</h5></button>
            <hr />
            <div style={{ paddingTop: "10px" }}>
              <center>
                <div className="fontSmall link-button"><Link to={globalConstants.baseAppName + "/login"}>Fazer Login</Link></div>
              </center>
            </div>
            <div style={{ paddingTop: "10px" }}>
              <center>
                <div className="fontSmall">ou</div>
              </center>
            </div>
                            
            <div style={{ paddingTop: "10px" }}>
              <center>
                <div className="fontSmall link-button"
                  onClick={this.showModal.bind(this, "recoveryPass")} >Tentar nova alteração
                  </div>
              </center>
            </div>
            {modal && <EmailModal alert={alert} values={modals[modalType]} />}
          </form>
        </div>
        </div>
      </div>
    );
  }
}



function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert

  };
}



export default connect(mapStateToProps)(ResetPassword);