import { localConstants, djangoConstants, globalConstants } from '../../constants';
// import { authentication } from '../../../services/auth'
export const localActions = {
  selectAppIndex,
  setReportList,
  toggleSidebar,
  setDetailsModelId,
  deleteDetailsModelId,
  setLastRoute,
  clearLastRoute,
  startTimeToken,
  hasUpdateTimeToken,
  redirect,
  setLocation,
}


function setLocation(location){
  return dispatch => {
    dispatch({ type: localConstants.setLocation, payload:{location} });
}
}

function selectAppIndex(app_index, isOpen){
  return dispatch => {
    dispatch({ type: djangoConstants.setAppIndex, payload:{app_index} }) 
    dispatch({ type: localConstants.toggleSideBar, payload:{isOpen} });
}
}

function setReportList(reportList) {
  return dispatch => {
    dispatch({ type: localConstants.setReportList, payload:{reportList} });
  }
}

function toggleSidebar(isOpen) {
  return dispatch => {
      dispatch({ type: localConstants.toggleSideBar, payload:{isOpen} });
  }
}

function setDetailsModelId(id) {
  return dispatch => {
      dispatch({ type: localConstants.setDetailsModelId, payload:{id}});
  }
}

function deleteDetailsModelId() {
  return dispatch => {
      dispatch({ type: localConstants.deleteDetailsModelsId });
  }
}

function setLastRoute(lastRoute){
  return dispatch=>{
    dispatch({type:localConstants.setLastRoute, payload:{lastRoute}})
  }
}

function clearLastRoute(){
  return dispatch=>{
    dispatch({type:localConstants.clearLastRoute})
  }
}


function startTimeToken(){
  return dispatch =>{
    dispatch({type:localConstants.startTimeToken})
  }
}


function hasUpdateTimeToken(expireTime){
  return dispatch=>{
    dispatch({type:localConstants.updateTimeToken})
  }
}

function redirect(to){
  const path = to || `${globalConstants.baseAppName+'/'}`;
  window.location.assign(path);    
    
}