import React, { Component } from 'react';

import './folder.css';
class Item extends Component {
  render() {
    const { type } = this.props.data;
    const { typeLine } = this.props;
    const types = {
      folder:"Pasta",
      header:"Tipo",
    }
      const itens = {
      header: {icon: null, name: this.props.data.name, type:types[type], description:this.props.data.description },
      folder: {icon: 'fas fa-folder fa-2x', name: this.props.data.name, type:types[type], description:this.props.data.description },
    }
    const item = itens[type];
    return (
      <div className={`row ${typeLine}-row hr-horizontal`}>
        <div className='col col-name card-text'>{item.icon? <i className={item.icon}/>:null} <div className="left-space">{item.name}</div></div>
        <div className='col col-type card-text'>{item.type}</div>
        <div className='col card-text'>{item.description}</div>
      </div>
    );  
  }
} 
export default Item;