import React from 'react';
import { PdfDocument } from "./pdfDocument";
import { PDFViewer } from '@react-pdf/renderer';

import './pdf.css';

export const PDFView = (props) => (
  <PDFViewer className="pdf-viewer">
  <PdfDocument data={props.listItem} usuario={props.usuario} />
  </PDFViewer>
);