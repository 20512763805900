import React, { 
  // useState, 
  useEffect} from 'react';
import { Marker, FeatureGroup, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";

import { features } from './places.js';

function readTextFile(file, callback) {
  var rawFile = new XMLHttpRequest();
  rawFile.overrideMimeType("application/json");
  rawFile.open("GET", file, true);
  rawFile.onreadystatechange = function() {
      if (rawFile.readyState === 4 && rawFile.status === "200") {
          callback(rawFile.responseText);
      }
  }
  // rawFile.send(null)
}

 
var fetchData = function fetchData(url, options) {
  let request = fetch(url, options);

  return request
    .then(r => r.json())
    .then(data => data.features);
}

export default function GeojsonLayer ({url, cluster}) {
  // const [data, setData] = useState([]);
      //usage:
    readTextFile(url, function(text){
      var data = JSON.parse(text);
      console.log(data);
    });
  useEffect(()=> {
    if (url) {
      const abortController = new AbortController();

      fetchData(url, { signal: abortController.signal }).then(data => {
        // setData(data);
      });

      // cancel fetch on component unmount
      return () => {
        abortController.abort();
      };
    }

  }, [url]);

  var GroupComponent = cluster ? MarkerClusterGroup : FeatureGroup;
  // features = features
  return (
      <GroupComponent>
        {features.map(f => (
            <Marker
              key={JSON.stringify(f.properties)}
              position={f.geometry.coordinates.reverse()}
            >
              <Popup minWidth={200} closeButton={false}>
                <div style={{backgroundColor:"white", color:"black"}}>
                  <b>{f.properties.region}</b>
                  <p>Local: {f.properties.name}</p>
                  <p>Data e hora da ocorrência: {f.properties.data}</p>
                  <p>Tipo de ocorrência: {f.properties.ocorrence}</p>
                </div>
              </Popup>
            </Marker>
        ))}
      </GroupComponent>
  );
}