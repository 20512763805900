import { elasticServices } from '../../../services/elasticServices';
import { elasticConstants, globalConstants, djangoConstants } from '../../constants';
import { alertActions } from '../../actions';

export const elasticActions = {
  setFields,
  searchMessage,

  postComment,
  getComments,
  deleteComments,
  putComments,
  setEditComment,
  cancelEditComment,

}

function getAppIndex(array) {
  return array.join(',');
  }

function setFields(listIndex) {
  let fields = [];
  let app_index = getAppIndex(listIndex);
  return async dispatch => {
    await listIndex.map(async (index) =>{
      await elasticServices.getFields(index) 
      .then(indexFields=>{
        fields = fields.concat(indexFields);
      })
      .catch(error=>{
        dispatch(alertActions.warning("Não foi possivel preparar os dados")) 
      })
      
      dispatch({type:elasticConstants.setFields, payload:{fields:fields}})
    })
    dispatch({ type: djangoConstants.setAppIndex, payload: { app_index } })
  }
}


function searchMessage(searchTerm, state) {
  function redirect () {
    const path = `${globalConstants.baseAppName+ '/results'}`
    setTimeout(() => {
      window.location.assign(path)
    }, 300);
  }
  
  return dispatch => {
    if (searchTerm.length >= 3) {
      dispatch({ type: elasticConstants.searchSuccess, payload:{searchTerm} });
      redirect();
    }else {
      let message = 'O termo digitado é muito curto!'
          // localStorage.setItem("searchTerm", searchTerm)
          dispatch(alertActions.warning(message))
          dispatch(alertActions.clearIn());
    }
  }
}


// comments

function postComment(data) {
  const newTime = new Date();
  data.time = newTime;
  return dispatch => {
    elasticServices.postComment(data) 
      .then(response => {
          dispatch(alertActions.success("Salvo Com Sucesso!"))
          dispatch(alertActions.clearIn());
        }).catch(error => {
          dispatch(alertActions.error("Erro ao Salvar o comentário, Contate os Administradores de Sistema"))
        })
  }
}

function getComments(id) {
  return dispatch => {
    elasticServices.getComments(id)
      .then(response => {
        dispatch({ type: elasticConstants.getComments, payload:{comments:response} });
      }).catch(error => {
        dispatch(alertActions.error("Não foi possivel encontrar os comentários, contate os administradores do sistema."))
        dispatch(alertActions.clearIn());
      })
  }
}



function deleteComments(id){
  return dispatch =>{
      elasticServices.deleteComments(id)
          .then(response=>{
              dispatch(alertActions.success("Comentário excluído com sucesso."))
          }).catch(error => {
              dispatch(alertActions.error("Comentário não pode ser excluído."))
          })
      }
}                                         


function putComments(id, data){
  return dispatch =>{
    elasticServices.putComments(id, data)
      .then(response=>{
        dispatch(alertActions.success("Comentário editado com sucesso."))
      }).catch(error => {
        dispatch(alertActions.error("Comentário não pode ser editado, tente novamente mais tarde."))
      })
      }
}       


function setEditComment(comment){
  return dispatch =>{
    dispatch({type:elasticConstants.editComments, payload:{edit_comment:comment}})
  }
}

function cancelEditComment(){
  return dispatch =>{
    dispatch({type:elasticConstants.cancelEditComments})
  }

}


