import React from 'react'

import imagem from '../assets/image-regular.png'
import './styles.css'
export default props => (
    <div className="w3-theme-l1 d-flex flex-row" style={{alignItems:'center' }}>
        <div>
            <img src={props.image || `${imagem}`} className="w3-circle "
                style={{ width: "56px", padding:10}} alt="Avatar" />
        
                <span className="w3-large"><strong>{props.name}</strong></span>
        </div>
      
         <div className="acttions" >
            {props.children}
        </div>
    </div>
)