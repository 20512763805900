import React from 'react';

import { Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import LogoSSP from '../../../assets/brasao_ssp.jpg'
import LogoDINTE from '../../../assets/dinte.jpg'

export default () => (
  <View style={styles.container} fixed>
       <View style={styles.containerRow}>
      <View style={styles.leftColumn}>
        <Image style={styles.image} src={ LogoSSP !== null ? LogoSSP : ''} />
      </View>
      
      <View style={styles.header}>
          <Text>GOVERNO DO ESTADO DO PIAUÍ</Text>
          <Text>SECRETARIA DE SEGURANÇA PÚBLICA</Text>
          <Text>DIRETORIA DE INTELIGÊNCIA ESTRATÉGICA</Text>
                
      </View>

       <View style={styles.rightColumn}>
        <Image style={styles.image} src={ LogoDINTE !== null ? LogoDINTE : ''} />
      </View>
      </View>

      <View style={styles.containerRow}>
        <View style={styles.subHeader}>
          <Text>RELATÓRIO DE PESQUISA INTEGRADA</Text>
        </View>
          </View>

  </View>
);

// Register font
Font.register({ family: 'Arial', src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Bold.ttf' });

const styles = StyleSheet.create({
  container: {
    flexDirection: 'colunm',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
    fontFamily: 'Arial',
    marginBottom: 10,
  },
  containerRow: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  leftColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
},
rightColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
},
header: {
    fontSize: 12,
    textTransform: 'uppercase',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    alignSelf: 'flex-end',
    flexGrow: 6,
    marginBottom: 25,
    alignItems: 'center',
  },
 
  subHeader: {
    fontSize: 14,
    textAlign: 'center',
    // fontWeight: 200,
    justifySelf: 'center',
  },
 
  image: {
    marginVertical: 5,
    marginHorizontal: 5,
    height: 68,
  },
});