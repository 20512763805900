import { combineReducers } from 'redux';

import { authenticationReducer } from './authentication.reducer';
import { alertReducer } from './alert.reducer';
import { commentsReducer, editCommentsReducer} from './comments.reducer';
import { indexedReducer } from './indexed.reducer';
import { getUserReducers } from './user.reducer';
import { monitoringReducer } from './monitoring.reducer';
import { navigationReducer } from './navigation.reducer';
import { searchedReducer } from './search.reducer';
import { setFields } from './fields.reducer';
import { registrationReducer } from './registration.reducer';
import { reportReducer } from './report.reducer';
import { setLevelList } from './admin.reducer';

const rootReducer = combineReducers({
  admin: setLevelList,
  authentication: authenticationReducer,
  alert: alertReducer,
  alerts: monitoringReducer,
  comments: commentsReducer,
  editComment: editCommentsReducer,
  fields: setFields,
  indexed: indexedReducer,
  navigation: navigationReducer,
  searched: searchedReducer,
  user: getUserReducers,  
  registration: registrationReducer,
  report: reportReducer,

})

export default rootReducer