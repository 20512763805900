import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
// import img1 from '../assets/brasao_ssp.jpg'
import './carousel.css';
import { IconContext } from "react-icons";
import { FcFilledFilter, FcAcceptDatabase, FcFlashOn, FcLink, 
    FcAlarmClock, FcCollaboration } from 'react-icons/fc'
// import LogoDINTE from "../assets/logoDinte.png";

const CarouselComponent = () => {
    const [products, setProducts] = useState([]);
    
    const data = [
        {
            "id": "1000", 
            "name": "Agilidade",
            "description": "A maneira mais rápida de encontrar a informação que procura em um só lugar.",
            "image": <FcFlashOn/>
        },
        {
            "id": "1001", 
            "name": "Vínculos",
            "description": "Os resultados das buscas poderão apresentar conteúdos relacionados em diversas bases por meio do Nome ou CPF.",
            "image": <FcLink/>
        },
        {
            "id": "1002", 
            "name": "Colaborações",
            "description": "Usuários podem colaborar enviando informaçoes sobre um Documento.",
            "image": <FcCollaboration/>
        },
        {
            "id": "1003", 
            "name": "Integridade",
            "description": "O SINFOR não substitui os dados em suas bases originais.",
            "image": <FcAcceptDatabase/>
        },
        {
            "id": "1004", 
            "name": "Filtros",
            "description": "Diversos filtros poderão ser aplicados em uma Base de dados específica.",
            "image": <FcFilledFilter/>
        },
        {
            "id": "1005", 
            "name": "Alertas",
            "description": "Monitore a ocorrência de termos importantes. Agende um termo ou frase e receba notificações quando detectarmos uma ocorrência.",
            "image": <FcAlarmClock/>
        },
        
    ]

    useEffect(() => {
        setProducts(data.slice(0,6));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const productTemplate = (product) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="mb-3">
                    <IconContext.Provider value={{ className: "initial-class-icons" }}> 
                            {product.image}
                    </IconContext.Provider>
                    </div>
                    <div>
                        <h4 style={{fontWeith: 'bold', color:"#333"}} className="mb-3">{product.name}</h4>
                        <div style={{fontSize:16, color:"#333"}}>{product.description}</div>
                        
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='container-apres'>
            <div className='img-carousel' />
            <Carousel value={products} numVisible={1} numScroll={1}
                    className="carousel" circular
                    autoplayInterval={5000} 
                    itemTemplate={productTemplate}  />
        </div>
    )
}

export default CarouselComponent;