import React from 'react';

import AlertItem from './alertItem';

import { getExternalName, getTerm, getType } from './functions';

import { TableButton } from '../../components/button';
import { Message } from '../../components/message_alert';

//table
import { Table, Thead, Th, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Card, Form } from 'react-bootstrap';

const AlertList = ({ alerts, handleItemClick, deleteItem, createItem }) => {
    let alerts_list = alerts.map((alert, index) => {
        let term = getTerm(alert);
        let name = getExternalName(alert.name);
        let type = getType(alert.yaml);
        return (
            <AlertItem  key={index} count={index+1} index={alert.yaml.index} title={name} term={term} type={type}
             onClick={(id) => handleItemClick(alert._id)} deleteItem={deleteItem} id={alert._id} />            
        )
    });

    return (  alerts_list.length > 0 ?   
        <Form >
        <Card >
          {/** title */}
          <Card.Title className="w3-theme-l1 card-header">{'Gerenciar Alertas'}</Card.Title>
      
                <Table className="table table-striped" id='minhaTabela'>
                {/*
                <Table >
              */}
              <React.Fragment>
        <Thead>
            <Tr>
                <Th>#</Th>
                <Th>Nome</Th>
                <Th>Termo/Frase</Th>
                <Th>Tipo de Alerta</Th>
                <Th>Base Selecionada</Th>
                <Th>       </Th>
                <Th>       </Th>
            </Tr>
        </Thead>
        <Tbody>
            {alerts_list}
            <Tr className='no-hover'>
                <Td colSpan='5'/>
                <Td colSpan='2'>
                    <TableButton className="btn save-btn" icon='add' label='Adicionar Alerta' onClick={createItem}/>
                </Td>
            </Tr>
        </Tbody>
        </React.Fragment>
        </Table>
        </Card>
        </Form>
         :
        <React.Fragment>
           
             <Message text="Você não possui alertas configurado" alert="info" icon="info" />

            
            <div className='no-hover flex-row row-reverse'>
                <Td colSpan='6'/>
                <Td colSpan='1'>
                    <TableButton className="btn save-btn" icon='add' label='Adicionar Alerta' onClick={createItem}/>
                </Td>
            </div>
        </React.Fragment>
    )
}

export default AlertList;


