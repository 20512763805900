import React from 'react'

import './styles.css'


export default props => (
 
<div>{props.children}</div>
)

