export const fieldsToType = {
'sisbo-personagens': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "codigo": "hide",
    "personagem_id": "hide",
},
'sisbo-boletins': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "codigo": "hide",
    "narrativa": "longText",
    "data_ocorrencia": "date",
    "data_encerramento": "date",
},
'ppe-boletins': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "ID_PROCEDIMENTO": "hide",
    "ID_PROCEDIMENTO_PAI": "hide",
    "ID_PROCEDIMENTO_ADITADO": "hide",
    "TX_RELATO_HISTORICO": "longText",
    "DATA_REGISTRO": "date",
},
'sisbo-naturezas': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "codigo": "hide",
    "natureza_infracao_id": "hide",
},

'sisprocep-procedimentos': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "codigo": "hide",
    "data_instauracao": "date",
},
'sisprocep-vitimas': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "codigo": "hide",
    "id_vitima": "hide",
},
'sisprocep-autores': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "codigo": "hide",
    "id_autor": "hide",
},
'sisprocep-naturezas': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "codigo": "hide",

},

"receita-pf": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "data_entrada": "date",
    "cod_pais_ext": "hide",
    "nome_pais_ext": "hide",
    "cod_qualif_repres": "hide",
    "perc_capital": "hide",
    "cod_qualificacao": "hide",
},
"receita-pj": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
     "cod_pais" : "hide",
    "cod_municipio" : "hide",
    "nm_cidade_exterior" : "hide",
    "data_situacao" : "date",
    "cod_nat_juridica" : "hide",
    "data_sit_especial" : "hide",
    "motivo_situacao" : "hide",
    "data_inicio_ativ" : "date",
    "qualif_resp" : "hide",
    "sit_especial" : "hide",
},

"agp-usuarios": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "id": "hide",
},

"sic-pessoas": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "id": "hide",
    "@timestamp": "hide",
    "text": "longText",
    "file_content": "longText",
    "description": "longText",
    "avatar_file_patch": "hide",
    "birth" : "dateShort",
    "created_at" : "date",
    "updated_at" : "date",
    "finger_operation_date" : "date",
},
"sicaf-pessoas": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "id": "hide",
    "@timestamp": "hide",
    "foto" : "hide",
    "legenda" : "hide",
    "data_nascimento" : "dateShort",
    "data_confirmado" : "date",
    "data_instauracao" : "date",
    "data_instauracao_ipl" : "date",
},
"arquivos-dinte": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
},
"arquivos-type": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
},
"arquivos-doe": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
},

"siapen-internos": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "Data_Processo": "date",
    "Data_Condenacao": "date",
    "Data_Prisao": "date",
    "Data_IP": "date",
    "Data_liberacao": "date",
    "Data_Nascimento" : "dateShort",
    "Data_Entrada": "date",
    "Data_Saida": "date",
    "Id_Visitante": "hide",
    "Id_Interno": "hide",
    
},
"siapen-visitantes": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "Id_Visitante": "hide",
    "Id_Interno": "hide",
    "Data_Nascimento" : "dateShort",
    "Data_Cadastro" : "date",
    "Data_da_Visita" : "date",
    "created_at" : "date",
    "updated_at" : "date",
},
"licitacoes-licitacoes":  {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    ID_LICITACAO: "hide",
    ID_UNIDADE_GESTORA: "hide",
    ID_MODALIDADE: "hide",
    ID_NATUREZA_OBRA: "hide",
    ID_PREGOEIRO_LEILOEIRO: "hide",
    ID_REGIME_EXECUCAO: "hide",
    ID_RESPONSAVEL_HOMOLOGACAO: "hide",
    ID_RESPONSAVEL_INFORMACAO: "hide",
    ID_STATUS_LICITACAO: "hide",
    ID_TIPO_ESPECIFICACAO_OBJETO: "hide",
    ID_TIPO_FORMA_ADJUDICACAO_OBJETO: "hide",
    ID_TIPO_LICITACAO: "hide",
    ID_TIPO_OBJETO: "hide",
    ID_TIPO_OBRA: "hide",
    

},
"licitacoes-responsaveis": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    ID_LICITACAO: "hide",
},
"licitacoes-publicacao": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    DATA_ULT_ALTERACAO: "hide",
    ATIVO: "hide",
    ID_MEIO_PUBLICACAO: "hide",
    ID: "hide",
    ID_LICITACAO: "hide",
},
"licitacoes-licitantes": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    ID_LICITANTE: "hide",
    TIPO_LICITANTE: "hide",
    VERSAO: "hide",
    ID_UNIDADE_GESTORA: "hide",
    DATA_ULT_ALTERACAO: "hide",
},
"licitacoes-itens": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
},

"pmpimobile-atendimentos": {
    "_id": "hide",
    "id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "data_criacao_atend" : "date",
    // "data_encerramento" : "date",
    "data_fato" : "date",
    "id_atendimento" : "hide",
    "id_endereco" : "hide",
    "id_veiculo" : "hide",
    "envolvido_data_nascimento" : "dateShort",
    "id_envolvido" : "hide",
    "id_natureza" : "hide",
    "id_atendimento_arma" : "hide",
    "id_atendimento_envolvido" : "hide",
    "data_hora_encerramento" : "date",
},
"pmpimobile-envolvidos": {
    "id_atendimento" : "hide",
    "id_envolvido" : "hide",
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
},

"contratos-contratos": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    ID_ORGAO_GERENCIADOR_ATA: "hide",
    ID_CONTRATO: "hide",
    ID_CONTRATADA: "hide",
    ID_LICITACAO: "hide",
    ID_REGIME_EXECUCAO: "hide",
    ID_RESPONSAVEL_INFORMACAO: "hide",
    ID_STATUS_CONTRATO: "hide",
    ID_TIPO_CONTRATO: "hide",
    ID_TIPO_PROCEDIMENTO: "hide",
    ID_UNIDADE_GESTORA: "hide",
    ID_TIPO_ESPECIFICACAO_OBJETO: "hide",
    ID_TIPO_INSTRUMENTO: "hide",
    VERSAO: "hide",
    COD_CONTRATO_SIAF: "hide",
    TIPO_ORIGEM_ATA: "hide",
},

"contratos-responsaveis": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "ID_CONTRATO": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
},
"contratos-itens": {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
},
'sinesp-procedimentos': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "id_procedimento": "hide",
    "dt_registro": "date",
    "dt_ocorrencia": "date",
    "an_registro": "hide",
    "dt_primeiro_registro": "date",
    "id_procedimento_aditado": "hide",
    "id_procedimento_pai": "hide",
    "id_origem": "hide",
    "id_relato_historico": "hide",
    "tx_relato_historico": "longText",
},
'sinesp-personagens': {
    "_id": "hide",
    "_index": "hide",
    "_score": "hide",
    "_type": "hide",
    "_doc": "hide",
    "_click_id": "hide",
    "highlight": "hide",
    "@timestamp": "hide",
    "id_pessoa": "hide",
    "id_pessoa_fisica": "hide",
    "id_procedimento": "hide",
    "dt_nascimento": "dateShort",
    "dt_expedicao": "dateShort",
    "dt_validade": "dateShort",
    "id_documento": "hide",
    "id_endereco": "hide",
    "id_municipio": "hide",
    "id_pessoa_contato": "hide",
    "id_primeira_versao": "hide",
    "id_pessoa_telefone_tmp": "hide",
},

}

 

export const bind_file_types = {
        "application/vnd.oasis.opendocument.text": "odt",
        "application/rtf": "rtf",
        "application/zip": "zip",
        "text/csv; charset=windows-1252; delimiter=comma": "csv",
        "text/plain; charset=ISO-8859-1": "txt",
        "application/msword": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
        "application/vnd.ms-powerpoint": "ppt",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
        "application/vnd.ms-excel": "xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
        "application/pdf": "pdf",
        "image/x-ms-bmp": "bmp",
        "image/jpeg": "jpeg",
        "image/png": "png"
    }
