import { createStore, applyMiddleware } from 'redux';
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";

import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
// import storageSession from 'redux-persist/lib/storage/session'
import rootReducer from './reducers';

const reduxStateSyncConfig = {
    blacklist: ["persist/PERSIST", "persist/REHYDRATE"]
};


const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist:['alert', 'registration'],
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)

const loggerMiddleware = createLogger();

let store = createStore(
    persistedReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        createStateSyncMiddleware(reduxStateSyncConfig)
    )
);


let persistor = persistStore(store)
initMessageListener(store)

export {store, persistor}