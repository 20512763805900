import React, { useState } from 'react';

import ReportItem from './reportItem';

import { Row, Col } from 'react-bootstrap';
// import PDFDownloadLink from '../../common/template/relatorios/pdfDownloadLink'
import {PDFView} from '../../common/template/relatorios/pdfDocumentViewer'

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Message } from '../../components/message_alert';
import { localActions } from '../../redux/actions';

import './report.css';

const ReportComponent = ({reportList, setReportList, user_data}) =>{
    const removeAll = () =>{setReportList([])}
    const [report, setReport] = useState(false)
    let count;
    try{
        count = reportList.length;
    }catch {
        count = 0; 
    }
    const loadReport = () => {setReport(!report)}
    const gerar = <div className="toggle-button" style={{maxHeight: "30px"}}  >
                    <i className="fa fa-check" aria-hidden="true"></i>
                    &nbsp;Gerar relatório
                </div>
    const remover = <div className="toggle-button" style={{maxHeight: "30px"}}  >
                        <i className="fa fa-times-circle" aria-hidden="true"></i>
                        &nbsp;Ver itens
                    </div>
    return (
        <div>
            <Row>
                <Col>
                    <h5> Itens selecionado(s): </h5>&nbsp;<h5 style={{fontWeight: 600, color: "#999"}}>{count}</h5>
                </Col>
                        {count <= 0 ? "" : <Col md={'auto'} className='report-buttons'>
                    <div className="d-flex flex-row-reverse" style={{alignItems:'center'}} onClick={loadReport} >
                        { report ?
                            remover
                         :
                            gerar
                        }
                    </div>
                </Col>
                      }
                      {count <= 0 ? "" : 
                <Col md={'auto'} className='report-buttons'>
                    <div className="d-flex flex-row-reverse" style={{alignItems:'center'}} onClick={removeAll}>
                        <div className="toggle-button toggle-button-red" >
                            <i className="fa fa-times-circle" aria-hidden="true"></i>
                            &nbsp;Limpar
                        </div>
                    </div>
                </Col>
                        }
            </Row>
                        
            {reportList.length <= 0 ?
                <Message text={"Não há itens selecionados"} alert="info" icon="info" />
                : report ?                          
                    <PDFView listItem={reportList} usuario={user_data.name} /> 
                    : reportList.map((listItem, idx)=>{
                        return <ReportItem key={idx} item={listItem} /> 
                    }) 
                    }

        </div>
    );
}


const mapStateToProps = (state) => {
    const { reportList } = state.report;
    const { user_data } = state.user;
    return {
        user_data,
        reportList  
    };
}

function mapToProps(dispatch){
    const { setReportList } = localActions;
    return bindActionCreators({
        setReportList
    }, dispatch);
}

export default connect(mapStateToProps, mapToProps) (ReportComponent); 