import ResetPassword from './ResetPassword/ResetPassword'
import ActivateEmail from './activateEmailPage'
import AlertPage from './alertPage'
import DetailsPage from './detailsPage'
import FolderPage from './folderPage'
import HomePage from './homePage'
import Imagepage from './imagePage'
import LoginPage from './loginPage'
import RegisterPage from './registerPage'
import ResultsPage from './resultsPage'
import DashboardPage from './dashboard'
import NotFound from './notFound'
import MapasPage from './mapasPage'
import ReportPage from './reportPage'
import TwoFactorPage from './twoFactorPage'
import TwoFactorRecoveryPage from './twoFactorRecoveryPage'
import RedirectPage from './redirectPage'


const system_pages = {
    ResetPassword,
    ActivateEmail,
    AlertPage,
    DetailsPage,
    FolderPage,
    HomePage,
    Imagepage,
    LoginPage,
    RegisterPage,
    ReportPage,
    ResultsPage,
    DashboardPage,
    MapasPage,
    NotFound,
    TwoFactorPage,
    TwoFactorRecoveryPage,
    RedirectPage,
}

export default system_pages;