import React, { Component } from 'react';
import './styles.css';
import RenderEntityItem from './renderEntityItem';
import { connect } from 'react-redux';

import { userService } from './index';

const MappingItens = ({ itens, list_right }) => {
  /** Mapeia o resultado buscado para o componente list correspondente */
  let list_itens = itens.map((item) => {
    let source = item._source;
    /** Adiciona id e index ao source de cada item */
    source._id = item._id;
    source._index = item._index;
return ( <RenderEntityItem key={source._id} entity={source} list_right={list_right} /> )
});

return (list_itens )
}


class RenderListItem extends Component {
  constructor(props){
    super(props);
    this.state = {
        list : [],
        length: 0,
    }
}
UNSAFE_componentWillMount() {
  this.searchItens();
}

async searchItens(){
  const { elasticindex } = this.props;
  /** Busca o item relacionado passado na props */
  const { ind, param, valor, valor_index, list_chain, list_right, param_ass, index_enc, param_enc } = this.props;
  let dataList = [];
  dataList = await userService.get_fieldsVinculado(ind, param, valor);

  if (list_chain && list_right) {
    /** Busca se o item for associado */
    let list = dataList.map((item) => {
      return item._source[param_ass];
    }); 
    dataList = []
    let ind_enc = elasticindex.find(element => element === index_enc)
    await Promise.all(list.map(async (item) => { 
      let obj = await userService.get_fieldsVinculado(ind_enc, param_enc, item)
      dataList.push(obj[0]);
      }))
    }
  else if (list_chain && !list_right) {
    /** Busca se o item for associado */
    let list = dataList.map((item) => {
      return item._source[param_ass];
    }); 
    dataList = []
    let ind_enc = elasticindex.find(element => element === index_enc)
    await Promise.all(list.map(async (item) => { 
      let obj = await userService.get_fieldsVinculado(ind_enc, param_enc, item)
      dataList.push(obj[0]);
    }))
    let obj2 = await userService.get_fieldsVinculado(valor_index, param, valor)
    dataList.push(obj2[0]);
    }
    
  this.setState({
      list: dataList,
      length: dataList.length
  });

  }

  render(){
          const list = <MappingItens itens={this.state.list} label={this.props.label} list_right={this.props.list_right} />

          return ( 
          this.props.list_right ?
            <div style={{width:'100%'}}>
              <span className="topic-label" >{this.props.label}</span>
              {list}
            </div>
          :
            <div style={{width: "100%"}}>
              <span style={{display:'flex'}}>
                <div className="topic-label">{this.props.label}
                <span className="text-muted">{this.props.valor}</span>
                </div>
              </span>
              {list}
            </div>
        ) 
        }
          
        }

function mapStateToProps(state) {
    const { elasticindex } = state.user.user_data.node_unidade;
    return {
        elasticindex,
    };
}

export default connect(mapStateToProps)(RenderListItem);



