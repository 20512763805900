import React from "react";
import Img from '../../assets/people.png'
import { formatDate } from '../../services/utils';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IconContext } from "react-icons";

import { DropdownButton, Dropdown } from 'react-bootstrap';
import { elasticActions } from '../../redux/actions';
import { MdExpandMore } from 'react-icons/md';
import './styles.css';


function Comment(props) {
  const {account_username, id, is_admin_react, setEditComment} = props;
  // const {account_username, toggleUpdate, id, deleteComments, is_admin_react, setEditComment} = props;
  const { username, message, time, editor } = props.comment;
  
  /** Chama a rotina de exclusão desse comentário */
  // function deleteComment(id) {
  //   deleteComments(id);
  //   setTimeout(() => {
  //     toggleUpdate();
  //   }, 500);
  // }

  /** Verifica se o usuário é ou não admin ou o dono do comentário */
  function checkStatus(account_username, username, is_admin){
    return (is_admin|account_username===username)
  }

  
  return (
    <div className="media mb-3">
      <img
        className="mr-3 bg-light rounded"
        width="48"
        height="48"
        src={Img}
        // src={`https://api.adorable.io/avatars/48/${username.toLowerCase()}@adorable.io.png`}
        alt={username}
      />
 
      <div className="media-body p-2 shadow-sm rounded bg-light border">
        <small className="float-right text-muted">{formatDate(time)}</small>
        <h6 className="mt-0 mb-1 text-muted">
          {username}
          { editor? `(editado por ${editor})`: ``}
          </h6>
        {message}
      </div>
      <IconContext.Provider value={{ className: "global-class-icons" }}> 

        <DropdownButton menuAlign="right" id='delete-button' className='delete-button' title={<MdExpandMore/>}> 
          {checkStatus(account_username, username, is_admin_react)?
          <>
            <Dropdown.Item className='dropdown-delete' onClick={()=>setEditComment({...{"_id":id},...props.comment})}>Editar</Dropdown.Item>
            {/* <Dropdown.Item className='dropdown-delete' onClick={()=>deleteComment(id)}>Excluir</Dropdown.Item> */}
          </>
          :
          <Dropdown.Item disabled >Sem opções disponível</Dropdown.Item>
          }
        </DropdownButton>
        </IconContext.Provider>
    </div>
  );
}

function mapStateToProps(state) {
  const { username, is_admin_react } = state.user.user_data;
  let account_username = username;
  return {
    account_username,
    is_admin_react,
  };
}

function mapDispatchToProps(dispatch) {
  const { deleteComments, setEditComment } = elasticActions;
  return bindActionCreators({
    deleteComments,
    setEditComment,
  }, dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(Comment);