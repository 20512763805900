import React, { Component } from "react";
import { Modal} from "react-bootstrap";
// import { Button, Modal, ModalHearder, ModalBody } from "react-bootstrap";
// import { utils } from '../../services/utils'
// import { connect } from 'react-redux'
import './styles.css'
import { Message } from '../../components/message_alert';


export class EmailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ""
        };
        this.sendEmail = this.sendEmail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.props.values.onHide.bind(this);

    }

    sendEmail(event) {
        event.preventDefault();
        this.props.values.request(this.state.email);
    }

    handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            ...this.state,
            [name]: value
        })
    }

    render(){
        const {textTitle, textBody } = this.props.values;
    let alertComponent = this.props.alert.message ? <Message icon={this.props.alert.icon} text={this.props.alert.message} alert={this.props.alert.type} /> : ""
        return (
            <Modal
                show={this.props.values.show}
                onHide={this.handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='modal-title'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {textTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    {/* <p>{textBody}</p> */}

                    {/* {this.props.alert.type ?  <div style={{ width: "88%"}}>{alertComponent}</div> :  */}
                    {alertComponent}
                    <div className="card card-info">
                        <div className="p-card-body text-center">
                            <p className="card-text">{textBody}</p>
                        </div>
                    </div>
                    
                    <input className='modal-input' type="email" name="email" value={this.state.email} placeholder="Email cadastrado" onChange={this.handleChange} />
                </Modal.Body>
                <Modal.Footer>
                  <button className='btn save-btn' onClick={this.sendEmail}>Solicitar</button>
                  <button className='btn close-btn' onClick={this.handleClose}>Fechar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
