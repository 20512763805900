import React, { useState } from 'react'
import { FaPlus, FaWindowMinimize } from "react-icons/fa";
import { Accordion,  Card} from 'react-bootstrap';
import './styles.css'

export const IconToggle = ({isOpen, label, value, count }) => {
  
  return <span>
            <div className="input-group-item">
              <div style={{width: "100%"}}>
                <span className={'accordionMin-label'} >{label}</span>
                <strong style={{fontSize: 14}}>{value}</strong>
              </div>
              <span title={count +" "+ label} className="badge-msg  bg-primary">{count}</span>&nbsp;
          {isOpen ? <FaPlus/> : <FaWindowMinimize/>}
        
          </div>
        </span>
}

function AccordionMin( props )  {
  const [open, setOpen] = useState(false);

  return (
    <Accordion defaultActiveKey="0">
  <Card className="">
    <Accordion.Toggle as={Card.Header} eventKey="0" className="w3-hover-shadow w3-theme-l1 link-toggle card-header" 
    onClick= {() => setOpen(!open)}> 
     <IconToggle label={props.label} value={props.value} count={props.count} isOpen={open} />
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0" className="box-shadow" >
      <Card.Body className="card-body" >
      <section>{props.children}</section> 
      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>

)
  }

export default AccordionMin;