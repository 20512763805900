import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import './styles.css'
export const Message = (props) => (
      <div className={`alert alert-${props.alert}`} role="alert">
            <h6>
                  <FontAwesomeIcon icon={['fas', `${props.icon}`]} />
                  <span>{" | "+props.text}</span>
            </h6>
        </div>
  );