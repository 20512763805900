import React from 'react';
import { FiTrash,  } from 'react-icons/fi';
import { FaAssistiveListeningSystems, FaUserFriends, FaUserLock, FaServer, FaEdit, FaTimesCircle  } from 'react-icons/fa';
import { GoDiffAdded } from "react-icons/go";
import { MdAccountBalance } from "react-icons/md";
import { BsPersonFill, BsCollectionFill, BsFillPersonCheckFill } from "react-icons/bs";

export const renderIcon = {
        "edit": <FaEdit />,
        "trash": <FiTrash />,
        "add": <GoDiffAdded />,
        "person": <BsPersonFill/>,
        "collection": <BsCollectionFill/>,
        "listen": <FaAssistiveListeningSystems/>,
        "userfriend": <FaUserFriends/>,
        "userlock": <FaUserLock/>,
        "server": <FaServer/>,
        "stop": <FaTimesCircle/>,
        "pj": <MdAccountBalance/>,
        "BsFillPersonCheckFill": <BsFillPersonCheckFill/>,
    }
    