import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { InputText }  from '../../primeComponents/inputtext/InputText';
import { InputText } from 'primereact/inputtext';
import './dataTable.css';
import { ServicesClass } from '../../services';
import { fieldsToTables, fieldsToLabels } from '../../config';
import { getFieldName,  } from '../../services';

const DataTableComponent = ({ ind, param, valor, label }) => { 
    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    
    const renderHeader = () => {
        return (
            <div className="table-header">
                <h6>{label}</h6>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" ></i>
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Filtar" />
                </span>
            </div>
        );
    }
    const header = renderHeader();
    
    useEffect(() => {
        const services = new ServicesClass();
        services.getWithSQL(ind, param, valor, fieldsToTables[ind])
        .then(data => getDataTable(data));
    }, [ind, param, valor]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const getDataTable = ((data) => {
        const { schema, datarows } = data;
        
        const dataCols = schema.map((col,i) => {
            let key, value;
            const cols = <Column key={i} 
                style={{ width: 'max-content'}}
                field={col.name} 
                header={getFieldName(fieldsToLabels[ind], col.name)} sortable
                body={(rowData) => {
                    key = Object.keys(rowData)
                    value = Object.values(rowData)
                    return (
                        <React.Fragment>
                            <span className="p-column-title">{getFieldName(fieldsToLabels[ind], key[i])}</span>
                            {value[i]}
                        </React.Fragment>
                    );
                }} 
         />;
         return cols
        });
        setCols(dataCols)

        const dataRows = datarows.map((item) => {
          let obj = {}
          item.forEach((it, index) => {
            obj[schema[index].name] = it
          })
          return obj
        })
        setRows(dataRows)
    })

    
    
    return (
        <div className="w3-third2 datatable-responsive">
        <DataTable value={rows} header={header} className="p-datatable-responsive" dataKey="id" rowHover globalFilter={globalFilter}
        paginator rows={5} emptyMessage="Não há itens" currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} itens"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5,10,50]}
        >
            {cols}
        </DataTable>
        </div>
    );
}

function mapStateToProps(state) {
    const { elasticindex } = state.user.user_data.node_unidade;
    return {
        elasticindex, 
    };
}

export default connect(mapStateToProps)(DataTableComponent)