import React from 'react'
// import { BsJustify, BsX } from 'react-icons/bs'
import { IconContext } from "react-icons";
import './sideBarIcon.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarIcon = ({handleClick, isOpen}) => {
  const arrUp = isOpen ? <span><FontAwesomeIcon icon={['fas', `arrow-up`]} /> Ocultar refinamento </span> : null
  const arrDown = !isOpen ? <span>
            <FontAwesomeIcon icon={['fas', `arrow-down`]} /> Refinar Pesquisa </span> : null
  return <div className="btn save-btn" style={{minWidth:170}}
          onClick={handleClick}>
            <IconContext.Provider value={{ className: "refino-icon" }}>
          {arrUp}
          {arrDown}
          </IconContext.Provider>
        </div>
}
export default SidebarIcon