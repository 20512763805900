// import config from 'config';
// import { authHeader } from './authHeader';
import { alertActions } from '../redux/actions';
import { api, apiElastic } from './index'
import { globalConstants } from '../redux/constants'

import moment from 'moment';

export const userService = {
  login,
  logout,
  updateToken,
  get_user,
  get_fieldsVinculado,
  register,
  getAll,
  // getById,
  // update,
  // delete: _delete

  //folders methods
  get_folders,
  post_folder,
  //alerts
  getAlerts,
  setAlert,
  editAlert,
  deleteAlert,
  getAlert,
  pivotTableGetData,
  getRecommendations,
  //admin
  getUnits,
  getLevels,
  listUsers,
  activateUser,
  updateLevelUser,
  /*
  updateUnitUser,
  */
  postComment,
  getComments,
  deleteComments,
  putComments,
};

function updateToken(token) {
  const url = 'users/token-expiration/';
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.get(url, auth).then(response => {
    if (!isNaN(response.data.expiry)) {
      localStorage.setItem(globalConstants.TOKEN_KEY, JSON.stringify(response.data));
    }
    return response.data;
    /*console.log(response)
    let newExpiry = new Date();
    newExpiry.setHours( newExpiry.getHours() + 1);
    let data = JSON.parse(localStorage.getItem(globalConstants.TOKEN_KEY));
    data.expiry = newExpiry;
    localStorage.setItem(globalConstants.TOKEN_KEY, JSON.stringify(data));
    return newExpiry;*/
  });
}

//admin


async function getUnits() {
  const url = '/users/units/';
  return new Promise((resolve, reject) => {
    api.get(url)
    .then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error)
    });
})
}

// async function getUnits() {
//   const url = '/users/units/';
//   return fetch(url)
//   .then(res => res.json())
//   .then(data => {
//     console.log(data);
//   });
// }

function getLevels(token) {
  const url = 'users/levels/';
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.get(url, auth).then(response => {
    return response.data;
  });
}

function listUsers(token) {
  const url = 'users/list/';
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.get(url, auth).then(response => {
    return response.data;
  });
}

function activateUser(token, username) {
  const url = 'users/activate-user/';
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  const data = { 'username': username }

  return api.post(url, data, auth).then(response => {
    return response;
  });
}


function updateLevelUser(token, idLevel, username) {
  const url = 'users/update-level-user/';
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  const data = { 'username': username, 'id_nivel': idLevel }
  return api.post(url, data, auth).then(response => {
    return response;
  });
}





/** 
# POST users/update-level-user/ (username e id novo nivel requerido)
- se o usuario atual for gestor da unidade do usuario que ele quer modificar e o nivel do usuario atual for maior que o dele
- se o usuario atual for admin do react ou admin do django
-OBS: O nivel tem que ser da unidade atual
# POST users/update-unity-user/ (username, id nova unidade e id novo nivel requerido)
- se for admin do react ou admin do Django
- OBS: O nivel tem que ser da mesma unidade
*/

/*

function updateUnitUser(token, idUnit, idLevel, username) {
  const url = 'users/update-unity-user/';
  let authStr = "token ".concat(token)
  const auth =  { headers:{"Authorization" : authStr }}
}
function activateUser(token, username) {
  const url = 'users/activate-user/';
  let authStr = "token ".concat(token)
  const auth =  { headers:{"Authorization" : authStr }}
}
function updateLevelUser(token, idLevel, username) {
  const url = 'users/update-level-user/';
  let authStr = "token ".concat(token)
  const auth =  { headers:{"Authorization" : authStr }}
}
*/

function login(user) {

  const url = '/users/login/'

  return api.post(url, user)
    .then(response => {
      // store and jwt token in local storage to keep user logged in between page refreshes
      //localStorage.setItem(globalConstants.TOKEN_KEY, JSON.stringify(token.data));

      return response.data.token;
    });
}


function register(user) {
  const url = '/users/'

  return api.post(url, user)
    .then(user => {
      return user
    })
    .catch(error => {
      return error
    })
}


function logout(token) {
  const url = '/users/logout/';
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.post(url, {}, auth).then(response => {
    return response;
  });
}


function get_user(token) {
  const url = '/users/me/';
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.get(url, auth)
    .then(user => {
      localStorage.setItem(globalConstants.USER_DATA, JSON.stringify(user.data));
      console.log(localStorage.getItem(globalConstants.USER_DATA));
      return user;
    })

  .catch(error => {
      return (error)
  })

}

function getAll(token) {
  const url = '/users/';
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.get(url, auth)
    .then(response => {
      console.log(response.data)
      return response.data;
    });
}


// Alerts methods


function getAlerts(token,) {
  const url = '/alerts/';;
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } };
  return api.get(url, auth).then(response => {
    return response;
  });
}

function setAlert(token, data) {
  const url = `/alerts/`;
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.post(url, data, auth).then(response => {
    return response;
  });
}

function getAlert(token, id) {
  const url = `/alerts/${id}/`;
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.get(url, auth).then(response => {
    return response;
  });
}

function deleteAlert(token, id) {
  const url = `/alerts/${id}/`;
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.delete(url, auth).then(response => {
    return response;
  });
}

function editAlert(token, data, id) {
  const url = `/alerts/${id}`;
  let authStr = "token ".concat(token)
  const auth = { headers: { "Authorization": authStr } }
  return api.put(url, data, auth).then(response => {
    return response;
  });
}


// Get Itens

// function getItens(ind, param, valor) {

//   return new Promise((resolve, reject) => {
//     apiElastic.get(`/${ind}/_doc/_search?q=${param}:${valor}`)
//       .then(response => {
//         resolve(response.data.hits.hits);
//       })
//       .catch(error => {
//         console.log("Não foi possível buscar os itens.");
//       });
//   });
// }


function get_fieldsVinculado(ind, listFields, valor) {

  const url = `/${ind}/_doc/_search`
  const body = {
    "query": {
      "multi_match": {
        "query": valor,
        "type": "phrase",
        "fields": listFields
      }
    }
  }
  return new Promise((resolve, reject) => {
    apiElastic.post(url, body)
      .then(response => {
        resolve(response.data.hits.hits);
      })
      .catch(error => {
        console.log("Não foi possível buscar os itens.");
      });
  });
}


/**
* Queries the Elasticsearch Database for show recommendations
* 
* @param {Object} entity 
*/
function getRecommendations(entity) {
  return new Promise(function (resolve, reject) {
    var body;
    // var highestRated = false;
    // var today = new Date();
    const url = "/" + entity._index + "/_doc/_search";
    // const fields = []
    var qs = "";
    const source = entity._source;

    Object.size = function (obj) {
      var size = 0, key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    };

    // Get the size of an object
    var size = Object.size(source);

    for (var [key, value] of Object.entries(source)) {
      var or = "";
      for (var item = 0; item < size; item++) {
        if (item < size) {
          or = " OR "
        }
        else or = "";
      }
      qs = qs + key + ":(" + value + ")" + or;
      // fields.push( key+":"+ value );
    }
    console.log(qs)
    console.log("Tamanho do source: " + size)
    // for (var entity = 0; entity < entities.length; entity++) {
    //     if (entities[entity].entity == "genre") {
    //         if (genres) {
    //             genres = genres + " OR ";
    //         }
    //         genres = genres + entities[entity].value;
    //     } else if (entities[entity].entity == "network") {
    //         if (networks) {
    //             networks = networks + " OR ";
    //         }
    //         networks = networks + entities[entity].value;
    //     } 

    // }

    // if (!genres) {
    //     genres = "*";
    // }

    // if (!networks) {
    //     networks = "*";
    // }

    // if (!days) {
    //     days = "*";
    // }

    // if (!times) {
    //     times = "*";
    // }

    body = {
      "query": {
        "query_string": {
          // "query": "(network.name:(" + networks + ") OR webChannel.name:(" + networks + ")) AND schedule.days:(" + days + ") AND status:(" + status + ") AND schedule.time:(" + times + ") AND (genres:(" + genres + ") OR type:("+ genres + "))"
          "query": "(" + qs + ")"
          // "query": "(codigo:(1254551055) OR situacao:(FINALIZADA) OR distrito:(DP DE SÃO JOÃO DO PIAUÍ))"
        }
      }
    }

    // if (highestRated) {
    //     body.sort = {"rating.average": "desc"};
    // } 
    apiElastic.post(url, body)
      .then(response => {

        console.log("Recomendações: " + JSON.stringify(response.data.hits.hits))
        return resolve(response);

      })
      .catch(error => {
        console.log("Não foi possível retornar dados. " + error);
        // dispatch(alertActions.error("Não foi possível retornar dados.")) ;
        reject(false)
      });

  })
}

// folder methods

function get_folders(matricula, dispatch) {
  const url = '/folders/_doc/_search/';
  const body = { "query": { "bool": { "filter": { "term": { "matricula": matricula, main_folder: true } } } }, size: 1000 }
  // const body = {"query": {"bool":{"filter":{"term":{"matricula": matricula },"term":{"main_folder":true}}} }, size:1000}
  return new Promise((resolve, reject) => {
    apiElastic.post(url, body)
      .then(response => {
        resolve(response.data.hits.hits);
      })
      .catch(error => {
        dispatch(alertActions.error("Não foi possível achar suas pastas, contate o(s) administrador(es) do sistema."));
        reject(false)
      });
  });
}
function post_folder(data, matricula, dispatch) {
  const url = '/folders/_doc/';
  const { name, description } = data;
  const body = {
    name: name,
    description: description,
    matricula: matricula,
    main_folder: "true",
    type: "folder",
    itens: []
  };
  return new Promise((resolve, reject) => {
    apiElastic.post(url, body)
      .then(response => {
        console.log(response)
        console.log("pasta criada com sucesso.");
        dispatch(alertActions.success("pasta criada com sucesso."));
        setTimeout(() => {
          dispatch(alertActions.clear())
        }, 4000);
        resolve(true)
      })
      .catch(error => {
        dispatch(alertActions.error("Não foi possível criar sua pasta, contate o(s) administrador(es) do sistema."));
        reject(false)
      });
  });

}

function getItemList(list) {
  let returnFields = []
  list.map((li) => {
    return returnFields = returnFields.concat(li._source)
  })
  return returnFields;
}

function dateToInternationalDate(data) {
  return moment(data).format('YYYY-MM-DD');
}

async function pivotTableGetData(filters) {

  const time_field = `${filters.data_filter.field}`;
 
  const body = {
    "size": 1000,
    "query": {
    "range": {
      [time_field]: {
        "gte": dateToInternationalDate(filters.data_filter.start),
        "lte": dateToInternationalDate(filters.data_filter.finish)
        }
      }
    }
  }
   
  const url = `/${filters.index_value}/_doc/_search`
  const response = await apiElastic.get(url, {
    params: {
      source: JSON.stringify(body),
      source_content_type: 'application/json'
    }
  }
    )
    .then(response => {
      return response.data.hits.hits;
    })
    .catch(error => {
      console.log("Não foi possível buscar os itens.");
    });
  // console.log(response)

  const list = getItemList(response);
  return list;
}



function postComment(data) {
  const url = '/comments/_doc/';
  return apiElastic.post(url, data).then(response => {
    return response;
  });

}
function getComments(id) {
  const url = '/comments/_doc/_search';
  const body = { "query": { "bool": { "filter": { "term": { "document_id": id } } } }, size: 1000 }
  // const body = {"query": {"bool":{"filter":{"term":{"matricula": matricula },"term":{"main_folder":true}}} }, size:1000}
  return apiElastic.post(url, body)
    .then(response => {
      return (response.data.hits.hits);
    })
} 

function deleteComments(id){
  const url = `/comments/_doc/${id}`;
  return apiElastic.delete(url)
    .then(response=>{
      console.log(response)
      return response
    })
    .catch(error=>{
      console.log(error)
      return error 
    });
}

function putComments(id, data){
  const url = `/comments/_doc/${id}`;
  return apiElastic.put(url, data)
    .then(response=>{
      console.log(response)
      return response
    })
    .catch(error=>{
      console.log(error)
      return error 
    });
}
