import { djangoConstants, elasticConstants, localConstants } from '../constants';

export function searchedReducer(state = {searchTerm:""}, action) {
    switch (action.type) {
        case  elasticConstants.searchSuccess:
            return {...state, searchTerm: action.payload.searchTerm}
            //return state.searchTerm = term;
        
        case localConstants.setDetailsModelId:
            return {...state, modelDetailsId: action.payload.id}
        
        case localConstants.deleteDetailsModelsId:
            return {...state, modelDetailsId: ''}    
        //ok
        case localConstants.toggleSideBar:
            return {...state, isOpen: !action.payload.isOpen}

        case djangoConstants.logoutSuccess:
            return {searchTerm:""}
        case 'persist/REHYDRATE':
            if (action.payload){
                return {...state, ...action.payload.searched}  
              }  
            return {searchTerm:""}      
        default:
            return state
    }
}

