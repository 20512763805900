import React from "react";
import L from 'leaflet';
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import Basemap from './Basemaps';
import GeojsonLayer from './GeojsonLayerFunc';
import './mapa.css';
import BasePage from '../homePage/base'

L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.5.0/dist/images/";

var myIcon =L.icon({
  iconUrl: 'https://www.pinclipart.com/picdir/big/391-3913630_new-training-location-green-location-icon-png-clipart.png',
  iconSize: [22, 38],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41]
})

class MapComponent extends React.Component { 
  state = {
    lat: -5.091007,
    lng: -42.798611,
    zoom: 12,
    basemap: 'osm',

    geojsonvisible: false,
  };

  onBMChange = (bm) => {
    this.setState({
      basemap: bm
    });
  }

  onGeojsonToggle = (e) => {
    
    this.setState({
      geojsonvisible: e.currentTarget.checked
    });
  }

  render() {
    var center = [this.state.lat, this.state.lng];

    const basemapsDict = {
      osm: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      real: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      dark:"https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png",
  }

    return (
      <BasePage level1={<li className="current"><b>{'Localização'}</b></li>} >
       <h5>Análise de dados com Mapa</h5>

      <Map zoom={this.state.zoom} center={center}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={basemapsDict[this.state.basemap]}
        />
        <Basemap basemap={this.state.basemap} onChange={this.onBMChange} />

        <div className="geojson-toggle">
          <label htmlFor="layertoggle">Exibir Ocorrências </label>
          <input type="checkbox"
            name="layertoggle" id="layertoggle"
            value={this.state.geojsonvisible} onChange={this.onGeojsonToggle} />
        </div>
        
        {this.state.geojsonvisible && 
          <GeojsonLayer url="places.json" cluster={true}/>
        }

        <Marker position={center} icon={myIcon}>
          <Popup>
            <b>Secretaria de Segurança</b> <p>Centro</p>
          </Popup>
        </Marker>
      </Map>
    </BasePage>
    );
  }
};

export default MapComponent;