import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
// import Img from '../../../assets/people.png'
import Header from './Header';
import Footer from './Footer';
import { renderFieldsToPrint, renderHeadersToPrint } from '../relatorios/renderFieldsToPrint';
import { indexesShortNames } from '../../../config';
import { StyleSheet } from '@react-pdf/renderer';

// Register font
Font.register({ family: 'Arial', src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf' });

export function PdfDocument(props) { 
  const reportList = props.data.map((listItem, idx)=>{ 
    let valor_index = listItem._index.split('_')[0]
    const item = (idx+1)
    const title = renderHeadersToPrint(item, indexesShortNames[valor_index])
      const fields = renderFieldsToPrint(listItem, valor_index)
   
    return  <View key={idx}>
              {title}
            <View style={styles.flexContainer}>
            {fields}
          </View>
          </View>
   
  });
  const user = props.usuario;

  const date = new Date();

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
      <Header />
      
        {reportList.length <= 0?
        <View>
                  <Text>
                    Relatório vazio
                  </Text>
        </View>
                : reportList}

       <Footer user={user} date={date} />

      </Page>
    </Document>
  );
}

// Create styles
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'col',
    backgroundColor: '#ffffff',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Arial',
  },

pageNumber: {
  // position: 'absolute',
  fontSize: 12,
  bottom: 30,
  left: 0,
  right: 0,
  textAlign: 'center',
  color: 'grey',
},
flexContainer: {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  // backgroundColor: '#fafafa',
},
});