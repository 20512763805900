import React from 'react'
import './styles.css'
import createMarkup from './createMarkup';

export default props => (
 
    <div className="column" >
        <p><span className="w3-text-main"><b>{props.label}:</b></span></p>
        <span className="w3-text-info" dangerouslySetInnerHTML={createMarkup(props.value)} /> 
        <hr className="hr-class" />
    </div>

)