import React, { useState, useEffect } from "react";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Apresentacao from "../apresentacao/apresentacao";
import Loading from '../../common/template/loading';
import { Message } from '../../components/message_alert';
import { Link } from "react-router-dom";

import { Formik, Field } from 'formik';
import { Form } from 'react-bootstrap';
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import TextError from './textError';

import { djangoActions } from '../../redux/actions';
import { globalConstants } from '../../redux/constants';

import ReCAPTCHA from "react-google-recaptcha";

import { Tree } from 'primereact/tree';
import Logo from "../../assets/sinfor_logo.png";


import '../loginPage/styles.css';
import './register.css';

const cpfmask = [/[0-9]/,/[0-9]/,/[0-9]/,".",/[0-9]/,/[0-9]/,/[0-9]/,".",/[0-9]/,/[0-9]/,/[0-9]/,"-",/[0-9]/,/[0-9]/];

function getId(node = [], key = ""){
    for (let i = 0; i<node.length; i++){
        if (node[i].key === key){
            return node[i].id;
        }
    }
    for (let i = 0; i<node.length; i++){
        let id_children = getId(node[i].children, key);
        if (id_children){
            return id_children
        }
    }
}


const getInitialValues = (user) =>{
    let initialUser = {
        name:"",
        cpf:"",
        matricula:"",
        node_unidade:"",
        email:"",
        username:"",
        password:"",
        password2:"",
        recaptcha:"", 
    }
    try{
        if (user.name) initialUser.name = user.name; 
        if (user.cpf) initialUser.cpf = user.cpf;
        if (user.matricula) initialUser.matricula = user.matricula; 
        if (user.node_unidade) initialUser.node_unidade = user.node_unidade; 
        if (user.email) initialUser.email = user.email;
        if (user.username) initialUser.username = user.username; 
        if (user.password) initialUser.password = user.password; 
    }catch{}
    return initialUser
}


const RegisterPage = ({
    registering,
    alert,
    register,
    requestErrors,
    user,
}) =>{


    const [ nodes, setNodes ] = useState(null)
    const [ selectNode, setSelectNode ] = useState(null)
    const [ expandedKeys, setExpandedKeys ] = useState({})
    
    async function setUnitsValues (){
        let data = await djangoActions.getUnitNodeTree(true);
        setNodes(data);
    }
    
    const expandedKey = (key) => {
        let keys = expandedKeys;
        keys[key] = true;
        setExpandedKeys(keys);
    }

    useEffect(() =>{
        setUnitsValues();
    },[]);
    
    const { REACT_APP_RECAPTCHA_KEY } =  process.env;

    let alertComponent = alert.message ? <Message icon={alert.icon} text={alert.message} alert={alert.type} /> : ""
    return (
        <div className="container-login">
                
                <Apresentacao idHref={'#registrar'} label={'Cadastrar'} />

            <div id="registrar" className="div-hight">
            <div className="imgLogoSinfor">
                <img  src={Logo} alt="Sinfor logo" />
            </div>
            <div className="div-hight-center">
                <h2>Cadastrar</h2>

                {registering ? <Loading message="Por favor, aguarde..." /> :
                    <Formik 
                        
                    initialValues={getInitialValues(user)}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required("Nome requerido"),
                        cpf: Yup.string()
                            .min(14, "CPF inválido")
                            .matches( /^(?!.*(_).*).*$/gm , "CPF inválido")
                            .required("CPF requerido"),
                        matricula: Yup.number()
                            .positive("Digite um número positivo")
                            .required("Matricula requerido"),
                        node_unidade: Yup.string()    
                            .required("Selecione uma unidade"),
                        email: Yup.string()
                            .email()
                            .required("Email requerido"),
                        username: Yup.string()
                            .required("Nome de usuário requerido"),
                        password: Yup.string()
                            .required("Senha requerida"),    
                        password2: Yup.string()
                            .required("Confirmação de senha requerida")
                            .oneOf([Yup.ref('password'),null],"O valor deve ser igual ao campo senha"),
                        recaptcha: Yup.string()
                            .required("Preencha o desafio para continuar")
                    })}


                    onSubmit={(values) => {
                        console.log(values)
                        register(values)    
                    }}
                    >
                        {({values,handleChange, handleSubmit, errors, setFieldValue}) =>(
                            <Form className="register-form" onSubmit={handleSubmit} method="POST">
                                {alertComponent}
                                <Field className='register-input' type="text" name="name" 
                                    placeholder="Nome completo" value={values.name} onChange={handleChange} required/>
                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"name"}/>

                                <Field name="cpf" 
                                    render={({field})=>(
                                        <MaskedInput 
                                            {...field}
                                            mask={ cpfmask }
                                            placeholder="CPF"
                                            id="cpf"
                                            className='register-input'
                                            type="text"
                                            onChange={handleChange}
                                            required
                                        />
                                    )}
                                    />
                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"cpf"}/>
                                
                                <Field className='register-input' type="number" name="matricula" 
                                    placeholder="Matricula"  value={values.matricula} onChange={handleChange} required/>
                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"matricula"}/>
                                
                                {console.log(values)}
                                {nodes!==null&&
                                    <Tree value={nodes} selectionMode="single" selectionKeys={selectNode} 
                                    expandedKeys={expandedKeys}
                                    onToggle={e => setExpandedKeys(e.value)}
                                    onSelectionChange={(e)=>{
                                        setSelectNode(e.value)
                                        expandedKey(e.value)
                                        if (getId(nodes, e.value)){
                                            setFieldValue('node_unidade', getId(nodes, e.value))
                                        }else{
                                            setFieldValue('node_unidade','')
                                        }
                                    }}/>
                                }
                                {nodes!==null&&
                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"node_unidade"}/>
                                }


                                {/* <Form.Control className='register-input selected-itens' name="node_unidade" as='select' onChange={handleChange}>
                                    <option disabled selected>Selecione sua Instituição</option>
                                    {units.map((unt ,id)=>{
                                        return <option key={id} value={unt.id} >{unt.nome} - {unt.instituicao}</option>
                                    })}
                                </Form.Control> */}

                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"unidade"}/>
                                  
                                <Field className='register-input' type="email" name="email" 
                                    placeholder="Email Institucional" value={values.email} onChange={handleChange} required/>
                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"email"}/>
                                
                                <Field className='register-input' type="text" name="username" 
                                    placeholder="Nome de usuário" value={values.username} onChange={handleChange} required/>
                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"username"}/>
                                
                                <Field className='register-input' type="password" name="password" 
                                    placeholder="Senha" value={values.password} onChange={handleChange} required/>
                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"password"}/>
                                  
                                <Field className='register-input' type="password" name="password2" 
                                    placeholder="Confirmar senha" value={values.password2} onChange={handleChange} required/>
                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"password2"}/>
                                 
                                <center>
                                <ReCAPTCHA
                                    className='recaptcha'
                                    sitekey={REACT_APP_RECAPTCHA_KEY}
                                    onChange={(response)=>{
                                        if(response!== null){
                                            setFieldValue("recaptcha", response)
                                        }else if (response === null){
                                            setFieldValue("recaptcha", "")
                                        }
                                    }}
                                    /> 
                                </center>
                                <TextError  requestError={requestErrors} formError={errors} value={values} defaultValue={user} field={"recaptcha"}/>

                                <button className='login-button' type="submit"><span>Enviar</span></button>
                                <hr />
                                <div> Já é registrado? <Link to={globalConstants.baseAppName + "/login"}>Fazer Login</Link></div>
                            </Form>
                         )}
                    </Formik >
                }
                </div>
                </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    const { registering, errors, user } = state.registration;
    const { alert } = state;
    const requestErrors = errors;
    return {
      registering,
      user,
      alert,
      requestErrors,
    };  
}

const mapDispatchToProps = (dispatch) => {
    const { register,  } = djangoActions
    
    return bindActionCreators({
        register,
    },dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);


