import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';

// import - redux
import { connect } from 'react-redux';

// elastic Search functions from services
import { userService } from '../../services'; 

import './folder.css';

class NewFolderModal extends Component {
  constructor(props){
    super(props);
    this.state={
      name:"",
      description:"",
    }
  }

  handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
        ...this.state,
        [name]: value
    })
  }
  
  async save(){
    const {name, description} = this.state;
    const {matricula, dispatch} = this.props;
    if (name && description){
      const result = await userService.post_folder({name, description}, matricula, dispatch)
      if (result){
        setInterval(this.props.updateList(),1000);
        this.props.hide();
      }

    }
  }

  render() {
    const {newFolder, hide} = this.props;
    return (      
      <Modal
      onHide={hide}
      show={newFolder}
      size='lg'
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header className='modal-title'>
          <Modal.Title id="contained-modal-title-vcenter">
            Nova Pasta
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='was-validated'>
            <label as="name">Nome da Pasta</label>
            <input className="form-control modals-form" type="text" 
             name="name" value={this.state.name} onChange={this.handleChange.bind(this)} required/> 
            <label as="description">Descrição</label>
            <input className="form-control modals-form" type="text" 
             name="description" value={this.state.description} onChange={this.handleChange.bind(this)} required/> 
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn1' onClick={()=>{this.save()}} >Salvar</button>
          <button className='btn1' onClick={hide}>Fechar</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { matricula } = state.user.user_data;
  return {
    matricula
  };
}

function mapDispatchToProps (dispatch) {
  return {dispatch}
}


export default connect(mapStateToProps, mapDispatchToProps)(NewFolderModal);