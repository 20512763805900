import React from 'react';

import BasePage from '../homePage/base'
import { userService } from '../../services';
import { Button } from 'react-bootstrap';

import tips from './tips';
//import { sortAs } from './Utilities';

// import TableRenderers from './TableRenderers';
// import createPlotlyRenderers from './PlotlyRenderers';
// import PivotTableUI from './PivotTableUI';
import './pivottable.css';
//import Dropzone from 'react-dropzone';
//import Papa from 'papaparse';

// import Plot from 'react-plotly.js';

import { connect } from "react-redux";

import { FormQuery } from './formQuery';
import Scrollbars from 'react-custom-scrollbars';
 
import { Sidebar } from 'primereact/sidebar';

// const PlotlyRenderers = createPlotlyRenderers(Plot);

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pivotState: [],
            valor_index: '',
            loading: false,
            visibleFullScreen: false,
        }
    }

    handleClick = () => {
        this.setState(
            {
                filename: 'Sample Dataset: Tips',
                pivotState: {
                    data: tips,
                    //   rows: ['Payer Gender'],
                    //   cols: ['Party Size'],
                    //   aggregatorName: 'Count',
                    //   rendererName: 'Grouped Column Chart',
                    plotlyOptions: { width: 600, height: 500 }
                }
            });
    }

    
    async handleSubmit(data) {
        let newpivotState = [];
        
        this.setState({ loading1: true });
        newpivotState = await userService.pivotTableGetData(data);
        setTimeout(() => {
            this.setState({ 
                loading: false,
                pivotState: newpivotState,  
                visibleFullScreen: true,
             });
        }, 1000);
        
    }
    
   
    render() {
        const { elasticindex } = this.props;
        // const indexs = getIndexs(elasticindex);
        //const valor_index = this.state.valor_index;
        return (
            <BasePage level1={<li className="current"><b>{'Dashboard'}</b></li>} >
                    
                    <FormQuery elasticindex={elasticindex} handleSubmit={(data)=>{this.handleSubmit(data)}}/>
                    <Sidebar visible={this.state.visibleFullScreen} fullScreen onHide={() => this.setState({ visibleFullScreen: false })}>
                        <h5 style={{ fontWeight: 'normal', marginTop: 20 }}><b>Análise  dinâmica de dados</b></h5>
                    <Scrollbars style={{ width: '100%', height: '80%' }}>
                    {/* <PivotTableUI
                        data={this.state.pivotState || []}
                        onChange={s => this.setState({ pivotState: s })}
                        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                        {...this.state.pivotState}
                        /> */}
                    </Scrollbars>
                    <Button variant="secondary" onClick={() => this.setState({ visibleFullScreen: false })}>Fechar</Button>
                    </Sidebar>
                    
                    {/*
                    <button onClick={this.handleClick}>Get CSV</button>
                     */}
              
            </BasePage>
        );
    }
}
function mapStateToProps(state) {
    const { elasticindex } = state.user.user_data.node_unidade;
    return {
        elasticindex
    };
}

export default connect(mapStateToProps, null)(Dashboard);