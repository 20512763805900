import React from "react";
import components from '.'
import { getFieldName, formatDate, formatDateShort } from '../services/index';
import { fieldsToType, fieldsToLabels } from '../config';


const checkType = (_index, key, value) => {
    let field = ''
    let type = getFieldName(fieldsToType[_index], key) 
  const { TextGroup, TextGroupIcon } = components;
  switch (type) {
        case "hide":
            // console.log('É um id')
            break;
        case "longText":
          field= <TextGroupIcon key={key} icon={"stack"} label={getFieldName(fieldsToLabels[_index], key)} value={value || 'N/I'} />
           
            break;
        case "dateShort":
          field= <TextGroup key={key} label={getFieldName(fieldsToLabels[_index], key)} value={formatDateShort(value) || 'N/I'} /> 
          
            break;
        case "date":
          field= <TextGroup key={key} label={getFieldName(fieldsToLabels[_index], key)} value={formatDate(value) || 'N/I'} /> 
          
            break;
        default:
          field= <TextGroup key={key} label={getFieldName(fieldsToLabels[_index], key)} value={value || 'N/I'} />
      
        
  }
return field
}

const generatorDetailsFields = (entityFields, _index) => {
    let fields = []
    let subFields = []
    const { TextGroup, TextHeader } = components;
    try{
        for (var [key, value] of Object.entries(entityFields)){
            if(value === null){
                fields.push(checkType(_index, key, value))
            }            
            else if (Array.isArray(value)){
                try{
                    subFields.push(<div key={key}> <TextHeader label={getFieldName(fieldsToLabels[_index], key).toUpperCase()} /></div>)
                    for (var [k, v] of Object.entries(value)){
                        if (typeof v === 'object'){ 
                            let f = generatorDetailsFields(v,_index)
                            // subFields = subFields.concat(f.fields)
                            // subFields = subFields.concat(f.subFields) 
                            subFields.push(f.fields)
                            subFields.push(f.subFields)                       
                        }else{
                            subFields.push(<div key={k}><TextGroup label={(++k)} value={v || 'N/I'} /></div>)
                        }
                    }
                }catch (e){
                    console.log('error 02: ', e);
                }
            }else if(typeof value ==='object' && key!=='highlight'){
                let y = generatorDetailsFields(value,_index)
                // subFields = subFields.concat(y.fields)
                // subFields = subFields.concat(y.subFields)
                subFields.push(y.fields)
                subFields.push(y.subFields)
            }else{
                fields.push(checkType(_index, key, value))
            }
        }
    }catch (e){
        console.log('error 01: ',e);
    }
    let itens = {'fields':fields, 'subFields':subFields} 
    return itens;

}



export const renderDetailsFields = (entityFields, _index) => {
    let allFields = []
    let subFields = []
    const fields = generatorDetailsFields(entityFields, _index);
    allFields = fields.fields;
    subFields = fields.subFields;
    return <div className="d-flex flex-column">
            <div className="columnIcon w3-round-xlarge" style={{minWidth:"100%"}}>{allFields}</div>
            {subFields.map((fields, idx)=>{
                return <div key={idx} className="columnIcon w3-round-xlarge" style={{minWidth:"100%"}}>{fields}</div>
            })
            }
        </div>;
}
