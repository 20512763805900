import { api, authenticatedApi } from "../newapi"
import { djangoUrls, djangoAuthenticatedUrls } from "./django.urls"

export const djangoServices = {
  login,
  twoFactor,
  twoFactorRecovery,
  twoFactorRecoveryConfirm,
  register,
  resetPassword,
  recoveryPassword,
  resendCodeEmail,
  activateUser,
  
  getUnits,

  getUnit,
  getRootUnits,
  fromPortalIntel,

}

export const authenticatedDjangoServices = {
  getUser,
  logout,
  // alertsPage
  deleteAlert,
  editAlert,
  getAlerts,
  setAlert,
  getAlert,
  // adminPage
  updateUnitUser,
  activateUserByAdmin,
  updateToken,
  listUsersByUnitId,

  listUsers,
  getLevels,
}


 
/** Requests que não requerem autenticação */

function login(user){
  return api.post(djangoUrls.login , user);
}


function twoFactor(payload){
  return api.post(djangoUrls.twoFactor , payload);
}

function fromPortalIntel(payload){
  return api.post(djangoUrls.fromPortalIntel , payload);
}

function twoFactorRecovery(payload){
  return api.post(djangoUrls.twoFactorRecovery, payload);
}

function twoFactorRecoveryConfirm(payload){
  return api.post(djangoUrls.twoFactorRecoveryConfirm , payload);
}

function resetPassword(email) {
  const body = { email: email }
  return api.post(djangoUrls.resetPassword, body);  
}

function recoveryPassword(params) {
  return api.post(djangoUrls.recoveryPassword, params);  
}

function resendCodeEmail(email) {
  const body = { email: email }
  return api.post(djangoUrls.resendCodeEmail, body);  
}


function register(user) {
  return api.post(djangoUrls.register, user)
}

function getUnits() {
  return new Promise((resolve, reject) => {
    api.get(djangoUrls.getUnits)
    .then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error)
    });
})
}

function activateUser(params) {
  return new Promise((resolve,reject)=>{
    api.post(djangoUrls.activateUser, params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      });
  }) 

}

function getRootUnits() {
  return api.get(djangoUrls.getRootUnits);
}

function getUnit(id) {
  return api.get(djangoUrls.getUnit(id));
}



/** Requests que requerem autenticação */

function getUser(){
  return authenticatedApi.get(djangoAuthenticatedUrls.getUser);
}


function logout() {
  return authenticatedApi.post(djangoAuthenticatedUrls.logout);
}

function updateToken() {
  return authenticatedApi.get(djangoAuthenticatedUrls.updateToken);
}


// alertspage

function deleteAlert(id) {
  return authenticatedApi.delete(djangoAuthenticatedUrls.deleteAlert(id));
}


function editAlert(data, id) {
  return authenticatedApi.put(djangoAuthenticatedUrls.editAlert(id), data);
}


function getAlerts() {
  return authenticatedApi.get(djangoAuthenticatedUrls.getAlerts);
}

function setAlert(data) {
  return authenticatedApi.post(djangoAuthenticatedUrls.setAlert, data);
}

function getAlert(id) {
  return authenticatedApi.get(djangoAuthenticatedUrls.getAlert(id));
}

// adminPage


function activateUserByAdmin(username) {
  return authenticatedApi.post(djangoAuthenticatedUrls.activateUserByAdmin,{ 'username': username });
}
function updateUnitUser(id, username) {
  return authenticatedApi.put(djangoAuthenticatedUrls.updateUnitUser, { 'username': username, 'id_unidade': id });
}

function listUsersByUnitId(id){
  return authenticatedApi.get(djangoAuthenticatedUrls.listUsersByUnitId(id));
}


function getLevels() {
  return authenticatedApi.get(djangoAuthenticatedUrls.getLevels);
}

function listUsers() {
  return authenticatedApi.get(djangoAuthenticatedUrls.listUsers);
}