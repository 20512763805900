import { djangoConstants, localConstants } from "../constants";



export function indexedReducer(state = {app_index:"*", listIndex: ["*"]}, action) {
    
    switch (action.type) { 
        case djangoConstants.setListIndex:
            return { ...state,  listIndex: action.payload.elasticindex }
        case djangoConstants.setAppIndex: 
            return { ...state, app_index: action.payload.app_index }   
        case djangoConstants.logoutSuccess:
            return { app_index:"*", listIndex: ["*"] } 
        case localConstants.setLocation:
            return { ...state, location: action.payload.location } 

        case 'persist/REHYDRATE':
            if (action.payload){
                return {...state, ...action.payload.indexed }        
            }        
            return {app_index:"*", listIndex: ["*"]}        
        default: 
            return { ...state };
    }
}