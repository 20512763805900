import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import {useFormikContext} from 'formik';

export default function Counter({title, name, initial, errors}){       
  const {handleChange} = useFormikContext();
  
  let countError;
    //count
    try{
      countError = errors['advancedForm']['specific'][name];
    }catch (e){
      countError = " "; 
    }
  
  return (
    <div>
      <Form.Label>{title}</Form.Label>   
      <Row>
        <Col md="auto">
          <Form.Control name={`advancedForm.specific.${name}`} type="number" variant='light' 
          value={initial} onChange={handleChange} />
        </Col>
        
      </Row>
      <Form.Text className='text-muted error'>
        {countError}
      </Form.Text>
    </div>
  );    
}
