import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import Modal from './Modal';
import { BsFillEyeFill } from 'react-icons/bs'
import { IconContext } from "react-icons";
const duration = 300;

const ModalWithTransitionStyles = styled(Modal)`
  &.modal-transition-enter {
    transform: translateX(100%);
  }
  &.modal-transition-enter-active {
    transition: transform ${duration}ms;
    transform: translateX(0);
  }
  &.modal-transition-exit {
    transform: translateX(0);
  }
  &.modal-transition-exit-active {
    transition: transform ${duration}ms;
    transform: translateX(100%);
  }
`;

const SlidingModal =  ({isOpen, toggleOpen, closeDetail, body})  => {
//   const [isOpen, toggleOpen] = useToggle(false);
  return (
    <>
        <IconContext.Provider value={{ className: "badge-class-icons" }}>
         <div  className="badge badge-secondary" onClick={toggleOpen}
          data-toggle="tooltip" data-placement="left" title="Pré-visualizar" >
         <BsFillEyeFill/>  <span style={{verticalAlign:'middle', padding:5, fontSize:10}}>Pré-visualizar</span> 
         </div >
         </IconContext.Provider>
    
      <CSSTransition
        in={isOpen}
        className="modal-transition"
        classNames="modal-transition"
        unmountOnExit
        timeout={duration}
      >
        <ModalWithTransitionStyles
          title={`Detalhes`}
          open={isOpen}
          onClose={closeDetail}
          // onSave={closeDetail}
        >

        {body}
         
        </ModalWithTransitionStyles>
      </CSSTransition>
    </>
  );
};

export default SlidingModal;
