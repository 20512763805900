import React from 'react';
import { withRouter } from 'react-router-dom';
import { BsFillSkipBackwardFill } from "react-icons/bs";
import { IconContext } from "react-icons";
import './goBack.css'

const GoBack = ({ history }) => 
<li data-toggle="tooltip" data-placement="left" title="Retornar">
    <div className="goBack-icon"  onClick={() => history.goBack()}>
    <IconContext.Provider value={{ className: "global-class-icons" }}> 
    <BsFillSkipBackwardFill/> Retornar
    </IconContext.Provider>
    </div>
</li> 
export default withRouter(GoBack);