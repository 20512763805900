import { djangoConstants, localConstants } from '../constants';

export function navigationReducer(state = {
 lastRoute:null,
 sessionToken:{start:false,update:false}
}, action) {
  
  switch (action.type) {
    case localConstants.setLastRoute:
      return {...state, lastRoute:action.payload.lastRoute}
    case localConstants.clearLastRoute:
      return {...state, lastRoute:null}      
    
    // tokenManagement
    case localConstants.updateTimeToken:
      return {...state, sessionToken:{...state.sessionToken, update:false,}}
    case localConstants.startTimeToken:
      return {...state, sessionToken:{ start:true, update:true}}
    case djangoConstants.setToken:
      return {...state, sessionToken:{...state.sessionToken, update:true}}; 
    
    case djangoConstants.logoutSuccess:
      return {...state, sessionToken:{start:false,update:false,}}; 
    case 'persist/REHYDRATE':
      if (action.payload){
        return {
          ...action.payload.navigation,
         sessionToken:{...action.payload.navigation.sessionToken, update:true}
        }  
      }  
      return {lastRoute:null,sessionToken:{start:false,update:false}}      
    default:
      return {...state}
  }
}

