export const djangoUrls = {
  login: '/users/login/',
  twoFactor: '/users/login/2fa/',
  fromPortalIntel: '/users/portal-da-inteligencia/login/',
  twoFactorRecovery: '/users/login/2fa/recovery/',
  twoFactorRecoveryConfirm: '/users/login/2fa/recovery/confirm/',
  resetPassword: '/users/reset_password/',
  recoveryPassword: '/users/reset_password_confirm/',
  resendCodeEmail: '/users/resend_activation/',
  register: '/users/',
  // getUnits: '/users/units/',
  activateUser: '/users/activation/',

  getRootUnits: '/units/list/',
  getUnit: (id)=>{return `/units/${id}/`},
}

export const djangoAuthenticatedUrls = {
  getUser: '/users/me/',
  logout: '/users/logout/',
  updateToken: '/users/token-expiration/',

  // alertPage
  deleteAlert: (id) => {return `/alerts/${id}/`},
  editAlert: (id) => {return `/alerts/${id}/`},
  getAlerts: '/alerts/',
  setAlert: '/alerts/',
  getAlert: (id) => {return `/alerts/${id}/`},
  // adminPage
  updateUnitUser:'/units/update-user/',
  activateUserByAdmin: '/users/activate-user/',
  listUsersByUnitId: (id) => {return `/units/${id}/users`},
  
  getLevels: '/users/levels/',
  listUsers: '/users/',
}