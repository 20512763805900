
export const fieldsToTables = {
	
    "licitacoes-itens": ["DESCRICAOITEM","QUANTIDADE","VALOR_UNITARIO","NOMELICITANTE","COTISTA"],
    "contratos-itens": ["NUM_ITEM","DESCRICAO","QUANTIDADE","VALOR_UNITARIO","ID_TIPO_UNIDADE_MEDIDA","ID_LOTE"],
    
}


export const fieldsToTablesDownload = {
    "arquivos-doe": ["file_name", "file_type", "meta.created","file_path"],
}