import { djangoConstants, localConstants } from "../constants";

export function authenticationReducer(state={
  isLoading:false, 
  isLogged:false, 
  isPreLogged: false,
  token: {}, 
  loginData:{}
}, action){
  switch(action.type){
    case djangoConstants.stopLoading:
      return {...state, isLoading:false}
    case djangoConstants.playLoading:
      return {...state, isLoading:true}

    case djangoConstants.loginRequest:
      return {...state, isLoading:true, loggedOut: false,  isPreLogged: false, isLogged:false};
    case djangoConstants.loginSuccess:
      return {...state, loginData: action.payload.loginData, isPreLogged: true };
    case djangoConstants.loginFail:
      return {...state, isLoading:false };
      
    case djangoConstants.twoFactorRequest: 
      return {...state, isLoading:true, loggedOut: false,  isPreLogged: true, isLogged:false};
    case djangoConstants.twoFactorSuccess:
      return {...state,  isLogged:true, isPreLogged:true, loginData:{}};
    case djangoConstants.twoFactorFail:
      return {...state, isLoading:false}
    case djangoConstants.twoFactorRecoveryConfirm:
      return {...state, loginData:{...state.loginData, 
        "authenticator_code":action.payload.authenticator_code, "detail":action.payload.detail}}


    case djangoConstants.logoutRequest:
      return { ...state };
    case djangoConstants.logoutSuccess:
      return {...state, isLoading: false, isLogged:false, isPreLogged: false,loggedOut:true, token: {}}; 
    case djangoConstants.setToken:
      return {...state, token: action.token };
    case djangoConstants.unsetUser:
      return { isLoading: false, isLogged: false, loggedOut:false, token: {} }
    case localConstants.clearLastRoute:
      return {...state, loggedOut:false}
    

    case 'persist/REHYDRATE':
      if (action.payload){
        return {...action.payload.authentication}  
      }
      return {isLoading:false, isLogged:false, loggedOut: false,token: {}}
    default: 
      return {...state};
  }
}