import React from "react";
import { Formik } from 'formik';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

// import tipos de alert e funcao de conversao
import { stateToJson, getExternalName } from '../functions';
import AdvancedForm from './advanced'
import StandartForm from './standart'


// import dados pros formularios
import { Card, Form, Col, Row } from 'react-bootstrap';

// from functions
import { validate, setInitialValues } from './alertFormFunctions';

//import da função p/ salvar os dados no banco
import { authenticatedDjangoActions } from "../../../redux/actions";


// css
import '../alert.css';
import './form.css';


const getForm = {
  "true": function (){ 
    return <AdvancedForm />},
  "false": function (){ 
    return <StandartForm />},
}



//Component
function AlertForm({close, userData, listIndex, setAlert, alertData, changeItem}) {
 
  //valores iniciais do formulario
  const initialValues = setInitialValues(alertData, userData);
 
  //salvar os dados
  async function onSubmit (values) {
    if (alertData===undefined||alertData===null){
      //add
      //converter dado em um json
      const addData = stateToJson(values, userData.matricula);
      //enivarDado para api
      setAlert(addData);
      //FecharJanela
      close();
    }else{
      //edit
      const editData = stateToJson(values, userData.matricula);
      //enviar dados para edição
      changeItem(getExternalName(editData.name), editData._id, JSON.stringify(editData));
      //FecharJanela
      close();
    }
  }
  
  return (
    
    <Formik initialValues = {initialValues}
    onSubmit = {onSubmit}
    validate={validate}
    >
        {form =>(
          <Form onSubmit= {form.handleSubmit} >
            <Card >

              {/** title */}
              <Card.Title className="w3-theme-l1 card-header">
                { alertData !== undefined ? 
                `Editando Alerta:${form.values.advancedForm.name}` 
                :'Novo Alerta'}
              </Card.Title>
              <div className='textList'>

              {/* Avançado */}
              {/* <Row>
                <Col md='auto' className='void-col' />
                  <Col md='1'>
                    <Form.Label>
                      Básico
                      <Form.Check type='radio' checked={form.values.advanced===false} onChange={()=>form.setFieldValue('advanced',false)} />
                    </Form.Label>
                  </Col>
                  <Col md='1'>
                    <Form.Label>
                      Avançado
                      <Form.Check type='radio' disabled checked={form.values.advanced===true} onChange={()=>form.setFieldValue('advanced',true)}/>
                    </Form.Label>
                  </Col>
              </Row> */}

              {/** Formularios */}
              {getForm[form.values.advanced]()}
                            
              <Row>
                <Col md='auto' className='void-col' />
                <Col md='auto'>
                  <button  className='btn save-btn' type='submit'>Enviar</button> 
                </Col>
                <Col md='auto'>
                  <button  className='btn close-btn' onClick={close}>Cancelar</button>
                </Col>
              </Row>
            </div>
            </Card>
          </Form>
        )}
      </Formik>
  );
}

const mapStateToProps = state => ({
  userData: state.user.user_data,
  listIndex:state.indexed.listIndex,
});

function mapDispatchToProps(dispatch) {
  const { setAlert } = authenticatedDjangoActions;
  return bindActionCreators({
    setAlert
  }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(AlertForm);
