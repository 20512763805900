import React, { useState, useEffect, useRef } from "react";
import UploadService from '../services/uploadServices/FileUploadService';
import { Messages } from 'primereact/messages';




const UploadFiles = () => {
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [fileInfos, setFileInfos] = useState([]);
    const messages = useRef(null);

    const showError = (msg) => {
      messages.current.show({severity:'error', summary: '', detail: msg, life: 5000});
    }
    const showSuccess  = (msg) => {
      messages.current.show({severity:'success', summary: '', detail: msg, life: 5000});
    }
    
    const checkMimeType=(event)=>{
      //getting file object
      let files = event.target.files 
      //define message container
      let err = ''
      // list allow mime type
    const types = ['image/png', 'image/jpeg', 'image/gif', 'application/pdf', 'text/plain',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword',
                  'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  ]
      // loop access array
      for(var i = 0; i<files.length; i++) {
      // compare file type find doesn't matach
      // eslint-disable-next-line
          if (types.every(type => files[i].type !== type)) {
          // create error message and assign to container   
          err = 'Tipo não suportado\n '+files[i].type;
        }
      };

    if (err !== '') { // if message not same old that mean has error 
          event.target.value = null // discard selected file
          showError(err)
          return false; 
      }
    return true;

    }

    const checkFileSize=(event)=>{
      let files = event.target.files
      let size = 10485760 
      let err = ""; 
      for(var x = 0; x<files.length; x++) {
      if (files[x].size > size) {
       err += 'Arquivo muito grande.\n '+files[x].size+ " \nMáximo-10MB";
        }
      };
      if (err !== '') {
          event.target.value = null
          showError(err)
          return false
    }
    
    return true;
    
    }
    const selectFile = (event) => {
      if ( checkFileSize(event) && checkMimeType(event)){ 
        setSelectedFiles(event.target.files);
      }
      };

    const upload = () => {
        let currentFile = selectedFiles[0];
    
        setProgress(0);
        setCurrentFile(currentFile);
    
        UploadService.upload(currentFile, (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        })
          .then((response) => {
            showSuccess(response.data.message)
            return UploadService.getFiles();
          })
          .then((files) => {
            setFileInfos(files.data);
          })
          .catch((error) => {
            setProgress(0);
            showSuccess("Erro ao enviar o arquivo! "+error);
            setCurrentFile(undefined);
          });
          setSelectedFiles(undefined);
      };
    
    useEffect(() => {
        UploadService.getFiles().then((response) => {
          setFileInfos(response.data);
        });
      }, []);
  
    return (
      <div className="commentsRow">
        <div className="col-4  border-right">
            {currentFile && (
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-info progress-bar-striped"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: progress + "%" }}
                  >
                    {progress}%
                  </div>
                </div>
              )}
        
              <label className="btn btn-default">
                <input type="file" onChange={selectFile} />
              </label>
        
              <button
                className="btn btn-success btn-block"
                disabled={!selectedFiles}
                onClick={upload}
              >
                Enviar
              </button>
              <Messages ref={messages}></Messages>
                     
        </div>
        <div className="col-8  bg-white">
          <div className="card">
            <div className="card-header"><b>Arquivos enviados</b></div>
            <ul className="list-group list-group-flush">
              {fileInfos &&
                fileInfos.map((file, index) => (
                  <li className="list-group-item" key={index}>
                    {file.type}
                    <a href={file.url}>{file.name}</a>
                    {file.size}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  
  export default UploadFiles;