import TextGroup from './textGroup';
import TextGroupIcon from './textGroupIcon';
import TextHeader from './textHeader';
import Avatar from './avatar';
import Accordion from './accordion';
import AccordionMin from './accordionMin';
import Tabs from './tabs';
import Panel from './panel';
import UploadFiles from './FileUpload';

const components = {
    TextGroup: TextGroup,
    TextGroupIcon: TextGroupIcon,
    TextHeader: TextHeader,
    Avatar: Avatar,
    Accordion: Accordion,
    AccordionMin: AccordionMin,
    Tabs: Tabs,
    Panel: Panel,
    UploadFiles: UploadFiles,
}

export default components;
