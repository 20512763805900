import React from 'react';

import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment';

export default (props) => (
  <View style={styles.containerFooter} fixed wrap>
       <View style={styles.containerRow}>
       <View style={styles.footer}>

        <View style={styles.hr}>
        <Text> </Text>
        </View>
          <View style={styles.footerLeft}>
            <Text>
              SINFOR - Sistemas Integrados
          </Text>
          </View>

          <View style={styles.footerCenter}>
            <Text render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )} fixed />
          </View>

          <View style={styles.footerHight}>
            <Text>
              Impresso por: {props.user}  {moment(props.date).format('DD/MM/YYYY, h:mm')}
            </Text>
          </View>
         
        </View>
          </View>

  </View>
);

// Register font
Font.register({ family: 'Arial', src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf' });

const styles = StyleSheet.create({
  containerFooter: {
    flexDirection: 'colunm',
    borderTopWidth: 2,
    borderTopColor: '#112131',
    borderTopStyle: 'solid',
    alignItems: 'stretch',
    fontFamily: 'Arial',
    fontWeight: 'ultrabold',
    marginBottom: 10,
  },
  containerFooterRow: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
 
  
footer: {
  display: 'flex',
  flexDirection: 'row',
  fontSize: 10,
  // position: 'relative',
  
  // marginBottom: 10,
  // color: 'gray',
  width: '100%',
},
footerLeft: {
  textAlign: 'left',
  width: '33%',
  marginTop: 5,
  padding: 10,
  flexGrow: 1
},
footerCenter: {
  fontSize: 8,
  textAlign: 'center',
  width: '33%',
  marginTop: 5,
  padding: 10,
  flexGrow: 1
},
footerHight: {
  fontSize: 8,
  textAlign: 'right',
  width: '33%',
  marginTop: 5,
  padding: 10,
  fontFamily: 'Arial',
  flexGrow: 1
},
});
