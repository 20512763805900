import React from 'react';
//import {Col, Row, Button} from 'react-bootstrap';
import './styles.css';
import { FiEdit, FiTrash,  } from 'react-icons/fi';
import { GoDiffAdded } from "react-icons/go";

export const TableButton = ({color, background, icon, label, height, width, onClick,fontSize, className}) =>{
    
    const renderIcon = {
        "edit": <FiEdit />,
        "trash": <FiTrash />,
        "add": <GoDiffAdded />, 
    }
    
    
    return (
        <div className={className} size='auto' onClick={onClick} type='submit' value='submit'
        style={{background:background,color:color,height:height,width:width,
        fontSize:fontSize}}>      
            {renderIcon[icon]}   
            {'  '+label}    
        </div>
    );
}