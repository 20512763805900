import React from 'react';

import DetailsItem from '../detailsPage/detailsItem';

import { connect } from 'react-redux';

const ReportItem = ({item}) =>{
    return (
        <div style={{paddingBottom:20}}>
            <DetailsItem valor_index={item._index.split('_')[0]} entityFields={item} />
        </div>

    );
}

export default connect(null, null) (ReportItem); 