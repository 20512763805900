import React from 'react';
import {
  Redirect,
  Route
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'; 


import { isAuthenticated } from "./services/auth";
import { globalConstants } from './redux/constants';
import { localActions } from './redux/actions';

function PrivateRoute({component:Component, setLastRoute,...rest}) {
  setLastRoute(rest.location.pathname);
  return(
    <div>
      <Route {...rest} render={
          props =>(
              isAuthenticated() ? (<Component {...props}/>)
              : 
              (<Redirect to={{pathname:globalConstants.baseAppName + '/login'}} />)
              )
          }
          
      />
    </div>
  );
}

function mapDispatchToProps(dispatch){
  const { setLastRoute } = localActions;
  return bindActionCreators({
    setLastRoute
  }, dispatch);
}

export default connect(null,mapDispatchToProps)(PrivateRoute);
