import React from "react";

// import dados pros formularios
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

// componente de contagem
import Counter from './counter';

// css
import '../../alert.css';


export default function NewTerm() {
  const {values, errors, handleChange} = useFormikContext();
  let term_filterError;
  
  //term
  try{
    term_filterError = errors.advancedForm.specific.term_filter;
  }catch (e){
    term_filterError = " "; 
  }
  

  return(
    <div>
      {/** filtro para termos */}
      <Form.Group>
        <Form.Label>Termo Monitorado</Form.Label>                
        <Form.Control type="text" name="advancedForm.specific.term_filter" value={values.advancedForm.specific.term_filter}  
          onChange={ handleChange }/>  
        <Form.Text className="text-muted error">
          {term_filterError}
        </Form.Text>
      </Form.Group>
      {/** term_window_size */}
        
      <Counter name="term_window_size" title="Duração de um termo(dias)" 
      initial={values.advancedForm.specific.term_window_size} 
        errors={errors} />
      
    </div>
  );
}
