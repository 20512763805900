import { djangoServices, authenticatedDjangoServices } from '../../../services';
import { djangoConstants, localConstants } from '../../constants';
import { globalConstants } from '../../constants';
import { alertActions, localActions } from '../../actions';
import { setAuthToken } from "../../../services/newapi";

import { 
  saveToken, 
  localExpireTime, 
  removeToken,
  clearStorage, 
} from './utils';


export const djangoActions = {
  login,
  twoFactor,
  twoFactorRecovery,
  twoFactorRecoveryConfirm,
  stopLoading,
  playLoading,
  register,
  resetPassword,
  recoveryPassword,
  resendCodeEmail,
  activateUser,
  getRootUnit,
  getUnit,
  getUnitNodeTree,
  getUnitNodeTreeChildren,
  getUnitNodeForTable,
  getUnitNodeTreeForTable,
  getUnitsList,
  getUnitItem,
  fromPortalIntel,
}

export const authenticatedDjangoActions = {
  setUser,
  logout,
  updateToken,
  // alertsPage
  deleteAlert,
  editAlert,
  getAlerts,
  getAlert,
  setAlert,
  //adminPage
  getAdminUsers,
  activateUser,
  activateUserByAdmin,
  listUsersByUnitId,
  
  updateUnitUser,
  setLevelList,
  getUsersList,
}
/** Funcões que não requerem autenticação */

function stopLoading(){
  return dispatch =>{ 
    dispatch({type:djangoConstants.stopLoading});
  }
}

function playLoading(){
  return dispatch =>{ 
    dispatch({type:djangoConstants.playLoading});
  }
}

function login(user){
  return dispatch =>{
    dispatch({type: djangoConstants.loginRequest});
    djangoServices.login(user)
      .then(response=>{
        console.log(response.data)
        dispatch({type:djangoConstants.loginSuccess, payload:{loginData: response.data}});
      })
      .catch(error=>{
        dispatch({type:djangoConstants.loginFail});
        dispatch(
          alertActions.error(
            error.response?
              error.response.data.non_field_errors[0]:
              'Não foi possivel Login'));
      });
  }
}

function twoFactor(payload){
  return dispatch =>{
    dispatch({type: djangoConstants.twoFactorRequest});
    djangoServices.twoFactor(payload)
      .then(response=>{
        const { token } = response.data;
        setAuthToken(token)
        dispatch(setUser());  
        dispatch(setToken(token));
        dispatch({type:djangoConstants.twoFactorSuccess})
      
      })
      .catch(error=>{
        dispatch({type:djangoConstants.twoFactorFail});
        // pre token die
        if (error.response.data.detail==="Credenciais inválidas!" || error.response.data.pre_token === "Este campo é obrigatório."){
          dispatch(alertActions.error("Tempo para autenticação expirou!\n Faça login novamente"))
          dispatch({type:djangoConstants.logoutSuccess})
        }
        // error no codigo
        else{
          dispatch(alertActions.error(error.response.data.detail?error.response.data.detail: error.response.data.totp_code))
        }
        
      });
  }
}

function fromPortalIntel(payload){
  return async dispatch =>{
    dispatch({type: djangoConstants.loginRequest});
    await djangoServices.fromPortalIntel(payload)
      .then(async response=>{
        const { token } = response.data;
        setAuthToken(token)
        dispatch(setToken(token));
        await dispatch(setUser());  
        // dispatch({type:djangoConstants.twoFactorSuccess})
      
      })
      .catch(error=>{
        dispatch({type:djangoConstants.twoFactorFail});
        dispatch(alertActions.error("Falha na conexão com o SINFOR!\n Tente novamente mais tarde."));
        dispatch(logout())
                // throw new Error('Não foi possivel Fazer login'+ error);
        
      });
  }
}

function twoFactorRecovery (payload){
  return dispatch => {
    djangoServices.twoFactorRecovery(payload)
      .then(response=>{
        localActions.redirect(`${globalConstants.baseAppName}/two_factor/recovery`)
      })
      .catch(error=>{
        if (error.response.data.detail==="Credenciais inválidas!" || error.response.data.pre_token === "Este campo é obrigatório."){
          dispatch(alertActions.error("Tempo para autenticação expirou!\n Faça login novamente"))
          dispatch({type:djangoConstants.logoutSuccess})
        }
        // error no codigo
        else{
          dispatch(alertActions.error(error.response.data.detail?error.response.data.detail: error.response.data.totp_code))
        }
      })
  }
}

function twoFactorRecoveryConfirm(payload){
  return async dispatch => {
    await djangoServices.twoFactorRecoveryConfirm(payload)
      .then(response=>{
        dispatch({type:djangoConstants.twoFactorRecoveryConfirm,payload:response.data})
        setTimeout(()=>{
          localActions.redirect(`${globalConstants.baseAppName}/two_factor`)
        },500);
      })
      .catch(error=>{
        if (error.response.data.detail==="Credenciais inválidas!" || error.response.data.pre_token === "Este campo é obrigatório."){
          dispatch(alertActions.error("Tempo para autenticação expirou!\n Faça login novamente"))
          dispatch({type:djangoConstants.logoutSuccess})
        }
        // error no codigo
        else{
          dispatch(alertActions.error(error.response.data.detail?error.response.data.detail: error.response.data.totp_code))
        }
      })
  }
}


  
function setToken(token, save= true){
  return async dispatch =>{
    if (save){
      await saveToken(token);
    }
    dispatch({type:djangoConstants.setToken, token: {expire: localExpireTime()}});
  }
}

function register(user) {
  return async dispatch => {
      dispatch({type: djangoConstants.registerRequest});
      await djangoServices.register(user)
        .then(response => {
          dispatch(alertActions.success('Cadastro realizado com sucesso!\n Confirme'
          + ' seu cadastro no email informado.'));
          dispatch({type: djangoConstants.registerSucess });
        })
        .catch(error => {
          if (error.response) { // status code out of the range of 2xx
            dispatch(alertActions.error('Não foi possível realizar seu cadastro.\n Verifique os dados informados!'));
            dispatch({type: djangoConstants.registerFail, user, errors: error.response.data});
            console.log(error.response.data);
            
          } else if (error.request) { // The request was made but no response was received
            dispatch(alertActions.error('Não foi possível fazer seu cadastro.\n '+ error.message));
            dispatch({type: djangoConstants.registerFail, user, errors: error.request});
            console.log(error);
          } else {// Error on setting up the request
            dispatch(alertActions.error('Não foi possível fazer seu cadastro.\n '+ error.message));
            dispatch({type: djangoConstants.registerFail, user, errors: error.message});
            console.log('Error', error.message);
          }
        })
        
      // setTimeout(()=>{
      //   dispatch(alertActions.clearIn())
      // },5000)
  }
}

function resetPassword(email) {
  return dispatch =>{
    djangoServices.resetPassword(email)
      .then(response=>{
        dispatch(alertActions.success('Acesse o email para continuar.'));
      })
      .catch(error=>{
        dispatch(alertActions.error('Não foi possivel enviar mensagens no email. '+error));
      })
  }
}


function recoveryPassword(params) {
  return dispatch =>{
    djangoServices.recoveryPassword(params)
      .then(response=>{
        dispatch(alertActions.success('Senha atualizada com sucesso'));
      })
      .catch(error=>{
        dispatch(alertActions.error('Não foi possivel trocar a senha.'));
      })
  }
}



function resendCodeEmail(email) {
  return dispatch => {
    djangoServices.resendCodeEmail(email)
      .then(() => {
          dispatch(alertActions.success('Um coódigo de ativação enviado para seu email!'))
          dispatch(alertActions.clearIn());
      })
      .catch(() => {
        dispatch(alertActions.error('Falha ao reenviar código. Por favor, entre' 
        + ' em contato com o(s) administrador(es) do sistema!'))
        dispatch(alertActions.clearIn());
      });
  }

}

function activateUser(params) {
  return dispatch => {
    djangoServices.activateUser(params)
      .then(response => {
        dispatch(alertActions.success('Email ativado com sucesso. Para ativar sua conta de usuário ' 
        + 'entre em contato com o(s) administrador(es) do sistema!'));
      })
      .catch(error => {
        dispatch(alertActions.error('Falha ao ativar email. Por favor, entre em contato' 
        + ' com o(s) administrador(es) do sistema - dint.ti@ssp.pi.gov.br'));
      });
  }


}


/** pega as nos raizes da unidade */
async function getRootUnit(){
  return await new Promise((resolve)=>{
    djangoServices.getRootUnits()
      .then(response=>{
        resolve(response.data);
      })
  });
}

/** pega o no unidade individualmente */
async function getUnit(id){
  return await new Promise((resolve)=>{
    djangoServices.getUnit(id)
      .then(response=>{
        resolve(response.data);
      })
      .catch(()=>{
        throw new Error('Não foi possivel Carregar Unidade');
      });
  }); 
}

/** componente de seleção de unidades */

/** Verifica a root e retorna a tree Select de unidades completa */
async function getUnitNodeTree(isRoot = false, id = undefined){
  
  let nodes = [];
  if (isRoot) {
    let roots = await getRootUnit();
    for(let index = 0;index<roots.length; index++){
      nodes.push(await getUnitNode(roots[index].id, null, index));
    }
  }
  else{
    if (id !== undefined){
      nodes.push(await getUnitNode(id, null, 0));
    }
  }
  return nodes;
}

async function getUnitNodeTreeChildren(isRoot = false, id = undefined){
  
  let nodes = [];
  if (isRoot) {
    let roots = await getRootUnit();
    for(let index = 0;index<roots.length; index++){
      nodes.push(await getUnitNode(roots[index].id, null, index));
    }
  }
  else{
    if (id !== undefined){
      let node = await getUnit(id);
      for(let index = 0;index<node.children_count; index++){
        nodes.push(await getUnitNode(node.children[index].id, null, index));
      }
    }
  }
  return nodes;
}


/** Gera cada node da tree select */
async function getUnitNode(id, rootKey, key){
  let data = await getUnit(id);
  let node = {
    "key":`${rootKey!==null?rootKey:""}${rootKey!==null?"-":""}${key}`,
    "label": `${data.instituicao} - ${data.nome}`,
    "data": `${data.instituicao} - ${data.nome}`,
    "id": data.id,
  }
  let childrenList = []
  if (data.children_count>0){
    for (let index = 0;index<data.children_count; index++){
      childrenList.push(await getUnitNode(data.children[index].id, node.key,index));
    }
  }
  node.children = childrenList  
  return node
}

/** tabela de unidades */
/** Verifica a root e retorna a treeTable de unidades completa */
async function getUnitNodeTreeForTable(isRoot = false, id = undefined){
  try{
    let nodes = [];
    if (isRoot) {
      let roots = await getRootUnit();
      for(let index = 0;index<roots.length; index++){
        nodes.push(await getUnitNodeForTable(roots[index].id, null, index));
      }
    }
    else{
      if (id !== undefined){
        nodes.push(await getUnitNodeForTable(id, null, 0));
      }
    }
    return nodes;
  }catch{
    return 'error'
  }
}

/** Gera cada node da treeTable */
async function getUnitNodeForTable(id, rootKey, key){
  let data = await getUnit(id);
  let node = {
    "key":`${rootKey!==null?rootKey:""}${rootKey!==null?"-":""}${key}`,
    "data":{
      "instituicao": data.instituicao,
      "nome": data.nome,
      "id": data.id,
    }
  }
  let childrenList = []
  if (data.children_count>0){
    for (let index = 0;index<data.children_count; index++){
      childrenList.push(await getUnitNodeForTable(data.children[index].id, node.key,index));
    }
  }
  node.children = childrenList  
  return node
}

async function getUnitsList (isRoot = false, id = undefined) {
  try{
    let units = []
    if (isRoot){
      let roots = await getRootUnit();
      for(let index = 0;index < roots.length; index++){
        units = units.concat(await getUnitItem(roots[index].id));
      }
    }else{
      if (id !== undefined){
        let unit = await getUnit(id);
        for(let idx = 0;idx < unit.children_count; idx++){
          units = units.concat(await getUnitItem(unit.children[idx]['id']));
        }
      }
    }
    return units;
  }catch (Err){
    return 'Error';
  }
}

async function getUnitItem (id) {
  let data = await getUnit(id);
  let units = [data]
  if (data.children_count>0){
    for (let index = 0; index < data.children_count; index++){
      units = units.concat(await getUnitItem(data.children[index].id));
    }
  }
  return units;
}


function getAppIndex(array) {
  return array.join(',');
} 

/** Funcões que requerem autenticação */
 
function setUser(){
  const result = async dispatch =>{
    await authenticatedDjangoServices.getUser()
      .then(response=>{
        const user = response.data;
        console.log(user)
        dispatch({type:djangoConstants.setUser, payload:{user} })
      
        const { elasticindex }= user.node_unidade;
        const app_index = getAppIndex(elasticindex);
        dispatch({type:djangoConstants.setListIndex, payload:{elasticindex} })
        dispatch({type:djangoConstants.setAppIndex, payload:{app_index} })
        dispatch({type:localConstants.startTimeToken})
                     
        setTimeout(() => {
          elasticindex.length > 0 ? localActions.redirect(`${globalConstants.baseAppName}/`) :
          dispatch(logout());
        }, 500);
      })
      .catch((error)=>{
        console.log(error.message)
        if (error.message === "Cannot read properties of undefined (reading 'node_unidade')") {
          dispatch(alertActions.error("Usuário não adicionado a uma unidade"))
        }
        else {
          dispatch(alertActions.error("Não foi possivel buscar os  dados do usuário" + error))
        }
        dispatch({type:djangoConstants.unsetUser});
        dispatch(logout());
        dispatch(alertActions.clearIn());
      })
};
return result;
}

// function getUser(){
//   return new Promise((resolve, reject)=>{
//     authenticatedApi.get(djangoAuthenticatedUrls.getUser)
//     .then(async response=>{
//       console.log(response)
//       const { elasticindex }= response.data.node_unidade;
//       const app_index = await getAppIndex(elasticindex);
//       resolve({user:response.data, elasticindex , app_index})
      
//     })
//     .catch(error=>{
//       reject(error)
//     })
//   }) 
// } 

function logout(){
  return dispatch =>{
    dispatch({type:djangoConstants.logoutRequest});
    authenticatedDjangoServices.logout();
    dispatch({type:djangoConstants.logoutSuccess});
    setTimeout(()=>{
      removeToken();
      clearStorage();   
      const path  = `${globalConstants.baseAppName+'/login'}`
      window.location.assign(path)
    },500);
  }
}

function updateToken(){
  return dispatch =>{
    authenticatedDjangoServices.updateToken()
      .then(response=>{
        const { token } = response.data;
        dispatch(setToken(token, false));
      })
      .catch(error=>{

      })
  }
}


// admin
function updateUnitUser(username, id) {
  const {  warning, success ,error, clearIn } = alertActions;
  return async (dispatch) => {
    dispatch(warning('Alterando...'));
    await authenticatedDjangoServices.updateUnitUser(id, username)
      .then(response => {
        dispatch(success(response.data.detail));
        // setTimeout(()=>{
          // const path  = `${globalConstants.baseAppName+'/admin/users/'}`
          // window.location.assign(path)
        // },2000);
      })
      .catch(erro => {
        let errorMessage = erro.response.data.detail? erro.response.data.detail: "Não foi possivel ativar este usuário"
        
        dispatch(error(errorMessage));
      })
    // dispatch(getAdminUsers());
    setTimeout(()=>{
      dispatch(clearIn())
    },2000)
  }
}


function activateUserByAdmin(username) {
  const {  warning, success, error, clearIn } = alertActions;
  return dispatch => {
    dispatch(warning('Alterando...'));
    authenticatedDjangoServices.activateUserByAdmin(username)
      .then(response => {
        dispatch(getAdminUsers());
        dispatch(success(response.data.detail));
      })
      .catch(erro => {
        dispatch(getAdminUsers()); 
        let errorMessage = erro.response.data.detail? erro.response.data.detail: "Não foi possivel ativar este usuário"
        
        dispatch(error(errorMessage));
      })
    setTimeout(()=>{
      dispatch(clearIn())
    },2000)
  }
}

async function listUsersByUnitId(id){
  return await new Promise((resolve,reject)=>{
    authenticatedDjangoServices.listUsersByUnitId(id)
      .then(response=>{
        resolve(response.data);
      })
      .catch(erro=>{
        reject('error');
      });
  });  
}


function getAdminUsers() {
  return dispatch => {
    authenticatedDjangoServices.listUsers()
      .then(response => {
        dispatch({ type: djangoConstants.setUserList, payload:{ userList: response.data } });
      })
      .catch(error => {
        dispatch(alertActions.error("Não foi possivel localizar usuários."))
      })
  }
}

function setLevelList() {
  return async dispatch => {
    await authenticatedDjangoServices.getLevels()
      .then(response => {
        dispatch({ type: djangoConstants.setLevelList, payload:{lvlList: response.data} });
      })
      .catch(error => {
        dispatch(alertActions.error(error));
      })
  }
}

/** Listar usuarios ao qual o administrador tem permisão de acesso */
async function getUsersList (unitsId = []){
  try{
    let users = [];
    if (typeof unitsId === 'object'){
      for(let index = 0; index < unitsId.length; index++){
        users = users.concat(await listUsersByUnitId(unitsId[index].id));
      }
      return users;
    }
    return 'void';
  }catch{
    return 'Error'
  }
}


// alerts
function deleteAlert(id) {
  return dispatch => {
    authenticatedDjangoServices.deleteAlert(id)
      .then(response => {
        dispatch({ type: djangoConstants.deleteAlert })
        dispatch(alertActions.success("Alerta Excluído com sucesso."))
        dispatch(alertActions.clearIn())
      })
      .catch(error => {
        dispatch(alertActions.error("Não foi possível excluir o alerta," 
         + "contate os administradores do sistema."))
      })
  }
}

function editAlert(data, id) {
  return dispatch => {
    authenticatedDjangoServices.editAlert(data, id)
      .then(response => {
        dispatch({ type: djangoConstants.editAlert })
        dispatch(alertActions.success("Alerta Alterado Com Sucesso!"))
        dispatch(alertActions.clearIn())
      })
      .catch(error => {
        dispatch(alertActions.error("Erro ao Alterar o Alerta, Contate os Administradores de Sistema."))
      })
  }
}


function getAlerts() {
  return dispatch => {
    authenticatedDjangoServices.getAlerts()
      .then(response => {
        dispatch({ type: djangoConstants.getAlerts, payload:{alerts:response.data}})
      })
      .catch(error => {
        dispatch({ type: djangoConstants.getAlertsError })
        dispatch(alertActions.error("Não foi possivel encontrar os alertas, contate os administradores do sistema."))
      })
  }
}



function setAlert(data) {
  return dispatch => {
    authenticatedDjangoServices.setAlert(data)
      .then(response => {
        dispatch({ type:djangoConstants.setAlert })
        dispatch(alertActions.success("Alerta Salvo Com Sucesso!"))
        dispatch(alertActions.clearIn())
      })
      .catch(error => {
        dispatch(alertActions.error("Erro ao Salvar o Alerta, Contate os Administradores de Sistema"))
      })
  }
}


function getAlert(id) {
  authenticatedDjangoServices.getAlert(id)
    .then(response => {  
      return (response.data)
    })
    .catch(error => {
      console.log(error)
    })
}
