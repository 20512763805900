import React from 'react';

// import "./alert.css";

import { TableButton } from '../../../components/button'

//table
import {Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';


const OrgaosItem = ({count, name, onClick, deleteItem, id}) => {
    return (
        <Tr>
            <Td>{count}</Td>
            <Td>{name}</Td>
            <Td>    
                <TableButton background="transparent" color="#007bff" label='Editar' icon={'edit'} onClick={onClick} />
            </Td>
            <Td>
                <TableButton background="transparent" color={"#dc3545"} label='Deletar' icon={'trash'} onClick={()=>deleteItem(name,id)} />
            </Td>
        </Tr>
    ); 
}
        
export default OrgaosItem;