import React from 'react';
import PropTypes from 'prop-types';
import {
	MultiList,
	SingleDropdownList,
	SingleDropdownRange,
	DateRange,
	SingleList,
//	RangeSlider,
//	TagCloud
} from '@appbaseio/reactivesearch';
import './SearchFilters.css'
import components from '../../components'
import { indexesOfFilter, indexesShortNames } from '../../config';
import MomentLocaleUtils , {formatDate,	parseDate, }from "react-day-picker/moment";
import "moment/locale/pt";
import pt from 'date-fns/locale/pt';
import { registerLocale } from 'react-datepicker';

registerLocale('pt', pt);

const { Accordion } = components
  
const FORMAT = "DD/MM/yyyy";

const SearchFilters = ({ currentTopics, setTopics, visible, valor_index }) => (
	visible ?
	<div>
		{indexesOfFilter[valor_index] ?
		<div style={{fontWeight: 800, fontSize: 16}}> <span className={'text-muted'}>Filtros disponíveis para: </span>{ indexesShortNames[valor_index.split(',',1)] }</div> 
		: ""
		}

		<div className="showFilter " >
		{ indexesOfFilter[valor_index] && indexesOfFilter[valor_index]['periodic'] ? <div className="showFilter-block">
		
		<Accordion icon="check" label={indexesOfFilter[valor_index]['periodic'][0]} className="header-filter">
			<SingleDropdownRange
				componentId="ultimos"
				dataField={indexesOfFilter[valor_index]['periodic'][1]}
				placeholder="Selecione..."
				filterLabel="Período"
				data={[
					{ start: 'now-1d', end: 'now', label: 'Últimas 24 horas' },
					{ start: 'now-1w', end: 'now', label: 'Última semana' },
					{ start: 'now-1M', end: 'now', label: 'Últimos 30 dias' },
					{ start: 'now-6M', end: 'now', label: 'Últimos 6 meses' },
					{ start: 'now-1y', end: 'now', label: 'Último ano' },
				]}
				style={{
					marginTop: 0,
					paddingTop: 0,
					borderTop: '0px #c4c4c4 solid'
				}}
			/>
		</Accordion>
		</div>
		: '' }

{ indexesOfFilter[valor_index] && indexesOfFilter[valor_index]['DateSensor'] ? <div className="showFilter-block">
		<Accordion icon="check" label={indexesOfFilter[valor_index]['DateSensor'][0]}>
		<DateRange
			className="dateInput"
			componentId="DateSensor"
			dayPickerInputProps={{
				formatDate,
				parseDate,
				format:FORMAT,
				// formatDate,
				// parseDate,
				dayPickerProps: { localeUtils: MomentLocaleUtils, locale: 'pt' }
			}}
					 
			filterLabel={indexesOfFilter[valor_index]['DateSensor'][0]}
			dataField={indexesOfFilter[valor_index]['DateSensor'][1]}
			placeholder={{
				start: 'Data Inicial',
				end: 'Data final',
			}}
			// defaultSelected={{
			// 	start: "2017-01-17",
			// 	end: "2017-01-23"
			// }}
			style={{"fontSize":10}}
			numberOfMonths={1}
			queryFormat="date"
			showFilter={true}
			/>
			</Accordion>
		</div>
		: ''}

		{ indexesOfFilter[valor_index] && indexesOfFilter[valor_index]['dropdownList_1'] ? <div className="showFilter-block">
		<Accordion icon="check" label={indexesOfFilter[valor_index]['dropdownList_1'][0]}>
			<SingleDropdownList
						className="singleDropdownList"
						componentId="dropdownList_1"
						filterLabel={indexesOfFilter[valor_index]['dropdownList_1'][0]}
						dataField={indexesOfFilter[valor_index]['dropdownList_1'][1]}
						showCount={false}
						showSearch={false}
						size={1000}
						style={{
							marginTop: 0, 
							paddingTop: 0,
							borderTop: '0px #c4c4c4 solid',
						}}
					//	title="Unidade Policial"
						placeholder="Selecione..."
					/>
		</Accordion>
		</div> : '' }

		{ indexesOfFilter[valor_index] && indexesOfFilter[valor_index]['SingleList_1'] ? <div className="showFilter-block">
		<Accordion icon="check" label={indexesOfFilter[valor_index]['SingleList_1'][0]}>
			<SingleList
				className="singleList"
				componentId="SingleList_1"
				filterLabel={indexesOfFilter[valor_index]['SingleList_1'][0]}
				dataField={indexesOfFilter[valor_index]['SingleList_1'][1]}
				size={10000}
				sortBy="count"
				//defaultValue="London"
				selectAllLabel="Todos"
				showRadio={true}
				showCount={false}
				showSearch={true}
				placeholder="Digite..."
				react={{
					and: ["CategoryFilter", "SearchFilter"]
				}}
				showFilter={true}
				URLParams={false}
				loader="Carregando ..."
				/>
		</Accordion>

		</div>
		: ''}

		{ indexesOfFilter[valor_index] && indexesOfFilter[valor_index]['multiList_1'] ? <div className="showFilter-block">
		<Accordion icon="check" label={indexesOfFilter[valor_index]['multiList_1'][0]}>
			<MultiList
				className="singleList"
				componentId="multiList_1"
				filterLabel={indexesOfFilter[valor_index]['multiList_1'][0]}
				dataField={indexesOfFilter[valor_index]['multiList_1'][1]}
				showCheckbox={true}
				showCount={false}
				showSearch={true}
				size={10000}
				sortBy="desc"
				style={{
					marginTop: 0,
					paddingTop: 0,
					borderTop: '0px #c4c4c4 solid'
				}}
				placeholder="Digite..."
			/>
		</Accordion>
		</div> : '' }
	
		{ indexesOfFilter[valor_index] && indexesOfFilter[valor_index]['multiList_2'] ? <div className="showFilter-block">
		<Accordion icon="check" label={indexesOfFilter[valor_index]['multiList_2'][0]}>
			<MultiList
				className="singleList"
				componentId="multiList_2"
				filterLabel={indexesOfFilter[valor_index]['multiList_2'][0]}
				dataField={indexesOfFilter[valor_index]['multiList_2'][1]}
				showCheckbox={true}
				showCount={false}
				showSearch={true}
				size={10000}
				sortBy="desc"
				style={{
					marginTop: 0,
					paddingTop: 0,
					borderTop: '0px #c4c4c4 solid'
				}}
				placeholder="Digite..."
			/>
		</Accordion>
		</div> : '' }
	
		</div>
	</div>
	: ""
			
);

SearchFilters.propTypes = {
	currentTopics: PropTypes.arrayOf(PropTypes.string),
	setTopics: PropTypes.func,
	visible: PropTypes.bool,
};

export default SearchFilters;