
import { apiElastic } from './index'

export class ServicesClass {

    async getWithSQL(index, param, value, fieldsToTables="*") {

    const url = `/_opendistro/_sql`
    const body = {
      "query": `SELECT ${fieldsToTables} FROM ${index} WHERE ${param}='${value}'`
    }
    return await new Promise((resolve, reject) => {
      apiElastic.post(url, body)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.log("Não foi possível buscar os itens."+error);
        });
    });
  }

}