import React, { useState, useEffect }  from "react";
import '../loginPage/styles.css';
import './register.css';

const TextError = ({requestError, formError, value, defaultValue, field }) =>{
    const [error, setError] = useState("");

    const setErrorValue = (requestError, formError, value, defaultValue, field) =>{
        try{
            if(value[field] === defaultValue[field]){
                setError(requestError[field]);
            }else if(formError[field] !== ""){
                setError(formError[field]);
            }else{
                setError("");
            }
        }catch{
            try{
                if (formError[field]!==""){
                    setError(formError[field]);
                }else{
                    setError("");
                }
            }catch{
                setError("");
            }        
        }


    }

    useEffect(()=>{
        setErrorValue(requestError, formError, value, defaultValue,field)
    },[requestError, formError, value, defaultValue, field])


    return(
        error!== "" ?<small className="form-text red">{error}</small>:<div></div>
    )
}


export default TextError;