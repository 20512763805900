import React, { useState } from 'react';

import ToggleMode from '../../common/template/toggleModeGridList';
import { getFieldName } from '../../services';

import { fieldsToLabels, indexesOfList } from '../../config';
import { renderDetailsFields } from '../../components/renderFields';
import createMarkup from '../../components/createMarkup';

import { Scrollbars } from 'react-custom-scrollbars';
import ToggleAddToReport from './ToggleAddToReport';

const DetailsItem = ({ valor_index, entityFields }) => {
    const [isGrid, setToggleMode] = useState(false);
    const title_label = getFieldName(fieldsToLabels[valor_index], indexesOfList[valor_index]['title']) + ": ";
    const title_value = entityFields[indexesOfList[valor_index]['title']];
    return (
        <div className="w3-third2">

            <div className="w3-theme-l1 d-flex flex-row" style={{ alignItems: 'center' }}>
               
                <div className="topic-label-details">
                    {title_label}
                    <span className="text-muted" dangerouslySetInnerHTML={createMarkup(title_value)}></span> 
                </div>

                <div className="actions" >
                    <ToggleMode handlemode={() => setToggleMode(!isGrid)} />
                    <ToggleAddToReport item={entityFields} />
                </div>
            </div>
            <br />
            <Scrollbars style={{ height: 400 }}>
                {renderDetailsFields(entityFields, valor_index)}
            </Scrollbars>

            
        </div>


    );
}


export default (DetailsItem);



