import  React , {Component} from "react";

import ContentHeader from '../../common/template/contentHeader';
import ContentBody from '../../common/template/contentBody';

import SideBar from './SideBar';
import FolderList from './FolderList';
import NewFolderModal from './newFolderModal';
import './folder.css';



class FolderPage extends Component{
  constructor(props){
    super(props);
    this.state = {
      newFolder:false,
      listUpdate:false,
    }
  }
  
  updateList(value){
    this.setState({
      ...this.state,
      listUpdate:this.state.listUpdate&&value
    });
  }

  modalAdd(){
    this.setState({
      ...this.state,
      newFolder:!this.state.newFolder
    });
  }

  render(){
    const { newFolder, listUpdate } = this.state;
    console.log("list update: "+listUpdate)
    return(
      <div className='page'>
        <ContentHeader staticL={true} brand={true} border={true} color={true} shadow={true}/>
          <ContentBody>
            {newFolder&&<NewFolderModal newFolder={newFolder} updateList={()=>{this.updateList()}} hide={()=>{this.modalAdd()}}/>}
            <div className='wrapper'>
              <SideBar hideNewFolderModal={()=>{this.modalAdd()}}/>
              <FolderList updateList={()=>{this.updateList()}} listUpdate={listUpdate}/>
            </div>
          </ContentBody>                               
      </div>
    );
  }
}

export default FolderPage