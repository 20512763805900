import { djangoConstants, elasticConstants } from '../constants';

export function setFields(state = {fields:[]}, action) {
  switch (action.type) {
    case elasticConstants.setFields:
      return {...state, fields: action.payload.fields };
    case djangoConstants.logoutSuccess:
      return {...state, fields:[]}      
    case 'persist/REHYDRATE':
      if (action.payload){
        return {...state, ...action.payload.fields }  
      }    
      return {fields:[]}  
    default:
      return state;
  }
  }