import React, { Component } from 'react';

import './folder.css';


import Item from './Item';

class FolderStructure extends Component {
  render() {
    const headersData = {name:"Nome",description:"Descrição",type:'header'}
    
    const folderData = this.props.list;
    return (
      <div className='conteiner structure-folders scrollable'>
        <Item data={headersData} typeLine='header'/>
        {folderData.map((element, key)=><Item key={key} data={element._source} typeLine='item'/>)}
      </div>
    );  
  }
}



export default FolderStructure;