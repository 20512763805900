import React, { useState } from 'react';

// import components
import AlertComponent from './alertComponent';

// import - redux
import { connect } from 'react-redux';
//baseComponent
import BasePage from '../homePage/base';
//css
import './alert.css';
//deleteModal
import DeleteAlertModal from './deleteAlertModal';
import UpdateAlertModal from './updateAlertModal';

import AlertDismissibleExample from '../../components/alert'


const AlertPage = ({alert}) => {
    //state
    const [isDeleting, setIsDeleting] = useState(false);
    const [data, setData] = useState({})
    const [isChanging, setIsChanging] = useState(false);
    //functions
    const changeDeleting = () => {setIsDeleting(!isDeleting)}
    const changeChanging = () => {setIsChanging(!isChanging)}
    const deleteItem = (name, id) => {
        setData({name,id});
        changeDeleting();
    }
    const changeItem = (name, id, itemData) => {
        setData({name, id, itemData});
        changeChanging();
    }
    let alertComponent = <AlertDismissibleExample variant={alert.type} title={alert.title} message={alert.message}/>

    return (
        <BasePage level1={<li className="current"><b>{'Alertas'}</b></li>} >
            <div style={{position: 'absolute', top: 0, right: 0 }}>
                {alert.type ? alertComponent : ''}
            </div> 
            {isDeleting?<DeleteAlertModal data={data} show={isDeleting} 
                close={changeDeleting} />:""}
            <div>
                {isChanging?<UpdateAlertModal data={data} show={isChanging} 
                    close={changeChanging} />:""}
                <div></div>
                <AlertComponent deleteItem={deleteItem} changeItem={changeItem}/>
            </div>
        </BasePage>  

    );
    
}


function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert,
    };
}

export default connect(mapStateToProps)(AlertPage);
