import React from 'react'
import { Link } from 'react-router-dom'
import { globalConstants } from '../../redux/constants'
import './breadcrumb.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default props => (

    <ol className="cd-breadcrumb custom-separator">
        <li className="visited">
             <Link to={globalConstants.baseAppName + "/"}>
                <FontAwesomeIcon icon={['fas', `home`]} />
                {' Home'}
             </Link> 
        </li>
        {props.level1}
        {props.level2}
    </ol>



)