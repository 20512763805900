import React from "react";
import { IconContext } from "react-icons";
import LogoSSP from "../../assets/logo_piaui.png";
import { Footer } from '../../components/footer';
import ApresentacaoHeader from './apresentacaoHeader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Particle from "../../components/particle";
import CarouselComponent from "../../components/carousel";

export const Box = (props) => {
    return (
    <div className={` `}> 
        <IconContext.Provider value={{ className: "initial-class-icons" }}> 
            <div className='icon-initial'> 
                {props.icon} &nbsp;
                <h1 className="common-BodyTitle">{props.title}</h1>
            </div>
        </IconContext.Provider>
            <div className='inner'> 
                <p className="common-BodyText">{props.text}</p> 
            </div> 
        </div> 
)
    }

export default (props) => (
    <div className="div-left">
     
        <ApresentacaoHeader color={true} shadow={true} border={false} staticL >
        <div className='divImgDinte'>
            <img className="" src={LogoSSP} alt="Sinfor logo" style={{height:62}} /> 
           <div className='divTextDinte'>
               <strong style={{fontSize:14, color:'#424242'}} >SECRETARIA DE SEGURANÇA PÚBLICA</strong>
                <em><strong style={{fontSize:10, color:'#999'}}>DIRETORIA DE INTELIGÊNCIA ESTRATÉGICA - DINTE</strong></em>
            </div> 
        </div>
        </ApresentacaoHeader> 
        
        <a className="loginScreenButton" href={props.idHref} ><FontAwesomeIcon icon={['fas', `arrow-down`]} />{" "+props.label}</a>
                {/* <Particle /> */}
     
        <CarouselComponent /> 
            
       <Footer/>
      {/* <a href="http://www.pc.pi.gov.br" target="_blank" rel="noopener noreferrer">
                Acesse nossos sistemas</a> */}
     
    </div>
)