import { globalConstants } from '../../constants';

import { 
  addHours,
} from 'date-fns';


/** Gera o tempo de vida do token no navegador: soma por padrão uma hora a hora atual */
export function localExpireTime(hours = 1){
  let now = new Date();
  now = addHours(now, hours);
  now = now.toString();
  return now;
}

export async function saveToken(token){
  await localStorage.setItem( globalConstants.sinforToken, String(token));
}

export async function removeToken(token){
  await localStorage.removeItem( globalConstants.sinforToken);
}

export async function clearStorage(){
  await localStorage.removeItem('persist:root');
}

// export function getAppIndex(array) {
//   return (array.map((item)=>item.toString())).join();
// } 
