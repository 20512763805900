import { globalConstants } from '../../redux/constants'

import axios from 'axios';
// const btoa = require('btoa');
require('dotenv').config()

// Constants
export const { 
  REACT_APP_PROXY_USER_URL,
  REACT_APP_PROXY_ELASTIC_URL,
  REACT_APP_PROXY_PORT, 
  REACT_APP_USER_API,
  } = process.env


 
export const api =  axios.create({
  baseURL: REACT_APP_PROXY_USER_URL,
  // httpsAgent: httpsAgent,
  headers: { 
    'Content-Type': 'application/json;charset=utf-8',
    'Accept': 'application/json, text/plain, */*',
  },
  crossdomain: true,
  timeout: 10000 
});



export const authenticatedApi = axios.create({
  baseURL: REACT_APP_PROXY_USER_URL,
  // httpsAgent: httpsAgent,
  headers: { 
    'Content-Type': 'application/json;charset=utf-8',
    'Accept': 'application/json, text/plain, */*',
    // 'Authorization': `token ${getToken()}`,
  },
  crossdomain: true,
  timeout: 10000 
});

export function getToken () {
  return localStorage.getItem(globalConstants.sinforToken);
}

// Seta o token nas requisições antes de armazenar no localStorage
export function setAuthToken(token) {
  authenticatedApi.defaults.headers.common['Authorization'] = '';
  delete authenticatedApi.defaults.headers.common['Authorization'];

  if (token) {
    authenticatedApi.defaults.headers.common['Authorization'] = `token ${token}`;
  }
}

// Seta o token nas requisições após de armazenar no localStorage
authenticatedApi.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `token ${token}`;
  }
  return config;
});



// Header de autenticação da Reactivesearch no Elasticsearch

 export const tokenElastic = '';
 
// Pega o token do usuário e passa no Header
export const headers = {
  'Content-Type':  'application/x-ndjson',
  'Accept': 'application/x-ndjson',
  'x-auth-token': `token ${getToken()}`
} 
   
export const apiElastic = axios.create({
  baseURL: REACT_APP_PROXY_ELASTIC_URL+'/api_elastic',
  headers: headers,
  // params: {
  //   'isValidToken': true,
  // },
  crossdomain: true,
  timeout: 5000 
});


export const apiImg =  axios.create({
  baseURL: REACT_APP_PROXY_USER_URL,
  responseType: 'blob',
  headers: headers,
});

