import { apiElastic } from "../newapi";
import { elasticUrls } from "./elastic.urls";
import { aliases } from "../../config"

export const elasticServices = {
  getFields,
  deleteComments,
  getComments,
  postComment,
  putComments,
  getMoreLikeThis,
  getCarouselList,
  getIndexCount,
  getLastDocument
}


function filterFields(values, index) {
  
  let jsonValues = null
  let prefix = ""
  if (aliases[index]) {
   Object.keys(values.data).map((index) => { 
    jsonValues = values.data[index]['mappings']['properties'] 
    return jsonValues
   })
  } 
  else { 
    jsonValues = values.data[index]['mappings']['properties'];
  }
  
  return getObjectKeys(jsonValues, prefix)
}

function getObjectKeys(obj, prefix = "") {
  const keys = [];
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      let type = obj[prop].type
    
      if (type === "text") {
        const fullKey = prefix ? `${prefix}.${prop}` : prop;
        console.log(fullKey)
        keys.push(fullKey);
      }
      if (typeof obj[prop] === "object") {
          const fullKey = prefix ? `${prefix}.${prop}` : prop;
          // chama a função recursivamente para obter suas chaves
          keys.push(...getObjectKeys(obj[prop].properties, fullKey));
        }
    }
  }
  return keys;
}

function getFields(index){
  return new Promise((resolve, reject)=>{
    apiElastic.get(elasticUrls.getFields(index))
      .then(response => {
        resolve(filterFields(response, index))
      })
      .catch(error=>{
        reject(error);
      })
  });
}

function postComment(data) {
  return apiElastic.post(elasticUrls.postComments, data).then(response => {
    return response;
  });

}

function getComments(id) {
  const body = { "query": { "bool": { "filter": { "term": { "document_id": id } } } }, size: 1000 }
  return apiElastic.post(elasticUrls.getComments, body)
    .then(response => {
      return response.data.hits.hits;
    }).catch(error=>{
      return error;
    })
} 


function deleteComments(id){
  return apiElastic.delete(elasticUrls.deleteComments(id))
  .then(response=>{
    console.log(response)
    return response
  })
  .catch(error=>{
    console.log(error)
    return error 
  });
}


function putComments(id, data){ 
  return apiElastic.put(elasticUrls.putComments(id), data)
  .then(response=>{
    console.log(response)
    return response
  })
  .catch(error=>{
    console.log(error)
    return error 
  });
}
 

// Busca o total de documentos em um índice
async function getIndexCount(index_name) {
  // const body = { "query": { "bool": { "filter": { "term": { "document_id": id } } } }, size: 1000 }
  return await apiElastic.post(`${index_name}/_count`)
    .then(response => {
      // console.log(JSON.stringify(response.data.count))
      return JSON.stringify(response.data.count);
    }).catch(error=>{
      return 0;
    })
} 

// Busca último documento em um índice
async function getLastDocument(index_name) {
  const body = {
    "size": 1,
    "sort": { "@timestamp": "desc"},
    "query": {
       "match_all": {}
    }
 }
  return await apiElastic.post(`${index_name}/_search`, body)
    .then(response => {
      return (response.data.hits.hits[0]._source['@timestamp']);
    }).catch(error=>{
      return 0;
    })
} 



function getMoreLikeThis(id, index) {
  const body = {
    "size": 5,
    "query": {
      "more_like_this": {
        "min_term_freq": 1,
        "max_query_terms": 25,
        "like": [
          {
            "_index": index,
            "_id": id
          }
        ]
      }
    }
  }
  return apiElastic.post(elasticUrls.getByIndex(index), body)
  .then(response => {
      return response.data.hits.hits;
    }).catch(error=>{
      return error;
    })
} 


function getCarouselList(id, index, fieldName) {
  const body = {
    "query": {
      "match_phrase": {
        [fieldName]: id,
      }
    }
  }
  return apiElastic.post(elasticUrls.getByIndex(index), body)
  .then(response => {
      return response.data.hits.hits;
    }).catch(error=>{
      return error;
    })
} 


