import React, { useState, useEffect, useRef } from "react";

import img_loader from '../../assets/loading_spinner.gif'
import { elasticServices } from "../../services";
import { Galleria } from 'primereact/galleria';
// import img from '../../assets/image-regular.png'; 
import { externalResourceImg, descriptionImg } from '../../config'
import { apiImg } from '../../services'
import { Message } from '../../components/message_alert';

  const  GaleriaComponent = ({id, indexName, fieldName, label, file_path, file_name }) => {
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselList, setCarouselList] = useState([]);
  const [urlList, setUrlList] = useState([]);

  const inputEl = useRef(null);
  
 
  const responsiveOptions = [
    {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
    },
    {
        breakpoint: '600px',
        numVisible: 2,
        numScroll: 2
    },
    {
        breakpoint: '480px',
        numVisible: 1,
        numScroll: 1
    }
  ];      


  useEffect(() => {
    setLoading(true);
    elasticServices.getCarouselList(id, indexName, fieldName).then(list => setCarouselList(list));
  }, [id,indexName,fieldName]);
  
 
  function fetchImage(url) {
    return new Promise((resolve, reject) => {
       apiImg.get(url) 
        .then(response => {
          var objectURL = URL.createObjectURL(response.data);
          resolve(objectURL);
        }) 
        .catch(err => reject(err)); 
    });
  }
  
  useEffect(()=>{
    async function setUrls(){ 
      let list = [];
      const generatePath = (image) => {
        let source = image._source;
        let path = `${externalResourceImg[indexName]}`;
        if (source[file_path]) {
            path = path.concat(source[file_path]+'/thumb/')
        }
        if (source[file_name]) {
            path = path.concat(source[file_name])
        }
        return path
      }

        
      function updateUrlList(){
        carouselList.map(async(image, index) => {   
          let imageUrlProxy = generatePath(image)
          await fetchImage(imageUrlProxy)
            .then(objectUrl=>{
              let item = {} 
              item.thumbnailImageSrc = objectUrl
              item.source = image._source
              item.id = image._id
              list.push(item)
            })
            .catch(err =>{ console.log(err) });
        })
      }
      await updateUrlList();
      setTimeout(() => {
        setUrlList(list) 
        setLoading(false) 
      }, 4000);
    }
    setUrls();
  }, [carouselList, file_name, file_path, indexName])
  


  const onButtonClick = async(selectItem, index) => {
    inputEl.current.show(); 
    const generatePath = (image) => {
      let source = image._source;
      let path = `${externalResourceImg[indexName]}`;
      if (source[file_path]) {
          path = path.concat(source[file_path]+'/original/')
      }
      if (source[file_name]) {
          path = path.concat(source[file_name])
      }
     
      return path
    }
    
    let imageUrlProxy = generatePath(await carouselList.find(item=> item._id === selectItem.id))
    setActiveIndex(index);

    fetchImage(imageUrlProxy) 
      .then(objectUrl => {
        selectItem.itemImageSrc = objectUrl
        inputEl.current.show(); 
      }) 
      .catch(err => console.log(err));
   
  };

  
  function List () {
    
    let list = urlList.map((item,index)=>{
      return _item(item, index)
    })
    return (
      <div className="imgcard">
        {list.filter(x=> x !== null).length  ? list : 
          <div style={{ display: 'block', width: '100%' }}>
            <Message text="Não foi possível carregar imagens ou não existe." alert="info" icon="info" />  
          </div>
            }                        
      </div>
    )
  }
  
  
  function _item(item, index){
    let img =  <img src={item.thumbnailImageSrc} alt="Image" aria-hidden="true" style={{ width:"200", cursor: 'pointer' }}
    onClick={() => { onButtonClick(item, index) }} />;
    const { title, subtitle } = descriptionImg[indexName];
    let renderTitle = item.source[title] ? item.source[title]:"Sem título." 
    let renderSubtitle = item.source[subtitle] ? item.source[subtitle]:"Sem descrição." 
    return(
      typeof item.thumbnailImageSrc !== "undefined"?
        < div className="card imgcard-item w3-hover-shadow" key={index}>  
          <div>{img}</div>
          <span className="p-mb-2">
          <b>{renderTitle}</b>
        </span>
        <p className='text-muted'>{renderSubtitle}</p>
        </div>:null) 
  }

  function caption(item) {
    const { title, subtitle } = descriptionImg[indexName];
    let renderTitle = item.source[title] ? item.source[title]:"Sem título." 
    let renderSubtitle = item.source[subtitle] ? item.source[subtitle]:"Sem descrição." 
    return (
      <React.Fragment>
        <h4 className="p-mb-2">
          {renderTitle}
        </h4>
        <p>{renderSubtitle}
        </p>
      </React.Fragment>
    );
  }
  
  const itemTemplate = (item) => {
    // const source = item._source
    return item.itemImageSrc ? 
      <img src={item.itemImageSrc} alt={'Image'} aria-hidden="true" style={{ width: '100%', display: 'block'  }} />: 
      <img src={img_loader} alt='loading' width="80px" />
  }

  return (
    <div>
      {loading ?
        <img src={img_loader} alt='loading' width="80px" />: 
        <div>
          <div className="topic-label">{label}</div>                
          <Galleria 
            ref={inputEl} value={urlList} responsiveOptions={responsiveOptions} numVisible={5} style={{ maxWidth: '850px'}}
            activeIndex={activeIndex}  onItemChange={(e) => onButtonClick(urlList[e.index] , e.index)} circular fullScreen showItemNavigators 
            item={itemTemplate} showThumbnails={false} caption={(e)=>caption(e)}
          />
          <List/>
        </div>}
    </div> 
  );

}


export default (GaleriaComponent);

