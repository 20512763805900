import {  getExternalName } from '../functions';


//formik functions 
export function setInitialValues(alertData, userData){
    const {email} = userData;
    let defaultValues = {
        advanced: false,
        advancedForm:{
            name:'',
            type:'frequency',
            index:'',
            specific:{
                //user Email
                email:[email],

                //frequency
                term:"",
                num_events:2,
                timeframe:2,
      
                //blacklist
                blacklist:["",],

                //new_term
                term_window_size:10,
                term_filter:""
      
            }, 
        },
        standartForm:{
            name:'',
            index:'',
            term:'',
            email,
        },
    }
    
    if  (alertData!==undefined ){
    defaultValues.advanced = alertData.yaml.advanced;
    //id
    defaultValues.advancedForm._id = alertData._id; 
    defaultValues.standartForm._id = alertData._id; 
    //name
    defaultValues.advancedForm.name= getExternalName(alertData.name);    
    defaultValues.standartForm.name= getExternalName(alertData.name);    
    //index
    defaultValues.advancedForm.index= alertData.yaml.index;
    defaultValues.standartForm.index= alertData.yaml.index;

        // avançado
        if (alertData.yaml.advanced){
            defaultValues.advancedForm.type = alertData.yaml.type;
            // Frequência
            if(alertData.yaml.type ==='frequency'){
                defaultValues.advancedForm.specific.term = alertData.yaml.filter[0].query.multi_match.query;
                defaultValues.advancedForm.specific.timeframe = alertData.yaml.timeframe.hours;
                defaultValues.advancedForm.specific.num_events = alertData.yaml.num_events;
            }
            // Lista Negra
            if(alertData.yaml.type ==='blacklist'){
                defaultValues.advancedForm.specific.blacklist = alertData.yaml.blacklist;
            }
            // Novo Termo
            if(alertData.yaml.type ==='new_term'){
                defaultValues.advancedForm.specific.term_filter = alertData.yaml.filter[0]['term']['/.*/'];
                defaultValues.advancedForm.specific.term_window_size =  alertData.yaml.term_window_size.days;
            }

        }
        //standart
        else{
            defaultValues.standartForm.term = alertData.yaml.filter[0].query.multi_match.query;
        }
    }




    return defaultValues;
  }
  
  
export const validate = (values) =>{
    let errors = {}
    // Formulários Avançado
    if (values.advanced){
        //name
        if(values.advancedForm.name === ""){
            errors.advancedForm = {};
            errors.advancedForm.name = 'Campo Requerido';
        }
        //index
        if(values.advancedForm.index === ""){
            if (!errors.advancedForm ) errors.advancedForm = {};
            errors.advancedForm.index = 'Selecione a Base de Dados para Busca';
        }
        //type
        if(values.advancedForm.type === ""){
            if (!errors.advancedForm ) errors.advancedForm = {};
            errors.advancedForm.type = 'Selecione o Tipo de Formulário';
        }

        // Frequência
        if (values.advancedForm.type === 'frequency'){
            if(!values.advancedForm.specific.term){
                if (!errors.advancedForm ) errors.advancedForm = {};
                if (!errors.advancedForm.specific ) errors.advancedForm.specific = {};
                errors.advancedForm.specific.term = 'Digite um termo ou frase';
            }
            if(values.advancedForm.specific.timeframe < 1 || values.advancedForm.specific.timeframe > 12){
                if (!errors.advancedForm ) errors.advancedForm = {};
                if (!errors.advancedForm.specific ) errors.advancedForm.specific = {};
                errors.advancedForm.specific.timeframe = 'Selecione um valor entre 1 e 12';
            }
            if(values.advancedForm.specific.num_events < 1 || values.advancedForm.specific.num_events > 12){
                if (!errors.advancedForm ) errors.advancedForm = {};
                if (!errors.advancedForm.specific ) errors.advancedForm.specific = {};
                errors.advancedForm.specific.num_events = 'Selecione um valor entre 1 e 12';
            }

        }
        // Lista Negra
         if (values.advancedForm.type === 'blacklist'){
            if(values.advancedForm.specific.blacklist.length <= 1){
                if (!errors.advancedForm ) errors.advancedForm = {};
                if (!errors.advancedForm.specific ) errors.advancedForm.specific = {};
                errors.advancedForm.specific.blacklist = 'Adicione ao menos 2 Termos para busca';
            }
        }
        // Novo Termo
        if (values.advancedForm.type === 'new_term'){
            if(values.advancedForm.specific.term_filter===""){
                if (!errors.advancedForm ) errors.advancedForm = {};
                if (!errors.advancedForm.specific ) errors.advancedForm.specific = {};
                errors.advancedForm.specific.term_filter = 'Campo Requerido';
              }
          
              if(values.advancedForm.specific.term_window_size < 1 || values.advancedForm.specific.term_window_size > 100){
                if (!errors.advancedForm ) errors.advancedForm = {};
                if (!errors.advancedForm.specific ) errors.advancedForm.specific = {};
                errors.advancedForm.specific.term_window_size = 'Selecione um valor entre 1 e 100';
              }
          
        }    

    }
    // Formulário Padrão
    else{
        //name
        if(values.standartForm.name === ""){
            errors.standartForm = {};
            errors.standartForm.name = 'Campo Requerido';
        }
        //index
        if(values.standartForm.index === ""){
            if (!errors.standartForm ) errors.standartForm = {};
            errors.standartForm.index = 'Selecione a Base de dados para busca';
        }
        //term
        if(values.standartForm.term === ""){
            if (!errors.standartForm ) errors.standartForm = {};
            errors.standartForm.term = 'Campo Requerido';
        }
        
    }


    return errors

}


/*
export const yupValidate = () =>{
    return yup.object().shape({
        advanced: yup.boolean()
            .required(),
        standartForm: yup.object().shape({
            name: yup.string()
                .when('advanced', {
                    is: true,
                    then: yup.string().required('Campo requerido'),
                    outher: yup.string().notRequired()
                }),

            index:yup.string()
                .when('advanced', {
                    is: true,
                    then: yup.string().required('Selecione uma base de busca...')
                }),
            term: yup.string()
            .when('advanced', {
                is: true,
                then: yup.string().required('Campo requerido')
            }),
        }),
    });
} 
*/
