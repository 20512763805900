import React, { Component } from 'react'

// import { Tabs, Tab } from 'react-bootstrap';
import './styles.css'
// export default props => (

//   <Tabs defaultActiveKey="rel" id="uncontrolled-tab-example">
//     <Tab eventKey="rel" title="Relacionados">
//       {/* <Sonnet /> */}
//       ...props
//     </Tab>
//     <Tab eventKey="rec" title="Recomendados">
//       {/* <Sonnet /> */}
//     </Tab>
//   </Tabs>

// )
export default class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected:this.props.selected || 0
    }  

}
  
  handleChange(index){
    this.setState({selected:index})
  }
 
  render(){
    return (<div className="tab">
      <ul className="inline">
        {this.props.children.map((elem,index)=>{
          let style = index === this.state.selected ? 'selected': '';
         return <li className={style} key={index} onClick={this.handleChange.bind(this,index)}>
           <strong>{(elem.props.title).toUpperCase()}</strong>
           </li>
        })} 
      </ul>
      <div>{this.props.children[this.state.selected]}</div>
      </div>
    )
  }
}



