import React from 'react';
import './tooltip.css'

export default props => (
        <div className="toolt">
            <span><b>?</b></span>
            <span className="tooltiptext">{props.text}</span>
        </div>
    )
  
 
