import React from 'react'
import './styles.css'
import createMarkup from './createMarkup';

export default props => (
    <div className="columnIcon" >
        <p>
            {/* <i className={`fa fa-${props.icon} fa-fw w3-margin-right w3-large w3-text-main`} aria-hidden="true"></i>  */}
        <span className="w3-text-main" style={{marginLeft:20}}><b>{props.label}:</b></span></p> 
        <div className="w3-light-grey w3-round-xlarge w3-small w3-text-info">
        <span dangerouslySetInnerHTML={createMarkup(props.value)} /> 
        </div> 
    </div>
)