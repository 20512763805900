import React  from "react";
import { Modal, Button} from "react-bootstrap";
// import { Button, Modal, ModalHearder, ModalBody } from "react-bootstrap";
// import { utils } from '../../services/utils'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authenticatedDjangoActions } from "../../redux/actions";
import '../styles.css';

//import AlertDismissibleExample from '../../components/alert'

const ManagementTokenModal = ({time, show, close, updateToken})=>{
    let resertTokenButton = (time > 0)?
      <Button 
       variant="success" 
       onClick={async ()=>{
        await updateToken();
        close();
       }}
        >
        OK
      </Button>
        :""
    let minutes = Math.floor(time / 60); 
    let seconds = time % 60; 

    let [ minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('');
    let [ secondLeft, secondRight] = String(seconds).padStart(2, '0').split('');

    let message = (time>0)
      ?'Faltam '+minuteLeft+minuteRight+':'+ secondLeft + secondRight+' para o fim da sessão, Clique em "'
      + 'ok" para continuar navegando .'
      :"Tempo para renovação acabou, faça login novamente.";

    return (
      <Modal
       show={show}
       onHide={close}
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered
        >
        <Modal.Header closeButton className='modal-title'>
          <Modal.Title>Renove sua sessão</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          {/* modal boddy */}
          {message}
        </Modal.Body>
        <Modal.Footer>
          {resertTokenButton}
        </Modal.Footer>
        </Modal>
    );
}

const mapDispatchToProps = (dispatch) =>{
  const { updateToken } = authenticatedDjangoActions;
  return bindActionCreators({ 
    updateToken,
  },dispatch);        
}

export default connect(null, mapDispatchToProps)(ManagementTokenModal);
