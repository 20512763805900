import React from 'react';
import { Link } from 'react-router-dom'

import BasePage from '../homePage/base';

import ReportComponent from './reportComponent';

//import redux
import { connect } from "react-redux";
//import { bindActionCreators } from 'redux';
import { globalConstants } from '../../redux/constants'

const ReportPage = () =>{

    return (
        <BasePage level1={<li className="visited">
        <Link to={globalConstants.baseAppName + "/results"}>
           <span>{'Pesquisas'}</span>
         </Link> 
       </li>}
   level2={<li className="current">
       <b>{'Relatório'}</b></li>}
>
            <ReportComponent />
        </BasePage> 
    );
}

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert,
    };
}

export default connect(mapStateToProps, null)(ReportPage);