import React, { useState } from 'react'
import {Alert } from 'react-bootstrap'

export default function AlertDismissibleExample(props) {
    const [show, setShow] = useState(true);
    
    if (show) {
      return (
        <div aria-live="polite" aria-atomic="true" style={{position: 'absolute', minHeight: 100 }}> 
                <div style={{position: 'fixed ', bottom: 10, right: 10 }}>
        <Alert variant={props.variant} onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{props.title}</Alert.Heading>
          <p>
            {props.message}
          </p>
        </Alert>
      </div>
      </div>
      );
    }
    return '';
  }
  