export const indexesOfFilter = {
	
	'sisbo-personagens,': {
	// ComponenteID-----------Label----------------Field--------
		SingleList_1: ['Cidade do envolvido', 'cidade_endereco.raw'],
		multiList_1: ['Bairro/residência', 'bairro_endereco.raw'],
		dropdownList_1: ['Tipo de Envolvimento', 'envolvimento.raw']
	},
	'sisbo-boletins,': { 
		periodic: ['Registrados até... ', 'data_encerramento'] ,
		SingleList_1: ['Cidade da Ocorrência', 'cidade_ocorrencia.raw'] ,
		multiList_1: ['Bairro da Ocorrência', 'bairro_ocorrencia.raw'],
		multiList_2: ['Natureza', 'naturezas.raw'],
		dropdownList_1: ['Unidade Policial', 'distrito.raw'],
		DateSensor: ['Data do Registro ', 'data_encerramento'] ,
	},
	'sinesp-personagens,': {
	// ComponenteID-----------Label----------------Field--------
		SingleList_1: ['Cidade do envolvido', 'enderecos.no_municipio.keyword'],
		multiList_1: ['Bairro/residência', 'enderecos.ed_bairro.keyword'],
		dropdownList_1: ['Tipo de Envolvimento', 'envolvimento.keyword']
	},
	'sinesp-procedimentos,': { 
		periodic: ['Registrados até... ', 'dt_registro'] ,
		SingleList_1: ['Cidade da Ocorrência', 'no_municipio.raw'] ,
		multiList_1: ['Bairro da Ocorrência', 'ed_bairro.raw'],
		multiList_2: ['Natureza', 'naturezas.no_natureza_ocorrencia.keyword'],
		dropdownList_1: ['Unidade Policial', 'no_estrutura_organizacional.raw'],
		// DateSensor: ['Data do Registro ', 'dt_ocorrencia.raw'] ,
	},
	'sisprocep-procedimentos,': { 
		periodic: ['Instaurados até... ', 'data_instauracao'] ,
		DateSensor: ['Data de Instauração ', 'data_instauracao'] ,
		dropdownList_1: ['Situação', 'situacao.raw'],
		SingleList_1: ['Unidade Policial', 'distrito.raw'],
		multiList_1: ['Natureza', 'naturezas.raw']
	},
	'arquivos-dinte,': { 
		SingleList_1: ['Tipo de arquivo', 'meta.type.keyword'] ,
		
	},
	'arquivos-type,': { 
		SingleList_1: ['Tipo de arquivo', 'file_type.keyword'] ,
		
	},
	'siapen-internos,': { 
		SingleList_1: ['Unidade Prisional', 'Infopen.Unidade_Penal.raw'] ,
		multiList_1: ['Motivo da Saída', 'Infopen.Motivo_Saida.raw'],
		dropdownList_1: ['Regime de Internato', 'Infoproc.Tipo_Regime.raw'],
		DateSensor: ['Data da Entrada ', 'Infopen.Data_Entrada'] ,
	},
	'siapen-visitantes,': { 
		DateSensor: ['Data da Visita ', 'Data_da_Visita'] ,
	},
	'sic-pessoas,': { 
		SingleList_1: ['Cidade/residência', 'cidadeendereco.raw'] ,
		multiList_1: ['Bairro/residência', 'district.raw'],
		dropdownList_1: ['Naturalidade', 'cidadenatal.raw'],
		DateSensor: ['Data do Registro', 'created_at'] ,
	},
	'sicaf-pessoas,': { 
		SingleList_1: ['Estado', 'enderecos.uf.raw'] ,
		multiList_1: ['Cidade', 'enderecos.cidade.raw'],
		multiList_2: ['Bairro/residência', 'enderecos.bairro.raw'],
		dropdownList_1: ['ORCRIM', 'orcrim.raw'],

		// DateSensor: ['Data do Registro', 'created_at'] ,
	},

	'licitacoes-licitacoes,': { 
		// periodic: ['Aberturas até... ', 'DATA_ABERTURA'] ,
		SingleList_1: ['Tipo de Licitação', 'TIPO_LICITACAO.keyword'] ,
		multiList_1: ['Tipo da Obra', 'TIPO_OBRA.keyword'],
		multiList_2: ['Unidade Gestora', 'UNIDADE_GESTORA.keyword'],
		DateSensor: ['Data de Abertura', 'DATA_ABERTURA'] ,
	},

	'contratos-contratos,': { 
		// periodic: ['Aberturas até... ', 'DATA_ABERTURA'] ,
		multiList_1: ['Unidade Gestora', 'UNIDADE_GESTORA.keyword'] ,
		multiList_2: ['Regime de Execução', 'REGIME_EXECUCAO.keyword'] ,
		SingleList_1: ['Tipo de Contrato', 'TIPO_CONTRATO.keyword'],
		dropdownList_1: ['Tipo do Procedimento', 'TIPO_PROCEDIMENTO.keyword'],
		// DateSensor: ['Data de Abertura', 'DATA_ABERTURA'] ,
	},
	'contratos-responsaveis,': { 
		// periodic: ['Aberturas até... ', 'DATA_ABERTURA'] ,
		SingleList_1: ['Cargo', 'CARGO.keyword'],
	},

	'receita-pj,': { 
		SingleList_1: ['Estado', 'uf.keyword'],
		multiList_1: ['Município', 'municipio.keyword'],
		dropdownList_1: ['Situação', 'situacao.keyword'],
	},
	'pmpimobile-atendimentos,': { 
		DateSensor: ['Data de Atendimento ', 'data_criacao_atend'] ,
		SingleList_1: ['Área Policial', 'desc_area_de_despacho.raw'],
		multiList_1: ['Natureza da Ocorrência', 'desc_naturezas.desc_natureza.raw'],
		dropdownList_1: ['Cidade da Ocorrência', 'desc_endereco.cidade.raw'],
	},
	'pmpimobile-envolvidos,': { 
		SingleList_1: ['Situação da Prisão', 'prisao.raw'],
		multiList_1: ['Tipo de Envolvimento', 'tipo_envolvimento.raw'],
		multiList_2: ['Domicílio do Envolvido', 'envolvido_logradouro_municipio.raw'],
	},
}