import React, { Component } from 'react';
import { globalConstants } from '../../redux/constants';

import './folder.css';

class Breadcrumb extends Component {
  render() {
    const { listPath } = this.props;
    return(
      <div className='container breadcrumb-conteiner'>
        {/* <div className='col-sm-1 icon-sidebar'>
            <i className="fas fa-outdent fa-2x "/>
        </div> */}
        <div className='col breadcrumb-component'>
          <nav aria-label="breadcrumb"> 
              <ol className="breadcrumb">          
                {listPath.length===0?
                <li className="breadcrumb-item active" aria-current="page">Meus Arquivos</li>:
                <li className="breadcrumb-item"><a href={`${globalConstants.baseAppName}/folderpage`}>Meus Arquivos</a></li> 
                }
                {listPath.length>0?
                listPath.map((path, index)=>{
                  return index+1!==listPath.length?
                    <li className="breadcrumb-item" key={index}><a href={path.link}>{path.item}</a></li>:
                    <li className="breadcrumb-item active" aria-current="page" key={index}>{path.item}</li>
                }):false}
              </ol>
            </nav>
        </div>
        {/* <div className='row'>
          <div className='col icon-sidebar'>
            <i className="fas fa-outdent fa-2x "/>
          </div>
          
          <nav aria-label="breadcrumb"> 
            <ol className="breadcrumb">          
              {listPath.length===0?
              <li className="breadcrumb-item active" aria-current="page">Meus Arquivos</li>:
              <li className="breadcrumb-item"><a href="/sinfor/folderpage">Meus Arquivos</a></li> 
              }
              {listPath.length>0?
              listPath.map((path, index)=>{
                return index+1!==listPath.length?
                  <li className="breadcrumb-item" key={index}><a href={path.link}>{path.item}</a></li>:
                  <li className="breadcrumb-item active" aria-current="page" key={index}>{path.item}</li>
              }):false}
            </ol>
          </nav>
        </div> */}
      </div>
    );
  }
}

export default Breadcrumb;