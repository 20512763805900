
export const indexesOfList = {
    /** Indices da Receita */
    "receita-pj": {
        "title": "razao_social",
        "subtitle": "nome_fantasia",
        "description": "endereco",
        "date": "",
        "image": "",
        "url": "email"
    },
    
    "receita-pf": {
        "title": "nome_socio",
        "subtitle": "cnpj_cpf_socio",
        "description": "",
        "date": "data_entrada",
        "image": "",
        "url": "email"
    },
     /** Indices da Agespisa */
     "agp-usuarios": {
        "title": "nome",
        "subtitle": "cpf",
        "description": "endereco",
        "date": "",
        "image": "",
        "url": "email"
    },
    /** Indices do SisBO */
    "sisbo-boletins": {
        "title": "numero_bo",
        "subtitle": "distrito",
        "description": "narrativa",
        "date": "data_encerramento",
        "image": "",
        "url": "data_instauracao"
    },
    "sisbo-personagens": {
        "_id": "_id",
        "title": "nome",
        "subtitle": "envolvimento",
        "description": "nome_mae",
        "date": "",
        "image": "",
        "url": "email"
    },
   
    /** Indices do SisProcep */
    "sisprocep-procedimentos": {
        "title": "numero",
        "subtitle": "distrito",
        "description": "tipo_procedimento", 
        "date": "data_instauracao",
        "image": "",
        "url": ""
    },
    "sisprocep-autores": {
        "title": "autor_nome",
        "subtitle": "autor_cpf",
        "description": "autor_mae",
        "date": "",
        "image": "",
        "url": ""
    },
    "sisprocep-vitimas": {
        "title": "vitima_nome",
        "subtitle": "vitima_cpf",
        "description": "vitima_mae",
        "date": "",
        "image": "",
        "url": ""
    },
    
   /** Indices do SIAPEN */
    "siapen-internos": {
        "title": "Nome_Interno",
        "subtitle": "CPF",
        "description": "Mae",
        "date": "",
        "image": "",
        "url": "email"
    },
    "siapen-visitantes": {
        "title": "Nome_Visitante",
        "subtitle": "CPF",
        "description": "Grau_de_Parentesco",
        "date": "Data_da_Visita",
        "image": "",
        "url": "email"
    },
    
    /** Indices do SIC */
    "sic-pessoas": {
        "title": "name",
        "subtitle": "mother",
        "description": "address",
        "date": "created_at",
        "image": "avatar_file_patch", 
        "url": "email"
    },
    /** Indices do SICAF */
    "sicaf-pessoas": {
        "title": "nome",
        "subtitle": "cpf",
        "description": "logradouro",
        "date": "",
        "image": "",
        "url": "email"
    },
    /** Indices dos Arquivos */
    "arquivos-dinte": {
        "title": "file_name",
        "subtitle": "",
        "description": "text",
        "date": "meta.created",
        "image": "",
        "url": ""
    },
    "arquivos-type": {
        "title": "file_name",
        "subtitle": "file_type",
        "description": "file_path",
        "date": "meta.created",
        "image": "",
        "url": ""
    },
    "arquivos-doe": {
        "title": "file_name",
        "subtitle": "file_type",
        "description": "file_path",
        "date": "meta.created",
        "image": "",
        "url": ""
    },
    "licitacoes-licitacoes": {
        "title": "TIPO_OBRA",
        "subtitle": "NUMERO_PROCEDIMENTO",
        "description": "DESCRICAO_OBJETO",
        "date": "",
        "image": "",
        "url": ""
    },
    "licitacoes-responsaveis": {
        "title": "NOME",
        "subtitle": "CPF",
        "description": "EMAIL",
        "date": "",
        "image": "",
        "url": ""
    },
    "licitacoes-publicacao": {
        "title": "MEIO_PUBLICACAO",
        "subtitle": "DATA_PUBLICACAO",
        "description": "",
        "date": "",
        "image": "",
        "url": ""
    },
    "licitacoes-licitantes": {
        "title": "NOME",
        "subtitle": "CPF",
        "description": "",
        "date": "",
        "image": "",
        "url": ""
    },

     /** Indices PM */
     "pmpimobile-atendimentos": {
        "title": "desc_grupo",
        "subtitle": "desc_area_de_despacho",
        "description": "desc_atendimento",
        "date": "data_criacao_atend",
        "image": "",
        "url": ""
    },
     "pmpimobile-envolvidos": {
        "title": "envolvido_nome",
        "subtitle": "tipo_envolvimento",
        "description": "nome_mae",
        "date": "envolvido_data_nascimento",
        "image": "",
        "url": ""
    },
     
    // Índices Contratos
    "contratos-contratos": {
        "title": "CONTRATADA",
        "subtitle": "CPF_CNPJ_CONTRATADA",
        "description": "OBJETO",
        "date": "",
        "image": "",
        "url": ""
    },

    "contratos-responsaveis": {
        "title": "NOME",
        "subtitle": "CARGO",
        "description": "CPF",
        "date": "",
        "image": "",
        "url": ""
    },

    // Indices Sinesp-PPe
    "sinesp-personagens": {
        "_id": "id_pessoa",
        "title": "no_pessoa",
        "subtitle": "envolvimento",
        "description": "no_mae",
        "date": "",
        "image": "",
        "url": "email"
    },
   
    "sinesp-procedimentos": {
        "title": "nr_registro_formatado",
        "subtitle": "no_estrutura_organizacional",
        "description": "tx_relato_historico", 
        "date": "dt_registro",
        "image": "",
        "url": ""
    },
}


export const aliases = {
        'receita-pj':'receita-pj',
        'sisprocep-procedimentos':'sisprocep-procedimentos',
        'sisprocep-autores':'sisprocep-autores',
        'sisprocep-vitimas':'sisprocep-vitimas',
    }