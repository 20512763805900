export const dataFields = {
    "sisbo-boletins":[{"name":"data_encerramento","label":"Data de Encerramento"}],
    "sisbo-boletins_natureza":[{"name":"data_encerramento","label":"Data de Registro"}],
    "sisbo-personagens":[],
    "sisprocep-procedimentos":[{"name":"data_instauracao","label":"Data de Instauração"}],
    "sisprocep-vitimas":[],
    "sisprocep-autores":[],
    "sisprocep-naturezas":[],
    "receita-pf":[],
    "receita-pj":[],
    "agp-usuarios":[],
    "sic-pessoas":[],
    "siapen-internos":[],
    "siapen-visitantes":[],
    "":[],
    }

export function indexesToDashboard(indexes){
    let indexs_ok  = []
    const indexs = ["sisprocep-procedimentos","sisbo-boletins_natureza"]
    const valueInList = (item, list)=>{
        for(let f = 0;f<list.length ;f++){
            if (list[f]===item){
                return true;
            }               
        }   
        return false;     
    }
    for(let i = 0; i <indexs.length; i++ ){
        if(valueInList(indexs[i],indexes)){
            indexs_ok.push(indexs[i])
        }
    }
    return indexs_ok;
}
