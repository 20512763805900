import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { alertActions } from '../../redux/actions';

export class RedirectComponent extends Component{
  constructor(props){
    super(props);
    this.state = {
      time: 10
    }
  }
  
  componentDidMount(){
    this.mytimer = setInterval(() => {
        this.setState(prevState =>({
          time: prevState.time-1
        }))
      }, 1000);
      
  }

  render(){
    if (this.state.time === 0){
      this.props.dispatch(alertActions.clear())
      return <Redirect to={this.props.from}/>    
    }
    return (
    <h7>Redirecionando em {this.state.time} segundos para a pagina de autenticação...</h7>
    );
  }
}