import React from 'react'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import './list_Item_Default.css'
import { globalConstants } from '../redux/constants';

class ItemListRelated extends Component {
    constructor(props) {
        super(props)
        this.state = {
          modal: false,
          modalType: "",
        };
      }
    
  showModal(type, event) {
    event.preventDefault();
    this.setState({ modal: true, modalType: type });
  }
  cancelModal() {
    // fechando modal
    this.setState({
      modal: false
    });
  }

 
    render() {
    const { _id, _index, title, subtitle } = this.props;
    
        return (
               
                    <div className="d-flex flex-column list-accordionMin" > 
                        
                          <Link to={globalConstants.baseAppName +"/reload/"  + _index + ':' + _id}>
                          <span style={{width:'100%'}} >
                              {title}
                          </span>
                          </Link> 
                           <span className='tag-list-accordionMin float-right'> {subtitle}</span>
                   
                    </div>
                                    
              
        );
    }


}

function mapStateToProps(state) {
  const { user_data } = state.user;
  return { user_data };

}


export default connect(mapStateToProps, null)(ItemListRelated);