import React, { Component } from "react";

// import { Link/*, Redirect*/ } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators} from 'redux';
import Logo from "../../assets/sinfor_logo.png";

import { djangoActions, alertActions, localActions } from '../../redux/actions';
import { globalConstants } from '../../redux/constants';

import { Message } from '../../components/message_alert';

import Apresentacao from "../apresentacao/apresentacao";


import '../loginPage/styles.css';

// import { botReact } from '../../../chatBot/botInit';

// modal
require('dotenv').config()


class TwoFactorRecoveryPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userToken: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this); 
    this.props.stopLoading();
  
  }
  
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value });
  }
    

  handleSubmit(event) {
    event.preventDefault();
    const { userToken } = this.state;
    const { warning, twoFactorRecoveryConfirm, loginData } = this.props;
        
    if (!userToken) {
      warning('Preencha o código de autenticação corretamente!');
    }
    else {
      twoFactorRecoveryConfirm({"pre_token":loginData.pre_token ,"totp_code":userToken})
    }
  }
  
  render() {
    const { isLogged, isPreLogged, redirect, lastRoute } = this.props;
    const { alert } = this.props;
    const { userToken } = this.state;

    if (isLogged && !isPreLogged){
      setTimeout(()=>{
        redirect(lastRoute);
      },500);
    }else if(!isLogged && !isPreLogged){
      setTimeout(()=>{
        redirect(`${globalConstants.baseAppName}/login`);
      },500);
    }

    let alertComponent = alert.message ? <Message icon={alert.icon} text={alert.message} alert={alert.type} /> : "";
    return (

      <div className="container-login">
              
            <Apresentacao idHref={'#autenticar'} label={'Autenticar'} />

        <div id="autenticar" className="div-hight">  
          <div className="imgLogoSinfor">
            <img  src={Logo} alt="Sinfor logo" />
          </div>
          <div className="div-hight-center">
            <h2>Informar QR Code</h2>
          
          <form onSubmit={this.handleSubmit} autocomplete="off">
            {alertComponent}
            <Message icon={'info'} text={`Digite o último código recebido no seu email.`} alert={'info'} />
            <p></p>
            <input className='login-input' type="text" name="userToken" value={userToken} placeholder="Código de autenticação" onChange={this.handleChange} />
            <button className='login-button'  type="submit"><span>Enviar código</span></button>
            {/* <button className='login-button'  onClick={()=>{}}><span>Voltar</span></button> */}
          </form>
        </div>
        </div>
      </div>

    );
  }
}
  
function mapStateToProps(state) {
  const { isLogged, loggedOut, isLoading, loginData, isPreLogged } = state.authentication;
  const { alert } = state;
  const { lastRoute } = state.navigation;
  return {
    isLogged,
    isPreLogged,
    loggedOut,
    isLoading,
    alert,
    loginData,
    lastRoute,
  };
}

function mapDispatchToProps(dispatch){
  const { error, warning } = alertActions;
  const { twoFactorRecoveryConfirm, stopLoading } = djangoActions;
  const { clearLastRoute, redirect } = localActions;
  return  bindActionCreators({
    error,
    warning, 
    twoFactorRecoveryConfirm,
    stopLoading, 
    redirect,
    clearLastRoute,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorRecoveryPage);
