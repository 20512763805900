import { djangoConstants } from '../constants';

export function monitoringReducer(state = {}, action) {
  switch (action.type) {
    case djangoConstants.getAlerts:
      return {
        update:true,
        alerts: action.payload.alerts
      };
    case djangoConstants.getAlertsError:
      return {
        update:true,
        alerts: []
      };
    case djangoConstants.setAlert:
      return { 
        update: false,
        alerts:[]
      }
    case djangoConstants.deleteAlert:
      return {
        update: false,
        alerts: [],
      }
    case djangoConstants.editAlert:
      return {
        update: false,
        alerts: [],
      }
    default:
      return state
  }
}