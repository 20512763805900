import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class ToggleMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: true,
            label: " Lista",
            icon: "list"
        };

        //this.listView = this.listView.bind(this);
        this.gridView = this.gridView.bind(this);
        // this.handlemode = this.handlemode.bind(this);
    }

    
    gridView() {    
        var elements = document.getElementsByClassName("column");
        // Pega os elementos com className="column"
        var i;
        
        // Percorre os elementos e redimensiona a largura
        for (i = 0; i < elements.length; i++) {
            if (this.state.mode) {  
                elements[i].style.width = "100%";
                this.props.handlemode(
                    this.setState({ mode: !this.state.mode, label: " Grade", icon: "th-large"  })
                    ); 
                }
            else {
                elements[i].style.width = "50%";
                this.props.handlemode(
                this.setState({ mode: !this.state.mode, label: " Lista", icon: "list" })
            );
            }
        }

    }

    render() {
        let coluna = (this.state.mode ) ?
        <div className="toggle-button" style={{maxHeight: "30px"}} onClick={this.gridView}
        data-toggle="tooltip" data-placement="left" title="Modo lista">
        <FontAwesomeIcon icon={['fas', `${this.state.icon}`]} />
        {this.state.label}
        </div> :
        null
         
        let lista = (!this.state.mode) ?
        <div className="toggle-button" style={{maxHeight: "30px"}} onClick={this.gridView}
        data-toggle="tooltip" data-placement="left" title="Modo grade">
                <FontAwesomeIcon icon={['fas', `${this.state.icon}`]} />
                {this.state.label}
                </div> :
        null
        return (
            <div className="d-flex flex-row-reverse" style={{alignItems:'center'}}>
               
                {coluna}
                {lista}
             
            </div>
        )
    }
}

export default ToggleMode;