import React, { Component } from 'react';
import { BsCheckCircle, BsSearch } from "react-icons/bs";
import './directSearch.css';
import Tooltip from '../../components/tooltip';
import {renderIcon} from '../../components/renderIcon';
import { indexesShortNames, indexesIcons } from '../../config';

class DirectSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // listOpen: false,
            headerTitle: this.props.title,
            selectedColor: ''
        }

    }
    // handleClickOutside() {
    //     this.setState({
    //         listOpen: false
    //     })
    // }
    // toggleList() {
    //     this.setState(prevState => ({
    //         listOpen: !prevState.listOpen
    //     }))
    // }
 
    

// soma os itens selecionados no menu
    static getDerivedStateFromProps(nextProps) {
        let ct = 0;
        nextProps.list.filter(function (a) { 
            const ind = a.indexes;
            ind.filter((s) => {
               if( s.selected ) {
                   ct += 1;
                 }
                 return ct;
            })
            return ct;
        }
        );
        if (ct === 0) {
            return { headerTitle: nextProps.title, selectedColor: '' }
        }
        else if (ct === 1) {
            return { headerTitle: `${ct} ${nextProps.titleHelper} selecionado`, selectedColor: 'selectedColor' }
        }
        else if (ct > 1) {
            return { headerTitle: `${ct} ${nextProps.titleHelper}s selecionados` }
        }
    }
 
    renderItem(item, index) {
        const indexes = item.indexes
        let list = indexes.map((it, id) => {
            return (
            <li key={it.title} className={it.selected?"selected input-group-text":"input-group-text"}
             data-toggle="tooltip" data-placement="left" title={it.title}
             onClick={() => this.props.toggleItem(id, index)}>
            <div className="input-group-item">
            
                {renderIcon[indexesIcons[it.title]]}  &nbsp; 
                {indexesShortNames[it.title]} 
            </div>
            {it.selected && <BsCheckCircle/>}
            </li>
            )
        })
        return list
    }

    render() {
        const { list } = this.props
        const { headerTitle } = this.state
        // let arrow = <FontAwesomeIcon icon="angle-down" size="2x" />
        return (
            <div className="menu-drop"> 
              
                <div className="drop-content">
                  
                    {list.map((item, index) => (
                        
                        <div className="drop-group" key={index}>
                        <span><strong>{(item.type).toUpperCase()}</strong></span>
                            <hr/>
                        {this.renderItem(item, index)}
                        
                        </div>
                    
                        ))}
                        <br />
                </div>
                     <div className="dop-row dd-header-smg">
                        <div className="input-gp">
                            {/* <div className="input-group-prepend"> */}
                            <div className={`dd-header input-group-prepend ${this.state.selectedColor}`} >
                            
                                <div className={`dd-header-title`}
                                // onClick={() => this.toggleList()}
                                >
                                {headerTitle}
                                </div>
                            {/* &nbsp; */}
                            {/* {listOpen ? <BsChevronUp /> : <BsChevronDown/> }  */}
                            </div>
                            <div className="input-group-text " id="btnGroupAddon"
                                    onClick={() => this.props.setApp_index()}>  < BsSearch/>            
                            </div>
                        {/* </div> */}
                        </div>
                        <Tooltip text={<em>Selecione uma ou mais <b > fonte de dados </b > para filtrar seus resultados...</em>} />
                    </div>
               
            </div>
        )
    }
}
 
export default DirectSearch;
