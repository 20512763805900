
export const fieldsToLabels = {
	'sisbo-personagens': {
		"bairro_endereco": "Bairro",
		"cep_endereco": "CEP",
		"cidade_endereco": "Cidade",
		"complemento_endereco": "Complemento",
		"cpf": "CPF",
		"rg": "RG",
		"endereco": "Endereço",
		"envolvimento": "Envolvimento",
		"fone_1": "Fone 1",
		"fone_2": "Fone 2",
		"nome": "Nome do Personagem",
		"nome_mae": "Mãe",
		"nome_pai": "Pai",
		"numero_bo": "Número do BO",
	},
	'sisbo-boletins': {
		"numero_bo": "Boletim Nº",
		"distrito": "Unidade Policial",
		"situacao": "Situação",
		"data_ocorrencia": "Data da Ocorrência",
		"hora_ocorrencia": "Hora",
		"endereco": "Endereço",
		"complemento": "Complemento",
		"bairro_ocorrencia": "Bairro",
		"cidade_ocorrencia": "Cidade",
		"tipo_local": "Tipo local",
		"narrativa": "Narrativa",
		"data_encerramento": "Data do Registro",
		"naturezas": "Natureza(s)",
	},
	'ppe-boletins': {
		"NR_REGISTRO_FORMATADO": "Boletim Nº",
		"NO_ESTRUTURA_ORGANIZACIONAL": "Unidade Policial",
		"SITUACAO_ATUAL": "Situação",
		"SN_SITUACAO_HOMOLOGADO": "Homologado",
		"DT_OCORRENCIA": "Data/Hora da Ocorrência",
		"ENDERECO": "Endereço",
		"ED_CEP": "CEP",
		"DS_PTO_REF_OCORRENCIA": "Pto Referência",
		"ED_BAIRRO": "Bairro",
		"ED_LATITUDE_LONGITUDE": "Pto Geográfico",
		"NO_MUNICIPIO": "Cidade",
		"SG_UF_REGISTRO": "UF",
		"DS_LOCAL_OCORRENCIA": "Tipo local",
		"TX_RELATO_HISTORICO": "Narrativa",
		"DT_PRIMEIRO_REGISTRO": "Data do Primeiro Registro",
		"DATA_REGISTRO": "Data do Registro Atual",
		"NATUREZAS": "Natureza(s)",
	},
	'sisbo-naturezas': {
		"natureza": "Nome",
	},

	'sisprocep-procedimentos': {
		"autoridade": "Autoridade",
		"boletim": "Número do BO",
		"data_instauracao": "Data de Instauração",
		"data_remessa": "Data da Remessa",
		"distrito": "Unidade Policial",
		"modo_instauracao": "Modo de Instauração",
		"tipo_procedimento": "Tipo de Procedimento",
		"numero": "Número",
		"situacao": "Situação",
		"naturezas": "Natureza(s)",
		"0": "Tipo",
	},
	'sisprocep-vitimas': {
		"vitima_alcunha": "Apelido",
		"vitima_cpf": "CPF",
		"vitima_data_nascimento": "Data de Nascimento",
		"vitima_estado_civil": "Estado civil",
		"vitima_mae": "Nome da Mãe",
		"vitima_naturalidade": "Naturalidade",
		"vitima_nome": "Nome",
		"vitima_rg": "RG",
		"vitima_sexo": "Sexo",
	},
	'sisprocep-autores': {
		"autor_alcunha": "Apelido",
		"autor_cpf": "CPF",
		"autor_data_nascimento": "Data de Nascimento",
		"autor_estado_civil": "Estado civil",
		"autor_mae": "Nome da Mãe",
		"autor_naturalidade": "Naturalidade",
		"autor_nome": "Nome",
		"autor_rg": "RG",
		"autor_sexo": "Sexo",
	},
	'sisprocep-naturezas': {
		"natureza": "Nome",
	},

	"receita-pf": {
		"nome_socio": "Nome",
		"tipo_socio": "Tipo de Sócio",
		"cnpj_cpf_socio": "CPF/CNPJ",
		"data_entrada": "Data de Ingresso",
		"cpf_repres": "CPF do Representante",
		"nome_repres": "Nome do Representante",
		"cnpj": "CNPJ",
	},
	"receita-pj": {
		"cnpj": "CNPJ",
		"razao_social": "Razão Social",
		"nome_fantasia": "Nome Fantasia",
		"situacao": "Situação Cadastral",
		"data_situacao": "Data da Situação",
		"nat_juridica": "Natureza Jurídica",
		"atividade_economica": "Atividade Econômica",
		"data_inicio_ativ": "Início das Atividades",
		"endereco": "Endereço",
		"bairro": "Bairro",
		"cep": "CEP",
		"uf": "UF",
		"porte": "Porte",
		"municipio": "Município",
		"opc_mei": "Opção pelo MEI",
		"opc_simples": "Opção pelo Simples",
		"fax": "FAX",
		"cnae_fiscal": "CNAE Fiscal",
		"nome_pais": "País",
		"fone1": "Fone 1",
		"fone2": "Fone 2",
		"email": "Email",
		"data_opc_simples": "Data op. pelo Simples",
		"data_exc_simples": "Data excl. do Simples",
		"capital_social": "Capital Social",
		"matriz_filial": "Matriz/Filial",
	},
	
	"agp-usuarios": {
		"num2": "Matrícula",
		"nome": "Nome",
		"cpf": "CPF",
		"endereco": "Endereço",
	},
	
	"sic-pessoas": {
		"name" : "Nome",
		"cpf" : "CPF",
		"rg" : "RG",
		"mother" : "Mãe",
		"father" : "Pai",
		"birth" : "Nascimento",
		"address" : "Endereço",
		"district" : "Bairro",
		"zip_code" : "CEP",
		"cidadeendereco" : "Cidade",
		"phone1" : "Fone 1",
		"phone2" : "Fone 2",
		"phone3" : "Fone 3",
		"phone4" : "Fone 4",
		"themis_unic_number" : "Processo Themis",
		"created_at" : "Data do registro",
		"updated_at" : "Última atualização",
		"finger_operation_date" : "Data da digital",
		"judgment" : "Julgamento",
		"name_sexo" : "Sexo",
		"namemarital" : "Companheiro(a)",
		"namecor" : "Cor",
		"namenac" : "Nacionalidade",
		"nameocupac" : "Profissão",
		"cidadenatal" : "Naturalidade",
		"description" : "Histórico",
	},
	"sicaf-pessoas": {
		"rg" : "RG",
		"estado_rg" : 'UF',
		"data_nascimento" : "Data de Nascimento",
		"apelido" : "Apelido",
		"naturalidade" : "Naturalidade",
		"registro" : "RG",
		"faccionado" : "Faccionado",
		"assaltante" : "Assaltante",
		"telefone" : "Telefone",
		"outras_informacoes" : "Outras Informações",
		"orcrim" : "Organização Criminosa",
		"status_orcrim" : "Status na Organização",
		"logradouro" : "Logradouro",
		"nome" : "Nome",
		"orgao_expedidor_rg" : "Órgão Expedidor",
		"cidade" : "Cidade",
		"passoa_ativa" : "Ativo",
		"numero" : "Número",
		"confirmado" : "Confirmado",
		"data_confirmado" : "Data da Confirmação",
		"bairro" : "Bairo",
		"sexo" : "Sexo",
		"cep" : "CEP",
		"complemento" : "Complemento",
		"ponto_ref" : "Ponto de Referência",
		"lat" : "Latitude",
		"lng" : "Longitude",
		"cpf" : "CPF",
		"foragido" : "Foragido",
		"regiao" : "Região",
		"estado" : "Estado",
		"uf" : "Estado",
		"tipo_procedimento" : "Tipo de Procedimento",
		"num_procedimento" : "Número do Procedimento",
		"outro" : "Outro",
		"origem" : "Origem",
		"descricao" : "Descrição",
		"data_instauracao" : "Data de Inst. Proc.",
		"data_instauracao_ipl" : "Data de Inst. IPL",
		"num_procedimento_ipl" : "Número do IPL",
		"origem_ipl" : "Origem do IPL",
		"crime_names" : "Crimes Praticados",
},
	"arquivos-dinte": {
		"file_name" : "Nome do arquivo",
		"text" : "Conteúdo",
		"file_content" : "Conteúdo",
		"type" : "Tipo",
		"file_type" : "Tipo",
		"file_path" : "Caminho",
		"Author" : "Autor",
		"created" : "Data de criação",
		"Creation-Date" : "Data de criação",
		"Content-Type" : "Tipo de conteúdo",
		"modified" : "Modificado",
		"Last-Modified" : "Última modificação",
		"Last-Save-Date" : "Salvo",
		"language" : "Linguagem",
		"producer" : "Produção",
		"creator" : "Criado por",
		"date" : "Data",
		"xmpTPg:NPages" : "Nº de páginas",
		"pdf:PDFVersion" : "Versão",
		"File Modified Date" : "Modificado",
		"File Size" : "Tamanho",
		"Image Height" : "Altura",
		"Image Width" : "Largura",

},
	"arquivos-type": {
		"file_name" : "Nome do arquivo",
		"text" : "Conteúdo",
		"file_content" : "Conteúdo",
		"type" : "Tipo",
		"file_type" : "Tipo",
		"file_path" : "Caminho",
		"Author" : "Autor",
		"created" : "Data de criação",
		"Creation-Date" : "Data de criação",
		"Content-Type" : "Tipo de conteúdo",
		"modified" : "Modificado",
		"Last-Modified" : "Última modificação",
		"Last-Save-Date" : "Salvo",
		"language" : "Linguagem",
		"producer" : "Produção",
		"creator" : "Criado por",
		"date" : "Data",
		"xmpTPg:NPages" : "Nº de páginas",
		"pdf:PDFVersion" : "Versão",
		"File Modified Date" : "Modificado",
		"File Size" : "Tamanho",
		"Image Height" : "Altura",
		"Image Width" : "Largura",

},
	"arquivos-doe": {
		"file_name" : "Nome do arquivo",
		"text" : "Conteúdo",
		"file_content" : "Conteúdo",
		"type" : "Tipo",
		"file_type" : "Tipo",
		"file_path" : "Caminho",
		"Author" : "Autor",
		"created" : "Data de criação",
		"meta.created" : "Data de criação",
		"Creation-Date" : "Data de criação",
		"Content-Type" : "Tipo de conteúdo",
		"modified" : "Modificado",
		"Last-Modified" : "Última modificação",
		"Last-Save-Date" : "Salvo",
		"language" : "Linguagem",
		"producer" : "Produção",
		"creator" : "Criado por",
		"date" : "Data",
		"xmpTPg:NPages" : "Nº de páginas",
		"pdf:PDFVersion" : "Versão",
		"File Modified Date" : "Modificado",
		"File Size" : "Tamanho",
		"Image Height" : "Altura",
		"Image Width" : "Largura",

},
	
    "siapen-internos": {
		"Cod_Interno" : "RGI do Interno",
		"Nome_Interno" : "Nome",
		"Outro_Nome" : "Outro nome",
		"Vulgo" : "Vulgo",
		"Data_Nascimento" : "Data de Nascimento",
		"Sexo" : "Sexo",
		"RG" : "RG",
		"CPF" : "CPF", 
		"Titulo_de_Eleitor" : "Título de Eleitor",
		"Estado_Civil" : "Estado civil",
		"Profissao" : "Profissão",
		"Mae" : "Mãe",
		"Pai" : "Pai",
		"Naturalidade" : "Naturalidade",
		"Nacionalidade" : "Nacionalidade",
		"Endereco" : "Endereço",
		"Cidade" : "Cidade",
		"Telefone" : "Telefone",
		"Contato" : "Contato",
		"Infopen": "Dados Prisionais",
		"Infoproc": "Dados Processuais",
	
		"Prontuario" : "Prontuário",
		"Pavilhao" : "Pavilhão",
		"Galeria" : "Galeria",
		"Solario" : "Solário",
		"Cela" : "Cela",
		"Data_Entrada" : "Data da Entrada", 
		"Procedencia" : "Procedência",
		"Condenacao" : "Condenação",
		"Status" : "Status",
		"Unidade_Penal" : "Unidade Penal",
		"Condicao_Interno" : "Condição do Interno",
		"Destino" : "Destino",
		"Isolamento" : "Isolamento",
		"Data_Saida" : "Data da Saida",
		"Motivo_Saida" : "Motivo da Saída",
		"Artigo" : "Artigo",
		"Faccao" : "Facção",
		"Numero_Infopen" : "Numero do Infopen",
		"Sigla" : "Sigla",
	
		"Num_Processo" : "Nº do Processo",
		"Data_Processo" : "Data do Processo",
		"Vara" : "Vara",
		"Data_Condenacao" : "Data da Condenação",
		"Tipo_Regime" : "Tipo de Regime",
		"Processo_VEC" : "Processo na VEC", 
		"Reu" : "Réu",
		"Situacao" : "Situação",
		"Inquerito" : "Inquérito",
		"Data_IP" : "Data do Inquérito",
		"Delegacia" : "Unidade Policial",
		"Data_Prisao" : "Data da Prisão",
		"Mandado" : "Tipo de Prisão",
		"Motivo_Prisao" : "Motivo da Prisão",
		"Tipo_Crime" : "Tipo de Crime",
		"Data_liberacao" : "Data da Liberação",
		"Motivo_liberacao" : "Motivo"
	},
    "siapen-visitantes": {
		"Cod_Interno" : "RGI do Interno",
		"Nome_Visitante" : "Nome",
		"Tipo" : "Tipo",
		"Grau_de_Parentesco" : "Grau de Parentesco",
		"Tipo_de_Visita" : "Tipo de Visita",
		"RG" : "RG",
		"CPF" : "CPF", 
		"Certidao_de_Nascimento" : "Certidao de Nascimento",
		"Sexo" : "Sexo",
		"Pai" : "Pai",
		"Mae" : "Mãe",
		"Data_Nascimento" : "Data de Nascimento",
		"Naturalidade" : "Naturalidade",
		"Nacionalidade" : "Nacionalidade",
		"Endereco" : "Endereço",
		"Cidade" : "Cidade",
		"Telefone" : "Telefone",
		"Contato" : "Contato",
		"Data_Cadastro" : "Data do Cadastro",
		"Data_da_Visita" : "Data da Última Visita"
	},
    "licitacoes-licitacoes":  {
		DATA_CRIACAO: "Criação",
		DATA_ULT_ALTERACAO: "Alteração",
		USUARIO_ULT_ALTERACAO: "Usuário",
		VERSAO: "Versão",
		ANO_PROCEDIMENTO: "Ano do Procedimento",
		ANO_PROCESSO_ADMINISTRATIVO: "Ano do Proc. Adm",
		RP_ANO_ATA: "Ano da Ata",
		RP_DATA_ASSINATURA_ATA: "Assinatura",
		RP_DATA_PUBLICACAO_ATA: "Publicação",
		RP_FIM_VIGENCIA_ATA: "Vigência",
		RP_INICIO_VIGENCIA_ATA: "Início da Vigência",
		RP_NUMERO_ATA: "Nº Ata",
		CARGO_RESPONSAVEL_HOMOLOGACAO: "Cargo do Responsável",
		DATA_ABERTURA: "Abertura",
		DATA_ADJUDICACAO: "Adjudicação",
		DATA_DIVULGACAO: "Divulgação",
		DATA_FINALIZACAO: "Finalização",
		DATA_HOMOLOGACAO: "Homologação",
		DATA_INSCRICAO_FINAL: "Insc. Inicial",
		DATA_INSCRICAO_INICIAL: "Insc. Final",
		DATA_JULGAMENTO: "Julgamento",
		DESCRICAO_OBJETO: "Descr. Objeto",
		FONTE_RECURSO: "Fonte de recurso",
		NOTA_EXPLICATIVA: "Nota explicativa",
		NUMERO_PROCEDIMENTO: "Nº Procedimento",
		NUMERO_PROCESSO_ADMINISTRATIVO: "Nº Proc. Adm",
		NUMERO_PROCESSO_TCE: "Nº Proc. TCE",
		OBSERVACAO: "Observação",
		REGISTRO_PRECO: "Registro de Preço",
		TIPO_LICITACAO_INFORMADO: "Tipo de Licitação",
		VALOR_TOTAL_HOMOLOGADO: "Valor Homologado",
		VALOR_TOTAL_PREVISTO: "Valor Previsto",
		ID_UNIDADE_GESTORA: "",
		UNIDADE_GESTORA: "Unidade gestora",
		ID_MODALIDADE: "",
		MODALIDADE: "Modalidade",
		ID_NATUREZA_OBRA: "",
		NATUREZA_OBRA: "Natureza da Obra",
		ID_PREGOEIRO_LEILOEIRO: "",
		CPF_PREGOEIRO_LEILOEIRO: "CPF Pregoeiro",
		PREGOEIRO_LEILOEIRO: "Nome do Pregoeiro",
		ID_REGIME_EXECUCAO: "",
		REGIME_EXECUCAO: "Regime de Execução",
		ID_RESPONSAVEL_HOMOLOGACAO: "",
		CPF_RESPONSAVEL_HOMOLOGACAO: "CPF Resp. Homologação",
		RESPONSAVEL_HOMOLOGACAO: "Resp. Homologação",
		ID_RESPONSAVEL_INFORMACAO: "",
		CPF_RESPONSAVEL_INFORMACAO: "CPF Resp. Informação",
		RESPONSAVEL_INFORMACAO: "Resp. Informação",
		ID_STATUS_LICITACAO: "",
		STATUS_LICITACAO: "Status",
		ID_TIPO_ESPECIFICACAO_OBJETO: "",
		TIPO_ESPECIFICACAO_OBJETO: "Tipo de Objeto",
		ID_TIPO_FORMA_ADJUDICACAO_OBJETO: "",
		TIPO_FORMA_ADJUDICACAO_OBJETO: "Forma de Adjudicação",
		ID_TIPO_LICITACAO: "",
		TIPO_LICITACAO: "Tipo da Licitação",
		ID_TIPO_OBJETO: "",
		TIPO_OBJETO: "Tipo do Objeto",
		ID_TIPO_OBRA: "",
		TIPO_OBRA: "Tipo da Obra",
		DATA_ULT_ALTERACAO_SISTEMA: "Alteração",
		TIPO_PREGAO: "Tipo de Pregão",
		VALOR_TOTAL_PREVISTO_ATUALIZADO: "Valor atualizado",
		JUSTIFICATIVA_PREGAO_PRESENCIAL: "Justificativa",
		SERVICO_CONTINUO: "Serviço contínuo",
		COVID19: "COVID19"
},
    "licitacoes-responsaveis": {
		ID_LICITACAO: "",
		CARGO: "Cargo",
		NOME: "Nome",
		CPF: "CPF",
		EMAIL: "Email",
		TELEFONE: "Telefone"
},
    "licitacoes-publicacao": {
		ID_LICITACAO: "",
		USUARIO_ULT_ALTERACAO: "Usuário",
		DATA_CRIACAO: "Data de Criação",
		JUSTIFICATIVA: "Justificativa",
		DATA_PUBLICACAO: "Data de Publicação",
		MEIO_PUBLICACAO: "Meio de publicação"
},
    "licitacoes-licitantes": {
		USUARIO_ULT_ALTERACAO: "Usuário",
		DATA_CRIACAO: "Data de Criação",
		IE: "Insc. Estadual",
},
    "licitacoes-itens": {
		DESCRICAOITEM: "Descrição",
		QUANTIDADE: "Quantidade",
		VALOR_UNITARIO: "Valor Unitário",
		NOMELICITANTE: "Licitante",
		COTISTA: "Cotista",
},
    
	"pmpimobile-atendimentos": {
        "data_criacao_atend" : "Data do atendimento",
          "data_encerramento" : "Encerramento",
          "data_fato" : "Data do fato",
          "desc_atendimento" : "Descrição do Atendimento",
          "desc_turno_fato" : "Turno do fato",
          "desc_origem_do_registro" : "Origem do registro",
          "desc_classificacao" : "Classificacão",
          "desc_grupo" : "Grupo",
          "desc_categoria" : "Categoria",
          "desc_area_de_despacho" : "Área de despacho",
          "cidade" : "Cidade",
          "estado" : "Estado",
          "bairro" : "Bairro",
          "cep" : "CEP",
          "desc_endereco" : "Endereço",
          "logradouro" : "Logradouro",
          "latitude" : "Latitude",
          "longitude" : "Longitude",
          "complemento" : "Complemento",
          "desc_encerramento" : "Encerramento",
          "descricao_encerramento" : "Descrição",
          "desc_codigo_encerramento" : "Natureza",
          "data_hora_encerramento" : "Data/Hora do encerramento",
          "desc_area_encerr" : "Área de encerramento",
          "desc_natureza" : "Descrição",
          
		  "desc_veiculos" : "Veículos",
		  "unidade_federativa_veiculo" : "Estado",
		  "categoria_veiculo" : "Categoria",
		  "cor_veiculo" : "Cor",
		  "especie_veiculo" : "Espécie",
		  "marca_veiculo" : "Marca",
		  "tipo_veiculo" : "Tipo",
		  "situacao_veiculo" : "Situação",
		  "renavam_veiculo" : "Ranavam",
		  "placa_veiculo" : "Placa",
		  "placa_novo_modelo_veiculo" : "Plava Mercosul",
		  "chassi_veiculo" : "Chassi",
		  "municipio_veiculo" : "Município",
		  "ano_modelo_veiculo" : "Ano do modelo",
		  "ano_fabricacao_veiculo" : "Ano de fabricação",
          
		  "desc_armas" : "Armas",
		  "especie_arma" : "Espécie",
		  "numero_serie" : "No de Série",
		  "modelo" : "Modelo",
		  "nro_sigma_sinarm" : "SINARM",
		  "observacoes" : "Observações",
		  "situacao_arma" : "Situação",


          "desc_ambiente" : "Ambiente",
          "desc_classificacao_do_ambiente" : "Classificacão do ambiente",
          "desc_atividade" : "Atividade",
          "desc_genero_do_envolvido" : "Gênero do envolvido",
          "desc_tipo_de_prisao_apreensao" : "Tipo de prisão/apreensão",
          "desc_tipo_de_veiculo" : "Tipo de veículo",
          "desc_situacao_veiculo" : "Situacão do veículo",
          "desc_tipo_de_arma" : "Tipo de arma",
          "desc_tipo_de_droga" : "Tipo de droga",
          "desc_tipo_de_acidente" : "Tipo de acidente",
          "desc_naturezas" : "Naturezas",
          "desc_envolvimento" : "Envolvimento"
          
     },
	"pmpimobile-envolvidos": {
		"prisao" : "Tipo de Prisão",
		"violencia_domestica" : "Violência doméstica",
		"tipo_envolvimento" : "Tipo de envolvimento",
		"local_conducao" : "Local",
		"envolvido_nome" : "Nome",
		"alcunha" : "Apelido",
		"nome_mae" : "Nome da mãe",
		"nome_pai" : "Nome do pai",
		"envolvido_idade" : "Idade",
		"envolvido_data_nascimento" : "Data de Nascimento",
		"envolvido_logradouro" : "Logradouro",
		"envolvido_logradouro_bairro" : "Bairro",
		"envolvido_logradouro_municipio" : "Município",
		"cpf" : "CPF",
		"rg" : "RG",
		"fone" : "Telefone",
		"imei" : "IMEI",
	},
	
	"contratos-contratos": {
		DATA_CRIACAO: "Criação",
		DATA_ULT_ALTERACAO: "Alteração",
		USUARIO_ULT_ALTERACAO: "Usuário",
		ANO_CONTRATO: "Ano do Contrato",
		ANO_PROCEDIMENTO: "Ano do Procedimento",
		ANO_PROCESSO_ADMINISTRATIVO: "Ano do PAD",
		ANO_ATA: "Ano da Ata",
		ANO_PROCEDIMENTO_ATA: "Ano Proc. Ata",
		DATA_ASSINATURA_ATA: "Assinatura da Ata",
		DATA_PUBLICACAO_ATA: "Publicação da Ata",
		FIM_VIGENCIA_ATA: "Fim da Vigência da Ata",
		INICIO_VIGENCIA_ATA: "Início da Vigência da Ata",
		NUMERO_ATA: "Número da Ata",
		NUMERO_PROCEDIMENTO_ATA: "Num. Proc. Ata",
		DATA_ASSINATURA: "Assinatura",
		DATA_FINALIZACAO_CADASTRO: "Fim do Cadastro",
		FIM_VIGENCIA: "Fim da Vigência",
		FIM_VIGENCIA_ATUAL: "Fim da Vigência Atual",
		FONTE_RECURSO: "Fonte de Recurso",
		FUNDAMENTACAO_LEGAL: "Fundamentação Legal",
		INICIO_VIGENCIA: "Início da Vigência",
		INICIO_VIGENCIA_ATUAL: "Início da Vigência Atual",
		MODO_PAGAMENTO: "Modo de Pagamento",
		NUMERO_CONTRATO: "Núm. do Contrato",
		NUMERO_PROCEDIMENTO: "Número do Procedimento",
		NUMERO_PROCESSO_ADMINISTRATIVO: "Núm. do PAD",
		NUMERO_PROCESSO_TCE: "Núm do Proc. TCE",
		OBJETO: "Objeto do Contrato",
		OBJETO_DIVISIVEL: "Divisível",
		OBSERVACAO: "Observação",
		VALOR_CONTRATADO: "Valor Contratado",
		CNPJ_ORGAO_GERENCIADOR_ATA: "CNPJ Gerenciador da Ata",
		ORGAO_GERENCIADOR_ATA: "Gerenciador da Ata",
		CPF_CNPJ_CONTRATADA: "CPF/CNPJ Contratada",
		CONTRATADA: "Contratada",
		REGIME_EXECUCAO: "Regime de Execução",
		CPF_RESPONSAVEL_INFORMACAO: "CPF Resp. p/ Informação",
		RESPONSAVEL_INFORMACAO: "Resp. p/ Informação",
		STATUS_CONTRATO: "Status do Contrato",
		TIPO_CONTRATO: "Tipo de Contrato",
		TIPO_PROCEDIMENTO: "Tipo de Procedimento",
		UNIDADE_GESTORA: "Unidade Gestora",
		TIPO_ESPECIFICACAO_OBJETO: "Tipo Especificação do Objeto",
		TIPO_INSTRUMENTO: "Tipo de Instrumento",
		OUTRO_INSTRUMENTO: "Outro Instrumento",
		VIGENCIA_INDETERMINADA: "Vigência Indeterminada",
		JUSTIFICATIVA_VIGENCIA_INDETERMINADA: "Justificativa da Vigência",
		COVID19: "",
		SERVICO_CONTINUO: "Serviço Contínuo",
		ORIGINA_SRP: "Origina SRP",
		PARTICIPACAO_SRP: "Participação SRP",
		CADASTRADO_NO_LW: "Cadastrado no LW"
	},

	"contratos-responsaveis": '',
	"contratos-itens": {
		ID: "",
		DATA_CRIACAO: "",
		DATA_ULT_ALTERACAO: "",
		USUARIO_ULT_ALTERACAO: "",
		VERSAO: "",
		DESCRICAO: "Descrição",
		QUANTIDADE: "Quantidade",
		VALOR_UNITARIO: "Valor Unitário",
		ID_CONTRATO: "",
		ID_LOTE: "Lote",
		NUM_ITEM: "Item",
		ID_TIPO_UNIDADE_MEDIDA: "Unidade de Medida",
		UNIDADE_DURACAO: "",
		DURACAO: "",
		COTISTA: ""
	},

	"sinesp-procedimentos": {
		ds_pto_ref_ocorrencia: 'Ponto de referência',
		no_municipio: 'Município',
		ed_latitude_longitude: 'Geolocalização',
		dt_ocorrencia: 'Data da ocorrência',
		ed_cep: 'CEP',
		endereco: 'Endereço',
		situacao_atual: 'Situação',
		ed_bairro: 'Bairro',
		ds_local_ocorrencia: 'Descrição do local',
		tx_relato_historico: 'Descrição do relato',
		no_estrutura_organizacional: 'Unidade Policial',
		sg_estrutura_organizacional: 'Sigla',
		nr_registro_formatado: 'Número Formatado',
		nr_registro: 'Número',
		nr_adendo: 'Adendo',
		tp_instauracao: 'Modo',
		in_tipo_origem: 'Tipo de origem',
		sg_uf_registro: 'UF',
		sn_situacao_homologado: 'Homologado',
		dt_registro: 'Data do registro',
		ed_complemento: 'Complemento',
		dt_primeiro_registro: 'Data do primeiro registro',
		in_tipo_procedimento: 'Tipo de Procedimento',
		no_natureza_ocorrencia: 'Descrição da Natureza',
		sn_tentativa: 'Modalidade',
	},
	"sinesp-personagens": {
		no_pessoa: "Nome do Personagem",
		no_alcunha: "Apelido",
		envolvimento: "Envolvimento",
		in_sexo: "Sexo",
		in_orientacao_sexual: "Oreintação sexual",
		in_estado_civil: "Estado civil",
		no_mae: "Nome da mãe",
		no_pai: "Nome do pai",
		in_raca_cor: "Cor da pele",
		in_escolaridade: "Grau de Instrução",
		dt_nascimento: "Data de Nascimento",
		nr_idade: "Idade",
		no_profissao: "Profissão",
		co_cpf: "CPF",
		nacionalidade: "Nacionalidade",
		naturalidade: "Naturalidade",
		no_municipio: "Município",
		logradouro: "Endereço",
		ed_cep: "CEP",
		ed_bairro: "Bairro",
		no_pais: "Pais",
		ed_latitude_longitude: "Geolocalização",
		ds_faccao_criminosa: "Organização Criminosa",
		no_tipo_documento: "Tipo de Documento",
		no_documento: "Número do Documento",
		id_uf: "UF",
		dt_validade: "Data de Validade",
		dt_expedicao: "Data de Expedição",
		in_contato: "Tipo de contato",
		nr_telefone: "Número",
		no_contato: "Nome do Contato",
		ds_rede_social: "Rede Social",
		sn_whatsapp: "Whatsapp",
		ed_email: "Email",

	}

}


