import React from 'react';

import "./alert.css";
import { indexesShortNames } from '../../config';

import { TableButton } from '../../components/button'

//table
import {Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';


const AlertItem = ({count, title, term, type, index, onClick, deleteItem, id}) => {
    return (
        <Tr>
            <Td>{count}</Td>
            <Td>{title}</Td>
            <Td>{term}</Td>
            <Td>{type}</Td>
            <Td>{indexesShortNames[index]}</Td>
            <Td>    
                <TableButton className="btn" background="transparent" color="#007bff" label='Editar' icon={'edit'} onClick={onClick} />
            </Td>
            <Td>
                <TableButton className="btn" background="transparent" color={"#dc3545"} label='Deletar' icon={'trash'} onClick={()=>deleteItem(title,id)} />
            </Td>
        </Tr>
    ); 
}
        
export default AlertItem;