import React from 'react';
import { connect } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner';

let Loading = ({ loading, message }) => (
loading ?
<div style={{  textAlign: 'center' }}>
<ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="6" fill="#EEEEEE" animationDuration=".5s"/>
<h6><em>{message}</em></h6>
</div> : null
);

const mapStateToProps=(state) => ({loading: state.authentication})

Loading = connect(mapStateToProps,null)(Loading)
export default Loading;