import React, { Component } from "react";
import { Link } from "react-router-dom";

import Apresentacao from "../apresentacao/apresentacao";
import { Message } from '../../components/message_alert';

import { connect } from 'react-redux';
import { bindActionCreators} from 'redux';
import Logo from "../../assets/sinfor_logo.png";

import { globalConstants } from '../../redux/constants'
import { djangoActions } from '../../redux/actions';

import '../loginPage/styles.css';
class ActivateEmail extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.activateUser(this.props.match.params);
  }

  render() {
    const { alert } = this.props;
    let alertComponent = alert.message ? <Message icon={alert.icon} text={alert.message} alert={alert.type} /> : ""
    return (
      <div className="container-login">
                        
          <Apresentacao idHref={'#ativar'} label={'Ativar Email'} />


        <div id="ativar" className="div-hight">
          <div className="imgLogoSinfor">
            <img  src={Logo} alt="Sinfor logo" />
          </div>
          <div className="div-hight-center">
            <h2>Ativação de Email</h2>
          <h6>Clique no botão "confirmar", para ativar sua conta.</h6>
          {alertComponent} 
          {alert.type === 'success' ? 
          <div className="activate-success"><a href="https://wa.me/8695592581/?text=Ativação%20de%20usuário" target="_blank" rel='noopener noreferrer'> Enviar mensagem pelo WhatsApp</a>
          <br/>ou envie um email para: <b>gsi@pc.pi.gov.br</b>
          </div>
          : ''}
          
          <form onSubmit={this.handleSubmit}>

          <button className='login-button' type="submit"><span>Confirmar</span></button>
          <hr />
              <div> Já é registrado? <Link to={globalConstants.baseAppName + "/login"}>Fazer Login</Link></div>

          </form>
          </div>
        </div>
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  const { activateUser } = djangoActions;
  
  return bindActionCreators({
    activateUser
  },dispatch);
}


function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert

  };
}



export default connect(mapStateToProps, mapDispatchToProps)(ActivateEmail);
