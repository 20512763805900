// import { globalConstants } from '../redux/constants/global.constants';
import { globalConstants } from '../redux/constants';

export const isAuthenticated = () => localStorage.getItem(globalConstants.sinforToken) !== null;

export const getToken = () => localStorage.getItem(globalConstants.sinforToken);


export const clearStorage = () => {
  localStorage.clear();
};

export const getTokenItem = () => 
  localStorage.getItem(globalConstants.sinforToken);
