import React from 'react'
import { Component } from 'react'
import { Link } from 'react-router-dom'
//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//import da função p/ deletar os dados no banco
import { localActions } from '../redux/actions';


import { Tbody, Tr, Td } from 'react-super-responsive-table';
import { Card } from 'react-bootstrap';

import './list_Item_Default.css'
import { globalConstants } from '../redux/constants';
import { BsBoxArrowInUpRight } from "react-icons/bs";
import { IconContext } from "react-icons";
import { indexesFullNames, fieldsToLabels } from '../config'
import { getFieldName } from '../services/index';

import SlidingModal from '../system_pages/resultsPage/SlidingModal';
import { indexesShortNames } from '../config';

import DetailsItem from '../system_pages/detailsPage/detailsItem';
import createMarkup from './createMarkup';

class ItemListDefault extends Component {
    constructor(props) {
      super(props);
        this.state = {
          entity: [],
          isOpen: false,
          update:false,
          id : "",
          clicked : false
        };

      }
    
  async closeDetail_(isOpen= true){
    await this.props.deleteDetailsModelId();
    this.alterUpdate();
  }

  closeDetail(isOpen = true) {
    // fechando modal
    this.setState({
      id: "",
      isOpen: !isOpen
    });
  }

  openDetail(idCur, allfields, isOpen = false) {
    this.setState({ 
      id: idCur,
      entity: allfields, 
      isOpen: !isOpen,
     });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = this.props.modelDetailsId !== nextProps.modelDetailsId;
    shouldUpdate = shouldUpdate || this.state.update !== nextState.update
    shouldUpdate = shouldUpdate || this.state.isOpen !== nextState.isOpen;
    // shouldUpdate = shouldUpdate || this.state.id !== nextState.id;
    return shouldUpdate;
  }

  componentWillUpdate(){
    const { _id, allfields, modelDetailsId } = this.props;
    if (modelDetailsId===_id){
      this.openDetail( _id, allfields );
    }else{
      this.closeDetail();
    }
  }

  alterUpdate(){
    this.setState({
      update:!this.state.update
    });
  }

  async getItem(event) {
    event.preventDefault();   
    const id = this.state.id;
    const currentId = this.props._id;
    if ( id === currentId ) {
      await this.props.deleteDetailsModelId();
    }
    else {
      await this.props.setDetailsModelId(currentId);
    }
    this.alterUpdate();
  }
  imageClick = () => {
    console.log('Click!!!!');
    this.setState({
      clicked: true
    })
  }   

  render() {
    const { _id, _index, title, description, date, subtitle, highlight } = this.props;
    const { labelTitle, labelDate, labelSubtitle, labelDescription } = this.props;
    const entityFields = this.state.entity;
    let valor_index = _index.split('_')[0]
    const modalInterior = (
      <DetailsItem valor_index={valor_index} entityFields={entityFields} />
    );
    const isOpen = this.state.isOpen;
    const renderHighlight = (highlight) => {
      let fields = []
      for (var [key, value] of Object.entries(highlight)){   
        console.log()
        let vl =  (value.length > 60) ? (value.substring(0, 60)+'...') : value 
        fields.push(<div key={key} className="d-flex flex-row">
          <small className="d-flex flex-row"> <span>Encontrado</span>&nbsp;
          <b><div className='description' dangerouslySetInnerHTML={ 
            createMarkup(vl[0])}> 
          </div></b>
              <span>
              &nbsp; em <em>'{getFieldName(fieldsToLabels[valor_index], key)}'</em>
              </span>
          </small>
          </div>
        )
      }
      return fields
    }
    return (
            <Card className="result-item " >
                        {/* <div className="d-flex flex-row item-header lineHeight" > 
                          {(image !== 'null') && image !== 'undefined'?
                            // <img className="avatar" src={image} alt="Imagem" /> 
                            <div>
                            <img src={ image } onClick={this.imageClick} alt="Imagem"/>
                          </div>
                        : ""}
                         </div> */}

                         <Tbody>
                          <Tr>
                            <Td>
                        <div className="d-flex title-list">
                        <strong>{(indexesShortNames[valor_index])?(indexesShortNames[valor_index]).toUpperCase():"Indefinido"}</strong>
                        </div>

                            </Td>
                          </Tr>
                          <Tr >
                              <Td colSpan='3' className="origin-list">{labelTitle}</Td>
                              <Td className="d-flex flex-row item-header ">
                              <Link to={globalConstants.baseAppName +"/reload/"  + _index + ':' + _id}>
                              <span dangerouslySetInnerHTML={createMarkup(title)}></span> 
                              </Link> 
                          
                        <div className="item-header">
                        <SlidingModal isOpen={isOpen} toggleOpen={this.getItem.bind(this)} 
                        closeDetail={this.closeDetail_.bind(this)} 
                        // closeDetail = {async function ()=>   await this.props.deleteDetailsModelId();}
                        body={modalInterior} />

                        <IconContext.Provider value={{ className: "badge-class-icons" }}>
                          
                          <div className="badge badge-info"  data-toggle="tooltip" data-placement="left" title="Abrir nova janela">
                          <a className={'link'} href={globalConstants.baseAppName +"/details/"  + _index + ':' + _id} target="_blank" rel="noopener noreferrer">
                            <BsBoxArrowInUpRight/><span style={{verticalAlign:'middle', padding:5, fontSize:10}}>Nova janela</span>
                            </a>
                            </div>
                        </IconContext.Provider>
                        </div>
                        </Td>
                          </Tr>
                          <Tr className=''>
                              <Td colSpan='3' className="origin-list flex-row item-header">{labelSubtitle}</Td>
                              <Td className="d-flex flex-row item-header ">
                              <span> <b dangerouslySetInnerHTML={createMarkup(subtitle)}/></span>
                              </Td>
                          </Tr>
                          <Tr className=''>
                              <Td colSpan='3' className="origin-list">{labelDate}</Td>
                             <Td className="d-flex flex-row item-header ">
                             <span className='data' dangerouslySetInnerHTML={createMarkup(date)} />
                             </Td>
                          </Tr>
                          <Tr className=''>
                              <Td colSpan='3' className="origin-list">{labelDescription}</Td>
                              <Td className="d-flex flex-row item-header ">
                              <div className='description' dangerouslySetInnerHTML={ createMarkup(description && (description.length > 255) ? description.substring(0, 255)+'...' : description )}></div>
                              </Td>
                          </Tr>
                          <Tr className=''>
                              <Td colSpan='3' className="origin-list">{"Fonte"}</Td>
                              <Td className="d-flex flex-row item-header origin-list">
                              <em> { `${indexesFullNames[_index.substring(0, _index.indexOf("-"))] }`}</em>
                              </Td>
                          </Tr>
                          <Tr className=''>
                              <Td colSpan='3' className="origin-list"></Td>
                              <Td className="d-flex flex-row item-header " style={{color:"#666"}}>
                              {highlight ? renderHighlight(highlight) : ''}
                              </Td>
                          </Tr>
                      </Tbody>
                        
                                        
                </Card>

        );
    }


}

function mapStateToProps(state) {
  const { user_data } = state.user;
  const { modelDetailsId } = state.searched;

  return { user_data, modelDetailsId };

}
function mapDispatchToProps(dispatch) {
    const { setDetailsModelId, deleteDetailsModelId } = localActions;
    return bindActionCreators({
      setDetailsModelId, deleteDetailsModelId
    }, dispatch)
  }



export default connect(mapStateToProps, mapDispatchToProps)(ItemListDefault);