import React, { Component } from 'react';
// import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap'
import './styles.css'
// import Logo from '../../../assets/sinforlogo.png'
// Ações do usuário
// import { userActions } from '../../../redux/actions';
// import Icon from '../../../assets/alarm.png';
// Funções do redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Img from '../../../assets/people.png'
import { IconContext } from "react-icons";
import ContentHeaderItem from './contentHeaderItem';
import { globalConstants } from '../../../redux/constants';
import { authenticatedDjangoActions } from '../../../redux/actions';
import { Divider } from '@material-ui/core';
import { BsBoxArrowRight } from "react-icons/bs";


class Header extends Component {    
    
   
getCss(start) {
    let params = start;
    const { color, shadow, border, staticL} = this.props;
    if (color){
        params += ' color';
    }
    if (shadow) {
        params += ' shadow';
    }
    if (border) {
        params += ' border';
    }
    if (staticL){
      params += ' static';
    }
    return params;
}
    render() {
        const { user_data, logout } = this.props;
        const divCss = this.getCss("divContent");
        const navCss = this.getCss("navbar");
        let count;
        try{
            count = this.props.reportList.length;
        }catch {
            count = 0; 
        }
        
        
        return (
            <div className={divCss}>
           
                <nav className={navCss} >
                                 
            {/* <IconContext.Provider value={{ className: "global-class-icons" }}> 
                
                <button className="navbar-toggler link-toggle" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <i className='fas fa-align-justify navbar-itens' />
                </button>
            </IconContext.Provider> */}
                    
                    {/* Adicionar elementos */}
                    {this.props.children}
                    {/* button toggle*/}
            <nav className='dp-menu' >

                <ul>
                    <li data-toggle="tooltip" data-placement="left" title="Abrir Relátorio">
                            <IconContext.Provider value={{ className: "global-class-icons" }}> 
                                <ContentHeaderItem   
                                    path={`${globalConstants.baseAppName +'/report'}`} 
                                    icon='report' count={count}/>
                            </IconContext.Provider>
                    </li>
                    <li>
                    <IconContext.Provider value={{ className: "global-class-icons" }}> 
                    <ContentHeaderItem icon='cog' />
                    </IconContext.Provider>
                        <ul>
                            <li>
                                <ContentHeaderItem  
                                         path={`${globalConstants.baseAppName +'/alertpage'}`} 
                                         icon='alerts' />
                            </li>
                            <li><Divider/></li> 
                            <li>
                            {/** rendereização condicional do link para admin page*/}
                            { true ?
                                    <ContentHeaderItem 
                                        path={`${globalConstants.baseAppName +'/admin'}`} 
                                    icon='admin' />:
                                    null}    
                            </li>
                            
                        </ul>
                    </li>
                    <li>
                    <div className="dop-row">
                        <div className="dd-header dd-header-logout">
                            <img src={Img} style={{height: "25px", marginTop:-2.5}} className="" alt="Avatar"/>                           <span>&nbsp;{user_data.username}</span>
                        </div>
                        <li title="Logout" className="dd-header input-group-text-logout" onClick={() => logout()}>        
                                    <IconContext.Provider value={{ className: "logout-class-icons menu-icon-sair" }}> 
                                    <BsBoxArrowRight />
                                    </IconContext.Provider>
                        </li>
                    </div>
                    </li>
                </ul>
                </nav>
                </nav>
            </div>
        );
    }

}


function mapStateToProps(state) {
    const { user_data } = state.user;
    const { reportList } = state.report;
    const { isOpen } = state.searched;
    return { user_data, reportList, isOpen };

}

function mapDispatchToProps(dispatch) {
    const { logout } = authenticatedDjangoActions;
    return bindActionCreators({
        logout
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
