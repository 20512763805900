import { djangoConstants } from '../constants';

export function registrationReducer(state = { registering: false, errors:{},user:{}}, action) {
  switch (action.type) {
    case djangoConstants.registerSucess:
      return { registering: false };
    case djangoConstants.registerRequest:
      return { registering: true };
    case djangoConstants.registerFail:
      return {registering: false, errors: action.errors, user:action.user };
    default:
      return state 
  }
}