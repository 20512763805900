import React, { Component } from 'react';

//componentes
import ContentHeader from '../../common/template/contentHeader';
import ContentBody from '../../common/template/contentBody';
import Logo from '../../assets/sinfor_logo.png';
import { ScrollTop } from 'primereact/scrolltop';

//biblioteca Reactivesearch
import { DataSearch } from '@appbaseio/reactivesearch';

import './styles.css';

// Ações do usuário
// import { userActions } from '../../redux/actions';

// Funções do redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AlertDismissibleExample from '../../components/alert'
import { elasticActions } from '../../redux/actions';

import { ExcludeFields } from '../../config';
import CountDocuments from './countDocuments';

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.customQuery = this.customQuery.bind(this);

    }


    handleUserInput(value) {
        this.setState({ searchTerm: value });
    }
 
  
    componentDidMount() {
        const { listIndex, setFields } = this.props
        setFields(listIndex);
    }

    customQuery=function() {
        return {
          "timeout": "1s",
          "query": {
            "common": {
                "body": {
                    "query": this.state.searchTerm,
                    "cutoff_frequency": 0.001,
                    "minimum_should_match": 2,
                    "low_freq_operator": "and"
                }
              }
          },
          "size": 100
        }
      }

    infoIndices = () => this?.props?.elasticindex?.length > 0 ?  <CountDocuments elasticindex={this.props.elasticindex} /> : ''
    render() {
        const { 
            alert,
            searchMessage, 
            fields,
        } = this.props;
        
        let alertComponent = <AlertDismissibleExample variant={alert.type} title={alert.title} message={alert.message}/>
        return (

            <div className='content-hight'>
                <ContentHeader border={false} color={false} shadow={false}>
                 <div></div>
                </ContentHeader>
                    <img className="imgLogoHome padingLogoHome" src={Logo} alt="Sinfor logo" />
                <ContentBody>
                <div aria-live="polite" aria-atomic="true" style={{position: 'relative', minHeight: 100 }}> 
                <div style={{position: 'absolute', top: 0, right: 0 }}>
                    {alert.type ? alertComponent : ''}
                </div>
                </div>
                     
                    <div className="searchBarHome">
                        <DataSearch
                            componentId="SearchResults"
                            filterLabel="Pesquisa"
                            dataField={fields}
                            // nestedField={['*.*']}
                            size={9}
                            placeholder="Pesquise aqui..."
                            autosuggest={true}
                            highlight={true}
                            queryFormat="and"
                            customQuery={this.customQuery}
                            value={this.state.searchTerm}
                            onChange={(event) => this.handleUserInput(event)}
                            onValueSelected={(value) => searchMessage(value)}
                            excludeFields={ExcludeFields}
                            loader="Carregando..."
                            iconPosition="right"
                            URLParams
                            className=""
                            innerClass={{
                                input: 'search-input',
                            }}
                        />
                    </div>
                </ContentBody>
               {this.infoIndices()}
               <ScrollTop className="custom-scrolltop" icon="pi pi-arrow-up" />

            </div>


        );
    }
}


function mapStateToProps(state) {
    const { fields } = state.fields;
    const { alert } = state;
    const { searched } = state;
    const { listIndex } = state.indexed;
    const { elasticindex } = state.user.user_data.node_unidade;
    return { alert, fields, searched, listIndex, elasticindex };

}

function mapDispatchToProps(dispatch) {
    const { setFields, searchMessage } = elasticActions;
    return bindActionCreators({
        setFields,
        searchMessage,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);