import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import './styles.css'
import { globalConstants } from '../../redux/constants'

import { apiElastic } from '../../services';
import { connect } from 'react-redux';
import BasePage from '../homePage/base';
import GenericDetails from './genericDetails'
import { indexesFullNames } from '../../config'
import Loading from '../../common/template/loading';

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: [],
            entityList: [],
            valor_index: '',
            valor_id: 0
        }

    }

    async UNSAFE_componentWillMount() {
        // pegar id da rota privada
        const { id } = this.props.match.params;
        // separando id do index
        const valor_id = id.substring(id.lastIndexOf(':') + 1);
        const valor_index = id.substring(0, id.lastIndexOf(':'));
               
        // Pega detalhes no elasticsearch mudar para api
        const response = await apiElastic.get(`/${valor_index}/_search?q=_id:${valor_id}`)
        this.setState({ 
            valor_id: valor_id, 
            valor_index: valor_index.split('_')[0],
            entity: response.data.hits.hits[0]
         });
    }

    render() {
        const valor_index= this.state.valor_index;
        const entity = this.state.entity;
        return (
            <BasePage
            level1={<li className="visited">
                 <Link to={globalConstants.baseAppName + "/results"}>
                    <span>{'Pesquisas'}</span>
                  </Link> 
                </li>}
            level2={<li className="current">
                <b>{`${indexesFullNames[valor_index.substring(0, valor_index.indexOf("-"))] }`}</b></li>}
        >
                {/* Passa a entidade a ser renderizada */}
                {entity && valor_index ? <GenericDetails entity={entity} valor_index={valor_index} /> : <Loading message="Carregando..." /> }

            </BasePage>
        );
    }
}


export default connect(null, null)(Details)