import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Comment from "./comment";
import img from '../../assets/loading_spinner.gif'
import { elasticActions } from "../../redux/actions";

const  CommentList = ({username, id, comments, update, getComments,toggleUpdate}) => {
  const [loading, setLoading] = useState(false);
  // const [update, setUpdate] = useState(props.update);
  const [commentsList, setComments] = useState([]);
  
    
  // const loadComments = useCallback( async () => {
    //   // const list = await userService.getComments(props.id);
    //   await props.getComments(props.id)
    //   setComments(props.comments)
    //   setLoading(false);
    // }, [])
    
  useEffect(() => {
      setLoading(true);
      setTimeout(() => getComments(id), 2000)
      // setComments(comments)
      
    // console.log(JSON.stringify(comments))
  }, [id,update,getComments]);
    
  useEffect(() => {
    // loadComments();
    // setUpdate()
    setComments(comments)
    setLoading(false);
    }, [/*update,*/comments]);
    
  return (
    <div className="commentList"><br/>
      <p className="text-muted mb-4">
        <span className="badge badge-success">{commentsList.length}</span>{" "}
        {commentsList.length > 1 ? "Colaborações" : "Colaboração"}
      </p>

     {/*commentsList.length === 0 && */loading ?
      <img src={img} alt='loading' width="80px" />
      :
      commentsList.length === 0 && !loading ?
      <div className="alert text-center alert-info">
        Seja o primeiro a colaborar
      </div>
      :
      commentsList && commentsList.map((comment, index) => (
        <Comment key={index} id={comment._id}comment={comment._source} toggleUpdate={toggleUpdate} />
      )) 
    }
        
    </div> 
  );
}


function mapStateToProps(state) {
  const { comments } = state;
  return {
    comments 
  };
}

function mapDispatchToProps(dispatch) {
  const { getComments } = elasticActions;
  return bindActionCreators({
    getComments
  }, dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(CommentList);

