
import React from 'react';
import './footer.css'
// import LogoGoverno from "../assets/logo-governo-do-piaui.svg";

var d = new Date();
var year = d.getFullYear();
export const Footer = () => (
    <div className="footer"> 
        {/* <img className="" src={LogoGoverno} alt="Sinfor logo" style={{height:60}} /> */}
        DINTE SSP/PI
        &copy; 2020 - {year} 
    </div>
);