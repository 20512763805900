import React, { useState, useEffect } from 'react'
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { connect } from 'react-redux'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { REACT_APP_USER_API, ServicesClass } from '../../services';
import { fieldsToTablesDownload, fieldsToLabels } from '../../config';
import { getFieldName,  } from '../../services';
import './dataTable.css';

const fieldToDownload = 'Download/Abrir';



const DownloadTableComponent = ({ ind, param, valor, label }) =>{
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  

  const  getDownloadButton = (address) => {
    return <Button className="btn save-btn" onClick={()=> onClickButton(`${REACT_APP_USER_API}${address}`)} >
      <i className="pi pi-download"></i>
    </Button>;
  }

  const onClickButton = (address) =>{
    window.open(address)
  }


  const renderHeader = () => {
    return (
        <div className="table-header">
            <h6>{label}</h6>
            <span className="p-input-icon-left">
                <i className="pi pi-search" ></i>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Filtar" />
            </span>
        </div>
    );
  }
  const header = renderHeader();
  
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDataTable = ((data) => {
    const { schema, datarows } = data;
    
    const dataCols = schema.map((col,i) => {
        let key, value;
        
        const cols = <Column key={i} 
            style={{ width: 'max-content'}}
            field={col.name} 
            header = {col.name==='file_path'? fieldToDownload :getFieldName(fieldsToLabels[ind], col.name)} sortable
            
            body={(rowData) => {
                key = Object.keys(rowData)
                value = Object.values(rowData)
                if (col.name === 'file_path'){
                  return(
                    <>
                      {getDownloadButton(value[i])}
                    </>
                  )                    
                }
                return (
                    <React.Fragment>
                        <span className="p-column-title">{getFieldName(fieldsToLabels[ind], key[i])}</span>
                        {value[i]}
                    </React.Fragment>
                );
            }} 
     />;
     return cols
    });
    setCols(dataCols)

    const dataRows = datarows.map((item) => {
      let obj = {}
      item.forEach((it, index) => {
        obj[schema[index].name] = it
      })
      return obj
    })
    setRows(dataRows)
})

  useEffect(() => {
    const services = new ServicesClass();
    services.getWithSQL(ind, param, valor, fieldsToTablesDownload[ind])
    .then(data => getDataTable(data));
  }, [getDataTable, ind, param, valor]);

  return (
    <div className="w3-third2 datatable-responsive">
      <DataTable value={rows} header={header} className="p-datatable-responsive" dataKey="id" rowHover globalFilter={globalFilter}
        paginator rows={5} emptyMessage="Não há itens" currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} itens"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5,10,50]}
        >
        {cols}
      </DataTable>
    </div>
  )
}


export default connect()(DownloadTableComponent);