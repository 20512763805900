const theme = {
	typography: {
		fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
		fontSize: '12px',
		color: '#005571',
	},
	colors: {
		titleColor: '--colorText',
		primaryColor: '#005571',
	},
	// secondaryColor: 'gray',
};

export default theme;