import { alertConstants } from '../constants';

export function alertReducer(state = {}, action) {
  switch (action.type) {
    case alertConstants.success:
      return {
        type: 'success',
        title: 'Ok!',
        message: action.message,
        icon: 'check'
      };
    case alertConstants.warning:
      return {
        type: 'warning',
        title: 'Atenção!',
        message: action.message,
        icon: 'exclamation-triangle'
      };
    case alertConstants.error:
      return {
        type: 'danger',
        title: 'Erro!',
        message: action.message,
        icon: 'times'
      };
    case alertConstants.clear:
      return {};

    case 'persist/REHYDRATE':
      if (action.payload){
        return {...action.payload.alert}  
      }
      return {}
    
    default:
      return {...state};
    
  }
}