import React from "react";

// import dados pros formularios
import { Form } from 'react-bootstrap';

// componente de contagem
import {useFormikContext, FieldArray, Field} from 'formik';
import { BsXSquareFill } from "react-icons/bs";
import { IconContext } from "react-icons";
import { TableButton } from '../../../../components/button';
import { Table, Thead, Tbody, Th, Tr, Td } from 'react-super-responsive-table';

// css
import '../../alert.css';

const BlackList = () =>{
    const {values, errors} = useFormikContext();  
    let blacklistError;
    //blacklist
    try{
      blacklistError = errors.advancedForm.specific.blacklist;
    }catch (e){
      blacklistError = " "; 
    }

    console.log(errors);
    return(
        <div>
          {/** blacklist */}
          <Form.Group>
            <Table className="table table-striped" id='minhaTabela'>   
            <Thead>
                          <Tr>
                            <Th>Lista de Termos</Th>
                            <Th>       </Th>
                          </Tr>
                        </Thead>          
            <Tbody>
                <FieldArray
                    name="advancedForm.specific.blacklist"
                    render={arrayHelpers => (
                    <div>
                       
                        <IconContext.Provider value={{ className: "global-class-icons" }}>
                        {values.advancedForm.specific.blacklist.map((item, index) => (
                        <Tr key={index}>
                            <Td>
                            <Field className="form-control" name={`advancedForm.specific[blacklist][${index}]`} />
                            </Td>
                            <Td>
                            <BsXSquareFill type="button" onClick={() => arrayHelpers.remove(index)} />
                            </Td>
                        </Tr>
                        ))}
                        
                        </IconContext.Provider>
                        <Tr className='no-hover'>
                            <Td colSpan='2'>
                                    <TableButton icon='add' label='Adicionar Termo' onClick={() => arrayHelpers.push("")} />
                            </Td>
                        </Tr>
                    </div>
                    )}
                />
        </Tbody>
          </Table>      
                <Form.Text className="text-muted error">
                { blacklistError }
                </Form.Text>
            </Form.Group>
        </div>
    );
}

export default BlackList;