import React, { Component } from 'react';

// importing DataSearch here
import { DataSearch } from '@appbaseio/reactivesearch';
import Results from './results';
import { groupByIndex } from "../../services";

import './styles.css';


// importações do redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import BasePage from '../homePage/base'
import AlertDismissibleExample from '../../components/alert'
// import SideBar from '../../common/template/sideBar';
import SearchFilters from '../resultsPage/SearchFilters';
import DirectSearch from '../resultsPage/directSearch';
import SidebarIcon from '../../common/template/sideBar/SidebarIcon';

import { elasticActions, localActions } from '../../redux/actions';
import { ExcludeFields } from '../../config';


class ResultsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            location: [],
            app_index: ''
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
        this.setIndex = this.setIndex.bind(this);
        this.customQuery = this.customQuery.bind(this);
        this.setApp_index = this.setApp_index.bind(this);
    }


    handleUserInput(value) {
        this.setState({ searchTerm: value.toString() });
    }


    
    componentDidMount() {
        const { elasticindex } = this.props
        this.setIndex(elasticindex);
    }

    // seta os índices no array criando atributos adicionais
    setIndex(elasticindex) {
        const location_old = this.state.location;
        elasticindex.map((item, index) => {

      const group = item.substring(0, item.indexOf("-")) 
      const location_new = {};
   
        location_new.type = group
        
        location_new.title = item;
        location_old.push(location_new)
   
            return groupByIndex(location_old);
        }
        );

        this.setState({
            location: groupByIndex(location_old)
        })
    }


    // marca o índice como selecionado e atualiza o app_index
    toggleSelected(id, key) {
        let temp = this.state.location
        temp[key].indexes[id].selected = !temp[key].indexes[id].selected
        this.setState({
            location: temp,
        });
        if (temp[key].indexes[id].selected) {
            this.setState({
                app_index: this.state.app_index + temp[key].indexes[id].title + ','
            });
            
        }
        else {
            this.setState({
                app_index: this.state.app_index.replace(temp[key].indexes[id].title + ',', '')
            });
        }
       
    }

    
    getAppIndex(array) {
    return array.join(',');
    } 

    //seta oos valores dos indices no app_index
    setApp_index() {
    //    e.preventDefault();
        const { selectAppIndex } = this.props;
        let app_index = this.state.app_index !== "" ? this.state.app_index 
        : this.getAppIndex(this.props.listIndex);
        selectAppIndex(app_index, this.props.isOpen);
    }

    setValueSelected(value) {
    //    e.preventDefault();
        const { selectAppIndex, searchMessage } = this.props;
        let app_index = this.state.app_index !== "" ? this.state.app_index 
        : this.getAppIndex(this.props.listIndex);
        selectAppIndex(app_index, null);
        searchMessage(value)
    }

    customQuery=function() {
        return {
          "timeout": "1s",
          "query": {
            "common": {
                "body": {
                    "query": this.state.searchTerm,
                    "cutoff_frequency": 0.001,
                    "minimum_should_match": 2,
                    "low_freq_operator": "and"
                }
              }
          },
          "size": 100
        }
      }
    render() {
        const { searched, alert, fields } = this.props;
        let alertComponent = <AlertDismissibleExample variant={alert.type} title={alert.title} message={alert.message}/>
        /** mapStateToProps */
        const { app_index, isOpen } = this.props;
        /** mapDispatchToProps */
        const { toggleSidebar } = this.props;

        return (

            <BasePage
                level1={<li className="current"><b>{'Pesquisas'}</b></li>}
                
                sidebarIcon = {
                    <SidebarIcon 
                    isOpen={isOpen}
                    handleClick={() => toggleSidebar(isOpen)}
                    />
                }
                searchBar={
                    <DataSearch
                        defaultValue={searched.searchTerm}
                        componentId="query"
                        filterLabel="Pesquisa"
                        dataField={fields}
                        // nestedField={['*.*']}
                        size={9}
                        placeholder="Pesquise aqui..."
                        autosuggest={true}
                        highlight={true}
                        // highlightField={searched.searchTerm}
                        // customQuery={this.customQuery}
                        queryFormat="and"
                        value={this.state.searchTerm}
                        onChange={(value) => this.handleUserInput(value)}
                        onValueSelected={(value) => this.setValueSelected(value)}
                        // showVoiceSearch={true}
                        loader="Buscando..."
                        excludeFields={ExcludeFields}
                        iconPosition="right"
                        URLParams
                        className="searchBar col-s-12 col-m-12 col-l-4"
                        innerClass={{
                            input: 'search-input',
                        }}
                        
                    />}
            

                sidebar = {
                   
                        <div className={ isOpen ? "panel-active" : "panel" }>

                            <DirectSearch
                                listOpen={isOpen}
                                titleHelper="Índice"
                                title="0 Índice selecionado"
                                list={this.state.location} 
                                // list={location ? location : this.state.location} 
                                // list={elasticindex}
                                toggleItem={(id, key) => { this.toggleSelected(id, key) }}
                                setApp_index={() => { this.setApp_index() }}
                                />
                           
                        </div>
                       
                    }
              
                    >
                  
                        <SearchFilters visible={true} valor_index={app_index.split('_')[0]}/>  
             

                    {alert.type ? alertComponent : ''}

                <Results />

            </BasePage>

        );
    }
}


function mapStateToProps(state) {
    const { fields } = state.fields;
    const { searched } = state;
    const { alert } = state;
    const { app_index, listIndex } = state.indexed;
    const { isOpen } = state.searched;
    const { elasticindex } = state.user.user_data.node_unidade;
    return {
        searched, alert, fields,
        app_index, isOpen, elasticindex, listIndex
    };
}

function mapDispatchToProps(dispatch) {
    const { toggleSidebar, selectAppIndex, setLocation } = localActions; 
    const { searchMessage } = elasticActions;
    return bindActionCreators({
        searchMessage, toggleSidebar, selectAppIndex, setLocation
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPage);