
   export const features = [
      {
        "type": "Feature",
        "properties": {
          "scalerank": 2,
          "name": "R. Raimundo Dorotéia, 1389-1387",
          "comment": null,
          "name_alt": null,
          "lat_y": -4.984059,
          "long_x": -42.843243,
          "region": "Santa Maria da Codipi",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.843243, -4.984059]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 2,
          "name": "R. Sd 706 Ltm Asa Norte, 1",
          "comment": null,
          "lat_y": -4.994083,
          "long_x": -42.837938,
          "region": "Santa Maria da Codipi",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.837938, -4.994083]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 2,
          "name": "Q. Cento e Sete, 5499-5379",
          "comment": null,
          "name_alt": null,
          "lat_y": -4.988057,
          "long_x": -42.829983,
          "region": "Santa Maria da Codipi",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.829983, -4.988057]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Dalila Ribeiro, 3483",
          "comment": null,
          "name_alt": null,
          "lat_y": -4.984888,
          "long_x": -42.824281,
          "region": "Santa Maria da Codipi",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.824281, -4.984888]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Firmino Pires Norte, 397",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.089885,
          "long_x": -42.817639,
          "region": "Centro (Sul)",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.817639, -5.089885]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Riachuelo, 830",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.086536,
          "long_x": -42.820105,
          "region": "Centro (Sul)",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.820105, -5.086536]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Manoel Domingues, 1754-180",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.078635,
          "long_x": -42.814746,
          "region": "Centro (Sul)",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.814746, -5.078635]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Des. Freitas, 1672-1750",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.085242,
          "long_x": -42.812194,
          "region": "Centro (Sul)",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.812194, -5.085242]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "Q. Oitenta e Quatro, 2-48.",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.144279,
          "long_x": -42.786558,
          "region": "Parque Piauí",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.786558, -5.144279]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Gameleira, 1938",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.154138,
          "long_x": -42.789887,
          "region": "Promorar",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.789887, -5.154138]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "Av. Jatobá",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.168656,
          "long_x": -42.789890,
          "region": "Angelim",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.789890, -5.168656]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "Q. F3 Cj P Alegre, 41",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.189462,
          "long_x": -42.751744,
          "region": "Esplanada",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.751744, -5.189462]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "Q.32",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.148314,
          "long_x": -42.753859,
          "region": "Santo Antonio",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.753859, -5.148314]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Afonso Pereira Lima, 3470",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.171977,
          "long_x": -42.764224,
          "region": "Angelim",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.764224, -5.171977]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Maria Eremita do Espírito Santo, 415-373",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.160147,
          "long_x": -42.776382,
          "region": "Santo Antonio",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.776382, -5.160147]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Domingos Alves de Holanda",
          "comment": null,
          "name_alt": "Cape Horn",
          "lat_y": -5.158827,
          "long_x": -42.786988,
          "region": "Santa Cruz",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.786988, -5.158827]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Mucuripe, 1969",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.152981,
          "long_x": -42.784541,
          "region": "Santa Cruz",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.784541, -5.152981]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Santa Helena, 1761-1749",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.148145,
          "long_x": -42.791655,
          "region": "Promorar",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.791655, -5.148145]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "Q. Quarenta e Cinco, 223-1",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.146222,
          "long_x": -42.778262,
          "region": "Promorar",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.778262, -5.146222]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Sete Quedas, 2791",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.154028,
          "long_x": -42.777742,
          "region": "Santa Cruz",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.777742, -5.154028]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Da Paz, 16",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.158324,
          "long_x": -42.759751,
          "region": "Parque Sul",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.759751, -5.158324]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "scalerank": 3,
          "name": "R. Elias do Vale, 603-551",
          "comment": null,
          "name_alt": null,
          "lat_y": -5.179827,
          "long_x": -123.401986,
          "region": "Basilar",
          "ocorrence": "X",
          "data": "00/00/0000 00:00"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [-42.759131, -5.179827]
        }
      }
    ]
  