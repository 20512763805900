export const elasticConstants = {
  setFields: "ELASTIC_SET_FIELDS",
  
  searchSuccess:"ELASTIC_SEARCH_SUCCESS",

  getComments: "LOCAL_GET_COMMENTS",
  editComments: "LOCAL_EDIT_COMMENT",
  cancelEditComments: "LOCAL_CANCEL_EDIT_COMMENT",
  

}