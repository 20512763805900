import React from "react";
import Img from '../assets/sinfor_logo.png'
import './styles.css'
import { globalConstants } from "../redux/constants";
 
export const Logo = () => (
      <a className='navbar-brand content-header' href={`${globalConstants.baseAppName}/`}>
        <img className="logo_1 imgLogo" src={Img} alt="Sinfor logo" />
      </a> 
  );

