import React, { Component } from 'react';

import './folder.css';

// import - redux
import { connect } from 'react-redux';

// elastic Search functions from services
import { userService } from '../../services'; 

import Breadcrumb from './Breadcrumb';
import FolderStructure from './FolderStructure';


class FolderList extends Component {
  constructor(props){
    super(props);
    this.state ={
      mainFolder: true,
      list:[],
    }
  }

  async getFolders(){
    const { matricula, dispatch } = this.props;
    const { mainFolder } = this.state;
    let listFolders = [];
    if (mainFolder){
      listFolders = await userService.get_folders(matricula, dispatch);
    }
    this.setState({
      ...this.state,
      'list':listFolders,
    });
    this.getFolders();
  }

  componentDidMount(){
    this.getFolders();
  }


  render() {
    const {list} = this.state;
    const listPath = [
      {item:'arquivo1',link:'#'},
      {item:'arquivo2',link:'#'},
    ];
    return ( 
      <div className='background hidden-sidebar'>
        <Breadcrumb  listPath={listPath}/>
        <FolderStructure list={list} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { matricula } = state.user.user_data;
  return {
    matricula
  };
}

function mapDispatchToProps (dispatch) {
  return {dispatch}
}


export default connect(mapStateToProps, mapDispatchToProps)(FolderList);
