import React, { useState, useCallback, useEffect } from "react";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Message } from '../../components/message_alert';
import { Col, Row } from 'react-bootstrap';
import { elasticActions } from "../../redux/actions";


const CommentForm = ({
    userData, alert, postComment,
    toggleUpdate, document_ID, editComment,
    cancelEditComment,
    putComments,
    }) =>{
        
    const [loading, setLoading ] = useState (false);
    const [edit, setEdit ] = useState (false);
    const [error, setError ] = useState ("");
    const [comment, setComment] = useState({username:userData.username,document_id:[document_ID]})
    const [message, setMessage] = useState("")

    /** retorna o conteúdo da message do comentário */
    const getMessage = () =>{
        return message;
    }
    
    /** organiza comentário */
    const getComment = () =>{
        let item  = comment;
        delete item._id;
        item.message = message;
        return item;
    }

    /** salva os dados de um novo comentário */
    const onSubmit = async (e) =>{
        e.preventDefault();
        let t = await onValidate();
        let m = getMessage();
        if (t){
            setLoading(true);
            await postComment({...comment,...{"message":m}});
            setTimeout(() => {
                toggleUpdate()
                cleanComment();
            }, 500)   
        }

    }
    
    /** salva os dados do comentário editado */
    const onEdit = async (e) =>{
        e.preventDefault();
        let t = await onValidate();
        if (t){
            setLoading(true);
            await putComments(comment._id, getComment());
            setTimeout(() => {
                toggleUpdate()
                cleanComment();
            }, 500)   
        }
    }
    
    useEffect(()=>{
        if (editComment){
            setEdit(true)
            setMessage(editComment.message);
            if(editComment.username===userData.username){
                setComment(editComment);
            }else{
                let editor =  userData.username;
                setComment({...{editor},...editComment});
            }
        }
    },[editComment, userData.username])
    /** limpa o formulario */
    const cleanComment = useCallback(()=>{
        setEdit(false);
        setLoading(false);
        setError("");
        setComment({username:userData.username,document_id:[document_ID]});
        setMessage(""); 
        cancelEditComment();
    },[cancelEditComment, document_ID, userData.username])
        
    /** Reserta o formulário */
    const onClose = (e) =>{
        e.preventDefault();
        cleanComment();
    }

    /** Valida os dados do formulário */
    const onValidate = useCallback(() =>{
        let t = true; 
        if(message===""){
            setError("Digite uma informação.")
            t = false;
        }else{
            setError("")
        }
        return t;
    },[message])

    /** Cria a mensagem de erro */
    const renderError = () => {
        return error ? (
            <div className="alert alert-danger">{error}</div>
        ) : null;
    }
    
    useEffect(()=>{
        if (editComment){
            setEdit(true)
            setMessage(editComment.message);
            if(editComment.username===userData.username){
                setComment(editComment);
            }else{
                let editor =  userData.username;
                setComment({...{editor},...editComment});
            }
        }

    },[editComment, userData.username]);
    
    
    let alertComponent = alert.message ? <Message icon={alert.icon} text={alert.message} alert={alert.type} /> : ""
    
    return (    
        <React.Fragment>
            <form >
                <div className="form-group">
                    <textarea
                        onChange={(e)=>setMessage(e.target.value)}
                        value={message}
                        className="form-control"
                        placeholder="🤬 Digite aqui..."
                        name="message"
                        rows="5"
                    />
                </div>
                {renderError()}

                <div className="form-group">
                    {alertComponent}
                    <Row>
                            <Col md='auto' className='void-col' />
                        <Col md='auto'>
                            {edit?
                                <button  className='btn save-btn' disable={loading} type='submit' onClick={(e)=>onEdit(e)} >Editar ➤</button> 
                                :
                                <button  className='btn save-btn' disable={loading} type='submit' onClick={(e)=>onSubmit(e)} >Publicar ➤</button> 
                            }
                        </Col>
                        <Col md='auto'>
                            <button  className='btn close-btn' type='submit' onClick={(e)=>onClose(e)}>Cancelar &#10006;</button>
                        </Col>
                    </Row>
                </div>
            </form>
        </React.Fragment>
    );
}


const mapStateToProps = state => ({
    userData: state.user.user_data,
    editComment:state.editComment,
    alert: state.alert
});

function mapDispatchToProps(dispatch) {
    const { postComment, cancelEditComment, putComments } = elasticActions;
    return bindActionCreators({
        postComment,
        cancelEditComment,
        putComments,
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
