import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import SystemPages from "./system_pages"; 
import { AdminHome, Users, Orgaos } from "./system_pages/admin";  
import { globalConstants } from './redux/constants';
import './App.css'
import './fadeTrasition.css'

const fullPath = globalConstants.baseAppName;
const { ResetPassword, ActivateEmail, AlertPage, ResultsPage, FolderPage, Imagepage, 
  DashboardPage, DetailsPage, NotFound, HomePage, LoginPage, RegisterPage, MapasPage, 
  ReportPage, TwoFactorPage, TwoFactorRecoveryPage, RedirectPage } = SystemPages;
  

const Routes = () => (
 <Route render={({location}) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={300}
              classNames="fade"
            >
              
    <Switch location={location}>
      <Route exact path={fullPath + "/login"} component={LoginPage} />
      <Route exact path={fullPath + "/two_factor"} component={TwoFactorPage} />
      <Route exact path={fullPath + "/two_factor/recovery"} component={TwoFactorRecoveryPage} />
      <Route exact path={fullPath + "/registrar"} component={RegisterPage} />
      <Route exact path={fullPath + "/activate/:uid/:token"} component={ActivateEmail} />
      <Route exact path={fullPath + "/password/reset/confirm/:uid/:token"} component={ResetPassword} />
      <Route exact path={fullPath + "/portal-da-inteligencia/:username/:authkey"} component={RedirectPage} />
      <Route exact path={"/"} component={() => <Redirect to={{pathname: fullPath + "/login"}}/>} />
      <PrivateRoute exact path={fullPath + "/dashboard"} component={DashboardPage} />
      <PrivateRoute exact path={fullPath + "/mapas"} component={MapasPage} />
      <PrivateRoute exact path={fullPath + "/"} component={HomePage} />
      <PrivateRoute exact path={fullPath + "/results"} component={ResultsPage} />
      <PrivateRoute exact path={fullPath + "/report"} component={ReportPage} />
      <PrivateRoute exact path={fullPath + "/alertpage"} component={AlertPage} />
      <PrivateRoute exact path={fullPath + "/folderpage"} component={FolderPage} />
      <PrivateRoute exact path={fullPath + "/images"} component={Imagepage} />
      <PrivateRoute exact path={fullPath + "/reload/:id"} component={(props) => <Redirect to={fullPath + "/details/"+props.match.params.id}/>} />
      <PrivateRoute exact path={fullPath + "/details/:id"} component={DetailsPage} />
      <PrivateRoute exact path={fullPath +"/admin"} component={AdminHome} />
      <PrivateRoute exact path={fullPath +"/admin/users"} component={Users} />
      <PrivateRoute exact path={fullPath +"/admin/orgaos"} component={Orgaos} />
      <Route path="*" component={NotFound} />
      
    </Switch>
    </CSSTransition>
          </TransitionGroup>
        )} />
);


export default Routes;
