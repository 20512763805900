import React from 'react';
import Routes from "./routes";
import { BrowserRouter } from 'react-router-dom'
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import theme from './theme';
import './Theme-App.css';
import './striped.css';
import {  headers } from './services/index'
import TokenManagement from './components/tokenManagement';
import { useEffect } from 'react';
import { useState } from 'react';
require('dotenv').config()
const App = ({ app_index }) => {
    const [wait, setWait] = useState(false);

    const { REACT_APP_PROXY_ELASTIC_URL } = process.env;

    // const appValue = app_index || ['*'];

    useEffect(() => {
      setTimeout(() => {
        setWait(true)
      }, 500);
    }, [])

    return (
      {wait} ?
      <>
      <BrowserRouter>
        <div className='wrapper'>
          {/* <Header />
        <SideBar /> */}
          <div className="stripes">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ReactiveBase
            app={app_index}  
            url={REACT_APP_PROXY_ELASTIC_URL+'/api_elastic'}
            credentials={'CREDENTIALS'}
            headers={headers} 
            theme={theme}
          >
            <Routes />

          </ReactiveBase>

        </div>
      </BrowserRouter> 
      <TokenManagement />
      </>
      :
      <TokenManagement />
    )
  }


function mapStateToProps(state) {

  const { app_index } = state.indexed;

  return {
    app_index
  };
}


export default connect(mapStateToProps)(App);
