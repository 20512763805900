import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators} from 'redux';
import Loading from '../../common/template/loading';
import AlertDismissibleExample from '../../components/alert';
import { djangoActions } from '../../redux/actions/django.actions/index';
import Logo from '../../assets/sinfor_logo.png';

// Redirect Page
class RedirectPage extends Component {
  constructor(props) {
    super(props)
    this.state = {};
  
  }
  
  componentDidMount() {
    let { match, fromPortalIntel } = this.props;
    let payload = {
      "authkey": match.params.authkey,
      "username": match.params.username,
      "urlreferer": document.referrer
    }
      setTimeout(()=> {
        fromPortalIntel(payload);
      },500)
}

  // Render some text when redirecting
  // You can use a loading gif or something like that
  render() {
    const { isLoading, alert } = this.props;
    let alertComponent = alert.message ? <AlertDismissibleExample variant={alert.type} title={alert.title} message={alert.message}/> : ""

    return (
    <div className='content-hight' style={{position:'relative', justifyContent:'center'}}>
      <img className="imgLogoHome padingLogoHome" src={Logo} alt="Sinfor logo" />
      {alertComponent}
      { isLoading ? <Loading message="Autenticando..." /> :
      ""}
    </div>
  );
};
};

function mapStateToProps(state) {
  const { isLoading } = state.authentication;
  const { alert } = state;
  return {
    isLoading, 
    alert,
  };
}

function mapDispatchToProps(dispatch){
  // const { error, warning } = alertActions;
  const { fromPortalIntel } = djangoActions;
  return  bindActionCreators({
    fromPortalIntel,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(RedirectPage);
