import React from 'react';

import { Modal, Button } from 'react-bootstrap';
//redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

//import da função p/ deletar os dados no banco
import { authenticatedDjangoActions } from '../../redux/actions';

const UpdateAlertModal = ({data, show, close, editAlert}) =>{
    const { name, id, itemData } = data;
    //functions
    async function handleUpdateAlert(){
        await editAlert(itemData, id);
        close();
    }
    return (
        <Modal
            show={show}
            onHide={close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title>Alterando alerta {name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Ao clicar em "Alterar Alerta", você confirma a alteração do alerta: {name}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn save-btn" variant="success" onClick={handleUpdateAlert}>Alterar Alerta</Button>
                <Button className="btn close-btn" variant="secondary" onClick={close}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    );
}

function mapDispatchToProps(dispatch) {
    const { editAlert } = authenticatedDjangoActions;
    return bindActionCreators({
      editAlert
    }, dispatch)
  }


export default connect(null, mapDispatchToProps)(UpdateAlertModal);