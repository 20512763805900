import { validate } from 'gerador-validador-cpf'

export const utils = {
  cpfMask,
  validateCpf,
  numberMask,
  validateEmail,
  validateUsername,
  validatePassword,
};

function cpfMask(value) {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
  function validateCpf(value) {

    let error = validate(value) ? "" : "Insira um CPF válido";

    return error;
  }

 function numberMask(value) {
    return value
      .replace(/[^\d]+/g,'') // substitui qualquer caracter que nao seja numero por nada
     
  }


  function validateEmail(value) {
    const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

    let error = emailRegex.test(value) ? "" : "Insira um email válido";

    return error;
  }


  function validateUsername(value) {
    let error = value.length < 3 ? "O usuário deve conter pelo menos 3 caracteres" : ""

    return error;
  }

  function validatePassword(value) {
    const passwordRegex = RegExp(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,}).+$/);

    let error = passwordRegex.test(value) ? "" : "A senha deve conter letra, número e pelo menos 8 carácteres"

    return error;
  }

 
  export function getNestedValue(obj, path) {
    /** Buscar os campos(keys) dos objetos e sub-objetos */
    const keys = path.split('.');
    const currentObject = obj;
    const nestedValue = keys.reduce((value, key) => {
        if (value) {
            return value[key];
        }
        return '';
    }, currentObject);

    if (typeof nestedValue === 'object') {
        return nestedValue;
     
    }
    return nestedValue;
}

export function getFieldName(fields_Set='index-default', field) {
  /** Mapeia os campos do documento para Labels customizados */
  if (fields_Set[field]) {
      return fields_Set[field]
  } else {
      return field
  }
}

export function strip_html_tags(str)
{
   if ((str===null) || (str===''))
       return false;
  else
   str = str.toString();
  return str.replace(/<[^>]*>/g, '');
}


export function formatDateShort (input) {
  if (input !== null){
    var p = input.split(/\D/g)
  return [p[2],p[1],p[0] ].join("/")
  }
}

export function formatDate(input) {
  if (input !== null){
    const date = new Date(input);
    const formattedDate = [
      date.getDate().toString().padStart(2, '0'),
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getFullYear()
    ].join('/');
    
    const formattedTime = [
      date.getHours().toString().padStart(2, '0'),
      date.getMinutes().toString().padStart(2, '0'),
      date.getSeconds().toString().padStart(2, '0')
    ].join(':');
    
    return `${formattedDate} ${formattedTime}`;
  }
}


export function formatDateLong(dt) {
  const d = new Date(dt);
  const months = ["jan", "fev", "mar","abril", "maio", "jun", "jul", "ago", "set", "out", "nov", "dez"];

  return (dt ? d.getDate() + " de " + months[(d.getMonth())] + ". de " + d.getFullYear() : '')
}

export const addIndexId = ((entity) => {
  const entityFields = entity._source;
  entityFields._index = entity._index;
  entityFields._id = entity._id;
  return entityFields
})

export const removeIndexId = ((entity) => {
  let myarray = []
  let entityFields = Object.keys(entity)
  for (let i = 0; i < entityFields.length; i++) {
      if (entityFields[i] !== "_id" && entityFields[i] !== "@timestamp" && entityFields[i] !== "_index") {
        if (typeof entityFields[i] === "string") {
          myarray = myarray.concat(entityFields[i])
        }
      }
  };
  return myarray
})

export function groupByIndex(array) {
  var newArr = [],
  types = {},
  i, j, cur;
for (i = 0, j = array.length; i < j; i++) {
  cur = array[i];
  var location_new = {};
  if (!(cur.type in types)) {
      types[cur.type] = {type: cur.type, indexes: []};
      newArr.push(types[cur.type]);
  }
  location_new.id = i;
  location_new.type = cur.type
  location_new.title = cur.title;
  location_new.selected = false;
  location_new.key = 'location';
  location_new.count = cur.count;
  location_new.timestamp = cur.timestamp;
  types[cur.type].indexes.push(location_new);
}
return newArr;
}
