import React, { Component } from 'react';
// import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap'
import '../loginPage/styles.css'
// import Logo from '../../../assets/sinforlogo.png'
// Ações do usuário
// import { userActions } from '../../../redux/actions';
// import Icon from '../../../assets/alarm.png';
// Funções do redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { authenticatedDjangoActions } from '../../redux/actions';

class ApresentacaoHeader extends Component {    
    
   
getCss(start) {
    let params = start;
    const { color, shadow, border, staticL} = this.props;
    if (color){
        params += ' color';
    }
    if (shadow) {
        params += ' box-header';
    }
    if (border) {
        params += ' border';
    }
    if (staticL){
      params += ' static';
    }
    return params;
}
    render() {
        const divCss = this.getCss("navbar showHeader");
               
        return (
            <div className={divCss}>
                                                
                    {this.props.children}
              
            </div>
        );
    }

}


function mapStateToProps(state) {
    const { user_data } = state.user;
    const { reportList } = state.report;
    const { isOpen } = state.searched;
    return { user_data, reportList, isOpen };

}

function mapDispatchToProps(dispatch) {
    const { logout } = authenticatedDjangoActions;
    return bindActionCreators({
        logout
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ApresentacaoHeader);
