import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import BasePage from '../../homePage/base'
import { globalConstants } from '../../../redux/constants';

import { userService } from '../../../services';
//table
import { Table, Thead, Th, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { TableButton } from '../../../components/button'
import { Message } from '../../../components/message_alert';
import { Card, Form } from 'react-bootstrap';

import OrgaosItem from './orgaosItem';

function List({ token }) {
    const [users, setUsers] = useState(null);

    useEffect(() => {
        userService.getAll().then(x => setUsers(x));
    }, [token]);

    // function deleteUser(id) {
    //     setUsers(users.map(x => {
    //         if (x.id === id) { x.isDeleting = true; }
    //         return x;
    //     }));
    //     accountService.delete(id).then(() => {
    //         setUsers(users => users.filter(x => x.id !== id));
    //     });
    // }
    
        let users_list =  users && users.map((item, index) =>

            <OrgaosItem  key={index} count={index+1} name={item.name} 
            //  onClick={(id) => handleItemClick(item._id)} deleteItem={deleteItem} id={item._id} 
             />     
        );
           
    return (
    <BasePage level1={<li className="visited">
                 <Link to={globalConstants.baseAppName + "/admin"}>
                    <span>{'Gestão'}</span>
                  </Link> 
                </li>}
            level2={<li className="current"><b>{'Unidades / Órgãos'}</b></li>} >

                    {/* <h5>Administração de Unidades</h5> */}
                    <div className="w3-third2 ">
            
                  {users_list !== null ?   
                        <React.Fragment>
                       <Form >
              <Card >
                {/** title */}
                <Card.Title className="w3-theme-l1 card-header">{'Unidades / Órgãos cadastrados'}</Card.Title>
            
                      <Table className="table table-striped" id='minhaTabela'>
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th>Nome</Th>
                                <Th>       </Th>
                                <Th>       </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {users_list}
                            <Tr className='no-hover'>
                                <Td colSpan='6'/>
                                <Td colSpan='2'>
                                    {/* <TableButton icon='add' label='Adicionar Alerta' //onClick={createItem} */}
                                    {/* /> */}
                                </Td>
                            </Tr>
                        </Tbody>
                         </Table>
              
                </Card>
                </Form>
                        </React.Fragment>
                        :
                        <React.Fragment>
                        
                            <Message text="Nenhuma Unidade ou Órgão foi cadastrado." alert="info" icon="info" />
                            
                            <div className='no-hover flex-row row-reverse'>
                                <Td colSpan='6'/>
                                <Td colSpan='1'>
                                    <TableButton icon='add' label='Adicionar' //onClick={createItem}
                                    />
                                </Td>
                            </div>
                        </React.Fragment>
                    }
        </div>
        </BasePage>
    );
}

function mapStateToProps(state) {
    const { token } = state.authentication.data;
    const {alert} = state;
    return {
        token,
        alert,
    };
}
export default connect(mapStateToProps)(List);