import React, { Component } from 'react';
import { connect } from 'react-redux';
import components from '../../components'
import RenderListItem from '../../services/renderListItem';
import { addIndexId } from '../../services';
// import { TabPanel } from 'primereact/tabview';

import { indexesShortNames, itensRelated } from '../../config';

import DetailsItem from './detailsItem';
import { filterIndexs } from '../alertPage/functions'

import CommentList from './commentList';
import CommentForm from './commentForm';
import MoreLikeThis from './moreLikeThis';
import GaleriaComponent from './galeria';
import {Message} from '../../components/message_alert';

// import TabViewRP from './tabViewRP';
// import UploadFiles from '../../components/FileUpload';
import './styles.css';

// import BuildTable from './buildTable';
import DataTableComponent from './dataTable';
import DownloadTableComponent from './DownloadTable';

class GenericDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valor_index: '',
            entity: [],
            isUpdate: false,
            dataRows: [],
            dataCols: []
        };

        this.toggleUpdate = this.toggleUpdate.bind(this);
      
    }

    toggleUpdate() {
        /** Alterna o modo de visualização da tela */
        this.setState((prevState) => {
            return { isUpdate: !prevState.isUpdate }
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
      }
    RenderSubList = (pos) => {
        const { entity, valor_index } = this.props;
        const entityFields = entity._source
         
        /** Renderiza os itens anexados ao documento principal */
        const { elasticindex } = this.props;
        if (itensRelated[valor_index]) {
            let label = "", 
            ind = "",
            param = "",
            list_right = false,
            field = "",
            list_itens = itensRelated[valor_index].map((item, index) => { 
                let component = null
                for (var [key] of Object.entries(item)) {
                    if (key === 'id_group' && pos === 'list_all') {
                        param = item[key];
                        label = "Itens do Documento: "
                            ind = filterIndexs(elasticindex, [valor_index]);
                            let list_chain = false
                            list_right = false;
                            component = <RenderListItem key={index}
                                label={label}
                                ind={ind}
                                param={param}
                                valor={entityFields[param]}
                                valor_index={ind}
                                list_chain = {list_chain}
                                list_right={list_right}
                            />
                    }
                    else if (key === 'list_chain' && pos === 'list_all') {
                        label = "Itens do Documento: "
                        ind = item[key][1]
                        list_right = false;
                        const list_chain = true, 
                        param_ass = item[key][2],
                        index_enc = item[key][3],
                        param_enc = item[key][4];
                        param = item[key][5];
                        
                            component = <RenderListItem key={index}
                                    label={label} 
                                    ind={ind} 
                                    param={param} 
                                    valor={entityFields[param]} 
                                    list_chain = {list_chain}
                                    param_ass = {param_ass}
                                    index_enc = {index_enc}
                                    param_enc = {param_enc}
                                    list_right={list_right}
                                    valor_index={valor_index}
                                    />
                        
                    }
                    else if (key === 'list_hight' && pos === 'list_right') {
                        label = item[key][0];
                        param = item[key][1];
                            // eslint-disable-next-line no-loop-func
                            ind = elasticindex.find(element => element === item[key][2])
                            let list_chain = false
                            list_right = true;
                            component = <RenderListItem key={index}
                                label={label}
                                ind={ind}
                                param={param}
                                valor={entityFields[param]}
                                valor_index={ind}
                                list_chain = {list_chain}
                                list_right={list_right}
                            />
                    }
                    else if (key === 'list_chain' && pos === 'list_right') {
                        label = item[key][0]
                        // indice da tabela associação
                        ind = item[key][1]
                        list_right = true;
                        const list_chain = true, 
                        param_ass = item[key][2],
                        // eslint-disable-next-line no-loop-func
                        index_enc =  elasticindex.find(element => element === item[key][3]),
                        param_enc = item[key][4];
                        param = item[key][5];
                        
                        component = <RenderListItem key={index}
                                    label={label} 
                                    ind={ind} 
                                    param={param} 
                                    valor={entityFields[param]} 
                                    list_chain = {list_chain}
                                    param_ass = {param_ass}
                                    index_enc = {index_enc}
                                    param_enc = {param_enc}
                                    list_right={list_right}
                                    />
                    }
                    
                    else if (key === 'list_vinculated' && pos === 'list_vinculated') {
                        label = item[key][0];
                        param = item[key][1];
                        ind = elasticindex;
                        field = item[key][2];
                        component = <div key={index} className="d-flex flex-row" style={{width:'100%' }} >
                                <RenderListItem  
                                    label={ label + ": "} 
                                    ind={ind} 
                                    valor={entityFields[param]} 
                                    param={field} 
                                    />
                                </div>
                    }

                    else if (key === 'list_table' && pos === 'list_table') {
                        label = item[key][0]
                        param = item[key][1];
                        const el = item[key][2]
                        // ind = elasticindex.find(element => element === el)
                        component = <DataTableComponent  key={index}
                            label={label}
                            ind={el} 
                            param={param} 
                            valor={entityFields[param]} 
                        />
                    }
                    else if (key === 'download_list' && pos ==='download_list'){
                        label = item[key][0]
                        ind = item[key][1];
                        component = <DownloadTableComponent  key={index}
                            label={label}
                            ind={ind} 
                            param={param} 
                            valor={entityFields[param]} 
                        />

                    }
                    else if (key === 'list_img' && pos ==='list_img'){
                        component = <GaleriaComponent  key={index}
                            label={item[key].label}
                            id={entity._id} 
                            indexName={item[key].index} 
                            fieldName={item[key].fieldName} 
                            file_path={item[key].filePath} 
                            file_name={item[key].fileName} 
                        />

                    }
                }
                return component
            })
            return list_itens
        };

    }

  
    render() {

        /** Renderiza o documento */
        // Desestruturação dos componentes utilizados 
        const { Tabs, Panel } = components
        const { entity } = this.props;
                
        const list_vinculated = this.RenderSubList('list_vinculated');
        console.log(list_vinculated)
        const list_right = this.RenderSubList('list_right');
        const list_all = this.RenderSubList('list_all');
        const list_table = this.RenderSubList('list_table');
        const download_list = this.RenderSubList('download_list');
        const list_img = this.RenderSubList('list_img');
        const isUpdate = this.state.isUpdate
        let valor_index = entity._index.split('_')[0]
        return (
            <Tabs selected={0}>
                <Panel title={indexesShortNames[valor_index]}>

                    <div className="w3-row-padding">

                        <div style={{ width: '100%' }} >
                            <DetailsItem valor_index={valor_index} entityFields={addIndexId(entity)} />
                            <br />
                          
                            {list_table}
                            {download_list} 
                           
                            <MoreLikeThis id={entity._id} index={entity._index} />
                 <br/>          
                 </div>

                        <div className="w3-third">
                            {list_right}
                        </div>

                    </div>

                </Panel>
                <Panel title="Partes Integrantes" >
                    <div className="w3-row-padding" >
                        <div style={{ display: 'flex', width: '100%' }}>
                        {list_all}
                        </div>
                    </div> 


                </Panel>
                <Panel title="Conteúdo vinculado">
                    <div className="w3-row-padding">
                        <div style={{width: '100%' }}>
                            {list_vinculated}
                        </div>
                    </div>
                </Panel>
                <Panel title="Imagens" >
                    <div className="d-flex flex-column">
                    <div className="columnIcon w3-round-xlarge" style={{minWidth:"100%"}}>
                        <div className="w3-row-padding columnIcon w3-round-xlarge" >
                            <div style={{ display: 'block', width: '100%' }}>
                            {list_img && list_img.filter(x=> x !== null).length ? <center>{list_img}</center>  : 
                            <Message text="Este item não possui images" alert="info" icon="info" />  }                        
                            </div>
                        </div>
                    </div>
                    </div>
                </Panel>

            </Tabs>
                    

        );
    }
}

function mapStateToProps(state) {
    const { elasticindex } = state.user.user_data.node_unidade;
    return {
        elasticindex, 
    };
}

export default connect(mapStateToProps)(GenericDetails);



