import { fieldsToLabels } from '../../config';


/*------------------------------Functions--------------------------------------------------------------------*/
const jsonTextGenerating = (index) =>{
let jsonArgs = {
    "_id": "Identifica\u00e7\u00e3o",
    "@timestamp": "Data/Hora",
}
return Object.assign(jsonArgs, fieldsToLabels[index]);
}

// tipos de alertas
export const typeList = [
    {"name":"Frequência", "type":"frequency"},
    {"name":"Lista Negra", "type":"blacklist"},
    {"name":"Novo Termo", "type":"new_term"},
    //{"name":"Mudança", "type":"change"},
]


//* gerando json p/submissao
function generateAlertName(name, matricula){
    const timestamp = Date.now();
    return timestamp+matricula+": "+name; 
    // name.replace(" ","_")+";"+timestamp+";"+matricula;
}


///name (out)
function generatingExternalName(name,matricula){
    const timestamp = Date.now();
    return name+";"+timestamp+";"+matricula;
}


export function getExternalName(name){
    return name.split(';')[0];
}

const standartFormat = (data, matricula) =>{
    let dataToSend = {
        name: generatingExternalName(data.standartForm.name, matricula),
        yaml:{
            name:generateAlertName(data.standartForm.name, matricula),
            type: 'frequency',
            index: data.standartForm.index,
            filter:[{
                query:{
                    multi_match: {
                        query: data.standartForm.term,
                        type:"phrase",
                        fields: "*"
                    }
                }
            }],   
            num_events: 1,
            timeframe: {
                hours: 1
            },
            alert:["email"],
            email:data.standartForm.email,
            advanced:data.advanced,
            json_text_args: jsonTextGenerating(data.standartForm.index),
            alert_subject:'SINFOR Alerta - Novo evento detectado para "'+data.standartForm.term+'"'
        }
    }
    if (data.standartForm._id !== null || data.standartForm._id !== undefined){
        dataToSend._id = data.standartForm._id;
    }

    return dataToSend;
}


const shippingFormat = {
    'frequency': function (data, matricula){
        let dataToSend = {
            name: generatingExternalName(data.advancedForm.name, matricula),
            yaml:{
                name:generateAlertName(data.advancedForm.name, matricula),
                type: data.advancedForm.type,
                index: data.advancedForm.index,
                filter:[{
                    query:{
                        multi_match: {
                            query: data.advancedForm.specific.term,
                            type:'phrase',
                            fields: "*"
                        }
                    }    
                }],   
                num_events: data.advancedForm.specific.num_events,
                timeframe: {
                    hours: data.advancedForm.specific.timeframe
                },
                alert:["email"],
                email:data.advancedForm.specific.email,
                advanced:data.advanced,
                json_text_args: jsonTextGenerating(data.advancedForm.index),
                alert_subject:'SINFOR Alerta - Novo evento detectado para "'+data.advancedForm.specific.term+'"'

            }
        }
        if (data.advancedForm._id !== null || data.advancedForm._id !== undefined){
            dataToSend._id = data.advancedForm._id;
        }
        return dataToSend;
    },
    'blacklist': function (data, matricula) {
        let dataToSend = {
            name: generatingExternalName( data.advancedForm.name, matricula),
            yaml:{
                name:generateAlertName( data.advancedForm.name, matricula),
                type:data.advancedForm.type,    
                index: data.advancedForm.index,
                compare_key:"/.*/",
                blacklist:data.advancedForm.specific.blacklist,
                alert:["email"],
                email:data.advancedForm.specific.email,
                advanced:data.advanced,
                json_text_args: jsonTextGenerating(data.advancedForm.index),
                alert_subject:'SINFOR Alerta - Novo evento detectado para "'+data.advancedForm.specific.blacklist+'"'

            }
        }
        
        if (data.advancedForm._id !== null || data.advancedForm._id !== undefined){
            dataToSend._id = data.advancedForm._id;
        }

        return dataToSend;
    },
    'new_term':function(data, matricula){
        let dataToSend = {
            name: generatingExternalName(data.advancedForm.name, matricula),
            yaml:{
                name:generateAlertName(data.advancedForm.name, matricula),
                type:data.advancedForm.type,    
                index: data.advancedForm.index,
                alert:["email"],
                email:data.advancedForm.specific.email,
                //
                fields: ["/.*/"],
                filter:[
                    {term: {"/.*/" :data.advancedForm.specific.term_filter}},
                ],
                term_window_size: {
                    days:  data.advancedForm.specific.term_window_size},
                advanced:data.advanced,
                json_text_args: jsonTextGenerating(data.advancedForm.index),
                alert_subject:'SINFOR Alerta - Novo evento detectado para "'+data.advancedForm.specific.term_filter+'"'
                
            }
        }
        
        if (data.advancedForm._id !== null || data.advancedForm._id !== undefined){
            dataToSend._id = data.advancedForm._id;
        }

        return dataToSend;
    },
   
}



export function stateToJson(data, matricula){
    let dataForm;
    if (data.advanced){
        dataForm = shippingFormat[data.advancedForm.type](data, matricula);
    }
    else{
        dataForm = standartFormat(data, matricula);
    }
    return dataForm;
}


/**************************** gerar as listas de indices ****************************/
export function valueInList(title, list){
    let result = false;
    list.map(item=>{
        if (item.split("-")[0]===title){
            result = true;
        }
        return null
    });
    return result;
  }


  export function filterIndexs(listIndexs, indexes){
      let indexs = [];
      listIndexs.map((value)=>{
        let title = value.split("-")[0];
        if (valueInList(title, indexes)){
            indexs.push(value)
        }
        return null;
    });
    return indexs; 

}

//get Alerts Terms

function listToString(list){
    let string = "";
    for (let i = 0; i < list.length; i++){
        if(i<list.length-1){
            string = string+list[i]+", ";
        }else{
            string = string+list[i];
        }
    }
    return string;
}



export function getTerm(alert){
    let term = "";
    //frequency
    if (alert.yaml.type==="frequency"){
        term = alert.yaml.filter[0].query.multi_match.query;
    }
    //blacklist
    if (alert.yaml.type==="blacklist"){
        term = listToString(alert.yaml.blacklist);
    
    }
    //new_term 
    if (alert.yaml.type==="new_term"){
        term = alert.yaml.filter[0]['term']['/.*/'] 
    }
    return term
}

export function getType(yaml){
    let type = "";
    if(yaml.advanced){
        type= yaml.type 
    }else{
        type= "Padrão"
    }
    return type
}


export function getAlertInList(id, list){
    let item_ = {};
    list.map((item)=>{
      if (item._id===id){
         item_ = item
      }
      return null
    })
    return item_
  }


/*-----------------------------------------------------------------------------------------------------------*/






