import { api as http} from '../newapi'

const upload = (file, onUploadProgress) => {
    let formData = new FormData();
  
    formData.append("file", file);
  
    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  };
  
  const getFiles = () => {
    return http.get("/files");
  };
  const downloadFiles = (filename) => {
    return http.get(`/files/${filename}`);
  }; 
  
  export default {
    upload,
    getFiles,
    downloadFiles
  };