import React from "react";

// import dados pros formularios
import { Form, Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';

// componente de contagem
import Counter from './counter';

// css
import '../../alert.css';


export default function Frequency() {
  const {values, errors, handleChange} = useFormikContext();
  
  let termError;
  
  //term
  try{
    termError = errors.advancedForm.specific.term;
  }catch (e){
    termError = " "; 
  }
  
  return(
    <div>
      {/** Term */}
      <Form.Group>
        <Form.Label>Termo Monitorado</Form.Label>   
        <br/><small>Escreva o termo/frase que deseja monitorar</small>             
        <Form.Control type="text" name="advancedForm.specific.term" value={values.advancedForm.specific.term}  
          onChange={ handleChange }/>  
        <Form.Text className="text-muted error">
          {termError}
        </Form.Text>
      </Form.Group>
      <Form>
        <Row>
          <Col>
          {/** num_events */}
          <Counter name='num_events' title="Quantidade de Eventos " initial={values.advancedForm.specific.num_events}
            errors={errors}/>
          </Col>
          <Col>
          {/** timeframe */}
          <Counter name='timeframe' title="Período de ocorrência(h)" initial={values.advancedForm.specific.timeframe} 
            errors={errors} />
          </Col>
        </Row>
      </Form>
    </div>
  );
}
