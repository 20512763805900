import React, { useState, useEffect } from 'react';

//import redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

//components 
import AlertList from './alertList';

import AlertForm from './form';
//functions
import { getAlertInList } from './functions';

//css
import './alert.css';
//import {  updateAlert, addAlert } from './apiAlerts';

//getAlertas
import { authenticatedDjangoActions } from '../../redux/actions';

//table
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const AlertComponent = ({getAlerts, alerts,deleteItem,changeItem}) =>{
  //state
  const [alertList, setAlertList] = useState([]);
  const [alertSelected, setAlertSelected] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  
  // functions
  const changeEditing = () =>{setIsEditing(!isEditing)} 
  const changeCreating = () =>{setIsCreating(!isCreating)} 
  const setItemSelected = (id) =>{
    let alert_ = {};
    alert_ = getAlertInList(id, alertList);
    setAlertSelected(alert_)
    changeEditing();
  }

  //lifeCycle
  //getAlert
  async function getList(){
    if (alerts.update===true){
      await setAlertList(alerts.alerts);
    }else{
      await getAlerts(); 
    }      
  }
  useEffect(()=>{
    getList();
  });

  return (        
        <div className="w3-third2 ">
          {/**addAlert **/
          isCreating?
          <AlertForm close={changeCreating}/>:
          <div className="">
              {/**editAlert  **/
              isEditing?
              <AlertForm close={changeEditing} alertData={alertSelected} changeItem={changeItem} />:
              /**table **/
             
                        <AlertList alerts={alertList} handleItemClick={(id) => setItemSelected(id)} 
                        deleteItem={deleteItem} createItem={changeCreating} />
              
              }
            </div>
          }
        </div>
  ); 
}

function mapStateToProps(state) {
  const { alerts } = state;
  return { 
    alerts: alerts, 
  }
}



function mapDispatchToProps(dispatch) {
  const { getAlerts } = authenticatedDjangoActions;
  return bindActionCreators({
    getAlerts
  }, dispatch)
}


export default connect(mapStateToProps,mapDispatchToProps)(AlertComponent);