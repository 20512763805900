import React, { useState, useEffect } from "react";

import img from '../../assets/loading_spinner.gif'
import { elasticServices, addIndexId } from "../../services";
import RenderEntityItem from '../../services/renderEntityItem';
import { Rating } from 'primereact/rating';

const  MoreLikeThis = ({id, index }) => {
  const [loading, setLoading] = useState(false);
  // const [update, setUpdate] = useState(props.update);
  const [likeThisList, setLikeThisList] = useState([]);
  
        
  useEffect(() => {
      setLoading(true);
      elasticServices.getMoreLikeThis(id, index).then(list => setLikeThisList(list));
      setTimeout(() => setLoading(false), 1000)
     
  }, [id,index]);
    

  return (
    <div>
     {loading ?
      <img src={img} alt='loading' width="80px" />
      :
      (likeThisList.length > 0) ? 
      <div>
        <div className="topic-label">Resultados semelhantes</div>
      <div className="morelike">
      {likeThisList.map((entityLike, index) => (
        <div className="card morelike-item w3-hover-shadow" key={index}>
          <Rating value={`${5 - index}`}  readOnly stars={5} cancel={false} />
          <RenderEntityItem key={entityLike._id} entity={addIndexId(entityLike)} list_right={true} /> 
        </div>
      ))}
       </div>
      </div>
        : " "
    }
    </div> 
  );
}


export default (MoreLikeThis);

