import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { globalConstants } from '../../../redux/constants';
import { Link } from 'react-router-dom';

import BasePage from '../../homePage/base'
import { authenticatedDjangoActions, djangoActions } from '../../../redux/actions';

import Loading from '../../../common/template/loading';

import { Message } from '../../../components/message_alert';

import { DataTable } from 'primereact/datatable'; 
import { Column } from 'primereact/column'; 
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';


const UsersAdmin = ({
    node_unidade,
    user_data, 
    updateUnitUser,
    activateUserByAdmin,
    alert,
}) =>{ 
    const [units, setUnits] = useState("void");

    const [users, setUsers] = useState("void");
    const [globalFilter, setGlobalFilter] = useState('');
    
    const [hasChange,setHasChange] = useState(true)
    const changing = () =>{
        setHasChange(!hasChange);
    }
    


    /** set units */
    useEffect(()=>{
        const fetchUnits = async () => {
            const { getUnitsList } = djangoActions;
            const { is_admin_react } = user_data;
            const { id } = node_unidade;
            let unitsList = await getUnitsList(is_admin_react, id);
            setUnits(unitsList);
            changing();
        }

        fetchUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[node_unidade, user_data]);


    useEffect(()=>{
        const fetchUsers = async () =>{
            const { getUsersList } = authenticatedDjangoActions;
            let usersList = await getUsersList(units);
            setUsers(usersList);
        }
        fetchUsers();
    },[hasChange, units]);

    
    async function changeUserUnitAndUpdate (username, unitId){
        setUsers('void')
        await updateUnitUser(username, unitId);
        changing();
    }


    const unitPicker = (rowData) => {
        const { id } = rowData.node_unidade;
        return <Dropdown options={units} value={id} optionLabel="nome" optionValue='id' 
            onChange={(e)=>{
                const {value} = e.target;
                e.preventDefault();
                changeUserUnitAndUpdate(rowData.username, value);
            }} 
            />
    }

    async function activateUserAndUpdateScreen (username){
        setUsers('void')
        await activateUserByAdmin(username);
        changing();
    }

    const selectUserStatus = (rowData) =>{
        return <InputSwitch checked={rowData.is_active} onChange={()=>{activateUserAndUpdateScreen(rowData.username)}} />
    }

    const reset = (e) =>{
        e.preventDefault()
        setGlobalFilter('')
    }
    
    const header = (
        <div className="table-header">
            <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" autoFocus="true" value={globalFilter} 
                    placeholder="Filtragem de itens"
                    onChange={(e) =>{
                        e.preventDefault()
                        setGlobalFilter(e.target.value)
                    }}
                />
            </span>
        </div>
    );  

    const UserTable = ({users}) =>{
        return (
            <div className="w3-third2 datatable-responsive">
                <DataTable value={users} header={header} className="p-datatable-responsive" 
                    dataKey="username" rowHover 
                    globalFilter={globalFilter}
                    paginator rows={10} emptyMessage="Não há itens" currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} itens"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5,10,50]}
                    editMode="cell">
                    <Column field="name" header="Nome" sortable={true} style={{width:'20%'}}/>
                    <Column field="username" header="Login" sortable={true} style={{width:'20%'}}/>
                    <Column field="email" header="Email" sortable={true} style={{width:'20%'}}/>
                    <Column field="matricula" header="Matrícula" sortable={true} style={{width:'10%'}}/>
                    <Column field="node_unidade" body={unitPicker} sortable={false} header="Unidade" />
                    <Column field="is_active" header="Ativo" sortable={true} body={selectUserStatus} style={{width:'10%'}}/>
                </DataTable>
            </div>
        );
    }

    let alertComponent = alert.message ? <Message icon={alert.icon} text={alert.message} alert={alert.type} /> : ""

    return (
        <BasePage level1={<li className="visited"><Link to={globalConstants.baseAppName + "/admin"}><span>{'Gestão'}</span></Link></li>} level2={<li className="current"><b>{'Usuários'}</b></li>}> 
            {alertComponent}
            {/**loading */}
            {units==='void' || (typeof units === 'object' && users === 'void')?
                <Loading/>:
                (users === 'Error' || units === 'Error')?
                    <p>Não foi possivel carregar os Usuários</p>:
                    users.length === 0?
                        <p>Não há usuários com niveis</p>:
                        <UserTable users={users} />
            }
        </BasePage>
    ); 
}

const mapStateToProps = (state) =>{
    const { user_data } = state.user;
    const { is_admin_react, is_gestor } = state.user.user_data;
    const { node_unidade } = state.user.user_data;
    const { alert } = state;

    return {
        user_data, 
        is_admin_react, 
        is_gestor,
        node_unidade,
        alert,
    }; 
}

const mapDispatchToProps = (dispatch) =>{
    const { updateUnitUser, activateUserByAdmin } = authenticatedDjangoActions;
    return bindActionCreators({
        updateUnitUser,
        activateUserByAdmin,
    },dispatch);
} 


export default connect(mapStateToProps, mapDispatchToProps)(UsersAdmin);

    // const changeUnit = async (username, unitId) => {
    //     await updateUnitUser(username, unitId);
    //     changing();
    // }
