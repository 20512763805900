import React from "react";

export const BoxIndex = (props) => {
    return (
    <div> 
            <div> 
                {props.icon} &nbsp;
                {props.title}
                <div className='index_info-count'> 
                {props.text}
                </div> 
                <div className='index_info-time'> 
                {props.timestamp}
                </div> 
            </div>
            
        </div> 
)
    }