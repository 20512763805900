import React, { Component } from 'react';

import { SelectedFilters, ReactiveList} from '@appbaseio/reactivesearch';
// import { SelectedFilters, ReactiveList, SingleDropdownRange } from '@appbaseio/reactivesearch';
import PropTypes from 'prop-types';
import './results.css'
import RenderEntityItem from '../../services/renderEntityItem';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { userActions } from '../../redux/actions';
import Loading from '../../common/template/loading';
import { Message } from '../../components/message_alert';

 
const onResultStats = (stats) => (
    <div className="flex justify-end resultstatus">
        {stats.numberOfResults} resultados encontrados em {stats.time}ms
	</div>
);
 
/** Carrega as entidades na lista de itens */
class Resultsdata extends React.Component {
    shouldComponentUpdate(prevProps) {
      if (
        JSON.stringify(prevProps.data) === JSON.stringify(this.props.data) &&
        prevProps.loading === this.props.loading
      ) {
        return false;
      }
      return true;
    }
  
    render() {
      const { data, loading, error } = this.props;
  
        if (loading) {
            return <div><Loading message="Pesquisando bases de dados..."/></div>;
        }
        if(error) {
            return (
                <div>
                   <Message text={`Algo deu errado! Detalhes do erro: ${JSON.stringify(error.statusText)}`}
                    alert="danger" icon="times" />
                    
                </div>
            )
        }
        return  (
        <React.Fragment>
        {data.map(item => 
            <RenderEntityItem key={item._id} entity={item} />
        
        )}
        </React.Fragment>
        )   
    }
}
 

class Results extends Component {
    

    render() {
    
    return <div className="result-list">
 
        <div className="filter-info"> 
            
            <SelectedFilters 
                // render={(props) => {
                //     const { selectedValues, setValue } = props;
                //     const clearFilter = (component) => {
                //         setValue(component, ' ');
                //     };

                    // const filters = Object.keys(selectedValues).map((component) => {
                    //     if (!selectedValues[component].value) return null;
                    //     return (
                    //         <button
                    //             key={component}
                    //             onClick={() => clearFilter(component)}
                    //         >
                    //             {selectedValues[component].value}
                    //         </button>
                    //     );
                    // });

                    // return filters;
                // }}
                showClearAll={true} 
                clearAllLabel="Limpar" 
            />
        </div>

        <ReactiveList
            componentId="resultlist"
           // dataField={'nome'}
           showLoader={true}
            dataField={'cnpj,nome,cpf,sexo,_score,_id,situacao'}
            render={data => <Resultsdata {...data}/>}
            // onData={data => onData(data, currentTopics, toggleTopic)}
            renderResultStats={stats => onResultStats(stats)}
            // passar os id dos campos de filtros dos componentes DataSearch e SearchFilters na tag react abaixo
            react={{
                and: ['query', 'multiList_1', 'multiList_2', 'SingleList_1', 'dropdownList_1', 'ultimos', 'DateSensor',
                'toggleButton' ],
            }}
            pagination
            innerClass={{
                list: 'result-list-container',
                pagination: 'result-list-pagination',
                resultsInfo: 'result-list-info',
                poweredBy: 'powered-by',
            }}
            size={6}
            showEndPage={true}
            sortOptions={[
                {
                    label: 'Mais relevantes',
                    dataField: '_score',
                    sortBy: 'desc',
                },
                {
                    label: 'Menos relevantes',
                    dataField: '_score',
                    sortBy: 'asc',
                },
                {
                    label: 'Mais novos',
                    dataField: '@timestamp',
                    sortBy: 'desc',
                },
                {
                    label: 'Mais antigos',
                    dataField: '@timestamp',
                    sortBy: 'asc',
                },

            ]}
            renderNoResults = {() => <div className="noResults">
                  <h5>Nenhum resultado encontrado para sua pesquisa!</h5>
                  <strong>Por favor, refine sua pesquisa ou escolha outro termo de busca.</strong>
                </div>
              }
        />


    </div>
}
}

Results.propTypes = {
    toggleTopic: PropTypes.func,
    currentTopics: PropTypes.arrayOf(PropTypes.string),
};

function mapStateToProps(state) {
    const { isOpen } = state.searched;
    return { isOpen };

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);
