import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { localActions } from '../../redux/actions';
import { authenticatedDjangoActions } from "../../redux/actions";
import { getDateDiff } from './utils';
import TokenModal from './TokenModal'

const TokenManagement = ({
  start,
  update,
  expire,
  logout,
  hasUpdateTimeToken,
}) => {
  
  const [time, setTime] = useState(getDateDiff(expire)|| null);
  const [modal, setModal] = useState(false);
  const [wasOpened, setWasOpened] = useState(false)
  
  function openModal(){setModal(true)}
  function closeModal(){setModal(false)}

  useEffect(()=>{
    Notification.requestPermission();
  },[])


  useEffect(() => {
    if (start){
      if (update){
        setTime(getDateDiff(expire))  
        closeModal()
        hasUpdateTimeToken()
        setTimeout(()=>{
          setWasOpened(false);
        },3000)
      }else{
        setTimeout(async () => {
          setTime(await getDateDiff(expire))
        }, 1000);
      }
    }
  }, [time, start, update, expire, hasUpdateTimeToken])
  
  const notifyOptions = {
    body: `Renove sua sessão ou faça logout automático em `+time+` segundos.`,
    icon: '/favicon.png'
  }

  const notifyTitle = 'SINFOR Explorer'


  useEffect(()=>{
    if ((time <= 0 || time === null || time === undefined) ){
      logout();
    }else if(time>0 && time <= 60 && wasOpened === false && start && !update){
      if (Notification.permission === 'granted'){
        new Notification(notifyTitle,notifyOptions)
      }
      openModal();
      setWasOpened(true);
    }
  },[logout, notifyOptions, start, time, update, wasOpened])



  return(
    modal?
      <TokenModal show={modal} time={time}  close={()=>closeModal()}/>:
      <div/>
  )
}


function mapStateToProps(state){
  const { start, update } = state.navigation.sessionToken;
  const { expire } = state.authentication.token;
  return({
    start,
    update,
    expire,
  });
}


function mapDispatchToProps(dispatch){
  const { 
    hasUpdateTimeToken,
  } = localActions;
  const { logout } = authenticatedDjangoActions;

  return bindActionCreators({ 
    hasUpdateTimeToken,
    logout,
  },dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenManagement);