import React from "react";

// import dados pros formularios
import { Form } from 'react-bootstrap';
import { useFormikContext, Field  } from 'formik';

////redux
import { connect } from "react-redux";

//functions and config
import { filterIndexs } from '../../functions';
import { indexesShortNames, indexesIcons, default_alert_indexes } from '../../../../config';
import {renderIcon} from '../../../../components/renderIcon';


// css
import '../../alert.css';
import './../form.css';


const StandartForm = ({listIndex}) => {
  const {values, errors, handleChange, setFieldValue} = useFormikContext();
  // const indexs = getIndexs(listIndex);
  // const indexs = listIndex;
  const indexs = filterIndexs(listIndex, default_alert_indexes);
  
  
  const groupBy = (item) => {
    var newArr = [],
    types = {},
    i, j, cur;
    for (i = 0, j = item.length; i < j; i++) {
      cur = item[i];
      var location_new = {};
      if (!(cur.type in types)) {
          types[cur.type] = {type: cur.type, indexes: []};
          newArr.push(types[cur.type]);
    }
    location_new.id = i;
    location_new.title = cur.title;
    location_new.selected = false;
    types[cur.type].indexes.push(location_new);
  }
  return newArr;
  }


  const setGroup = (indexs) => {
    const location_old = [];
    indexs.map((item, index) => {
  
    const group = item.substring(0, item.indexOf("-")) 
    const location_new = {};
    
    location_new.type = group
    
    location_new.title = item;
    return location_old.push(location_new)
  
    }
    );
  return groupBy(location_old);
    
  }

 
  const renderItem = (item, index) => {
    const indexes = item.indexes
    let list = indexes.map((it, id) => {
        return ( 
        <label style={{width: "100%"}}>
          <li key={it.title} className={it.selected?"selected input-group-text":"input-group-text"}
         data-toggle="tooltip" data-placement="left" title={it.title} >
          <div className="input-group-item">
              {renderIcon[indexesIcons[it.title]]}  &nbsp; 
              {indexesShortNames[it.title]} 
          </div>
          <Field type="radio" name="picked" value={it.title} onClick={()=> setFieldValue('standartForm.index',it.title)} />
        
        </li>
        </label>
        )
    })
    return list
}

  const indexsGroup = setGroup(indexs);

  let nameError;
  let indexError;
  let termError;
  //name
  try{
    nameError = errors.standartForm.name;
  }catch (e){
    nameError = " ";
    
  }
  //index
  try{
    indexError = errors.standartForm.index;
  }catch (e){
    indexError = " ";
  }
  //term
  try{
    termError = errors.standartForm.term;
  }catch (e){
    termError = " ";
  }
  
  return(
    <div>
      
      <div className='textList  padding-redux'>      
        
        {/** name */}    
        <Form.Group>
          <Form.Label>Título</Form.Label>    
          <br/><small>Digite um titulo para o alerta</small>            
          <Form.Control type="text" value={values.standartForm.name}
           onChange={(e)=>{
            setFieldValue('advancedForm.name',e.target.value)
            setFieldValue('standartForm.name',e.target.value)
          }}/>
            <Form.Text className="text-muted error">
              {nameError}  
          </Form.Text>
        </Form.Group>
              
        {/** form - index */}
        <Form.Group>
          <Form.Label>Bases de dados</Form.Label> 
          <br/><small>Selecione a origem dos dados</small> 

          <div className="drop-content">
          { indexsGroup.map((item, idx)=> (
              <div className="drop-group" key={idx}>
              <span><strong>{(item.type).toUpperCase()}</strong></span>
                  <hr/>
              {renderItem(item, idx)}
              
              </div>
              
          ))}
          </div>

          <Form.Text className="text-muted error">
            {indexError}
          </Form.Text>
        </Form.Group>
      
      
      {/** Term */}
      <Form.Group>
        
        <Form.Label>Termo Monitorado</Form.Label>   
        <br/><small>Escreva o termo/frase que deseja monitorar</small>             
        <Form.Control type="text" name="standartForm.term" value={values.standartForm.term}  
          onChange={ handleChange }/>  
        <Form.Text className="text-muted error">
          {termError}
        </Form.Text>
      </Form.Group>
      </div>
  
    </div>
  );
}

const mapStateToProps = state => ({
  listIndex:state.indexed.listIndex,
});

export default connect(mapStateToProps)(StandartForm);