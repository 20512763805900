import React from 'react';

import { Modal, Button } from 'react-bootstrap';
//redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

//import da função p/ deletar os dados no banco
import { authenticatedDjangoActions } from '../../redux/actions';

const DeleteAlertModal = ({data, show, close, deleteAlert}) =>{
    const {name,id} = data;
    //functions
    async function handleDeleteAlert(){
        await deleteAlert(id);
        close();
    }
    return (
        <Modal
            show={show}
            onHide={close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title>Deletando alerta {name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Ao clicar em "Excluir Alerta", você confirma a exclusão do alerta: {name}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDeleteAlert}>Excluir Alerta</Button>
                <Button variant="secondary" onClick={close}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    );
}

function mapDispatchToProps(dispatch) {
    const { deleteAlert } = authenticatedDjangoActions;
    return bindActionCreators({
      deleteAlert
    }, dispatch)
  }


export default connect(null, mapDispatchToProps)(DeleteAlertModal);