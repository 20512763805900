import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegBell, FaUsersCog, FaPrint } from "react-icons/fa";
import { BsFolderSymlink, BsGear, BsBoxArrowInRight } from "react-icons/bs";

const icons = {
    "alerts": function () {return <FaRegBell />},
    "report": function () {return <FaPrint />},
    "folders": function () {return <BsFolderSymlink />},
    "admin": function () {return <FaUsersCog />},
    "cog": function () {return <BsGear/>},
    "logout": function () {return <BsBoxArrowInRight/>},
}

const labels = {
    "alerts": function () {return 'Gerenciar Alertas'},
    "report": function (count) {return count > 1 ? `${count} itens` : `${count} item`},
    "folders": function () {return `Pastas`},
    "admin": function () {return `Configurações`},
    "cog": function () {return ``},
    "logout": function () {return ``},
}

const ContentHeaderItem = ({icon, path, count = 0}) =>{ 

    return(
        <Link className={'nav-link text-center menu-icon link-toggle'} icon={icon} to={path}>
        {icons[icon]()}
            &nbsp;
        {labels[icon](count)}
    </Link>  
    );
}


export default ContentHeaderItem;