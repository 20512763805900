import React from "react";
import { getFieldName, formatDate, formatDateShort, strip_html_tags } from '../../../services/index';
import { fieldsToType, fieldsToLabels } from '../../../config';
import { Text, View } from '@react-pdf/renderer';

import { StyleSheet } from '@react-pdf/renderer';

const he = require('he');

const checkType = (_index, key, value) => {
    let field = null
    let type = getFieldName(fieldsToType[_index], key)  
    const textoCodificado = strip_html_tags(value)
    const textoDecodificado = he.decode(textoCodificado, {
        'allowUnsafeSymbols': true
      });
    switch (type) {
        case "hide":
            // console.log('É um id')
            break;
        case "longText":
            field = <View style={styles.sectionLarge} key={key}>
                    <Text style={styles.title}>{getFieldName(fieldsToLabels[_index], key)}:</Text>
                    <Text style={styles.description}>{textoDecodificado || 'N/I'}</Text>
                    </View>
            break;
        case "dateShort":
            field = <View style={styles.section} key={key}>
                    <Text style={styles.title}>{getFieldName(fieldsToLabels[_index], key)}:</Text>
                    <Text style={styles.description}>{formatDateShort(value) || 'N/I'}</Text>
                    </View>
            break;
        case "date":
            field = <View style={styles.section} key={key}>
                    <Text style={styles.title}>{getFieldName(fieldsToLabels[_index], key)}:</Text>
                    <Text style={styles.description}>{formatDate(value) || 'N/I'}</Text>
                    </View>
            break;
        default: 
            field = <View style={styles.section} key={key}>
                    <Text style={styles.title}>{getFieldName(fieldsToLabels[_index], key)}:</Text>
                    <Text style={styles.description}>{strip_html_tags(value) || 'N/I'}</Text>
                    </View>
    }
    return field
  }


  export const renderFieldsToPrint = (entityFields, _index) => {
    let fields = []
    let subFields = []
    try{
        for (var [key, value] of Object.entries(entityFields)) {
        if (Array.isArray(value)){
            try{
            subFields.push(<View style={styles.sectionLarge} key={key}>
                <Text style={styles.title}>{getFieldName(fieldsToLabels[_index], key).toUpperCase()}</Text>
                </View>
            )
            for (var [k, v] of Object.entries(value)){
                if (typeof v === 'object'){ 
                    for (var [y, z] of Object.entries(v)){
                        subFields.push(checkType(_index, y, z))                     
                    }
                   
                }
                else{
                    subFields.push(
                        <View style={styles.sectionLarge} key={k}>
                        <Text style={styles.title}>{++k}:</Text>
                        <Text style={styles.description}>{strip_html_tags(v) || 'N/I'}</Text>
                        </View>)
                }
            }
            }catch (e){
                // console.log('error 02: ', e);
            }
        }
        else if(typeof value ==='object'){
            let y = renderFieldsToPrint(value,_index)
            subFields.push(y.fields)
            subFields.push(y.subFields)
        }
        else  {
           fields.push(checkType(_index, key, value))
        }            
    }
    }catch (e){
        // console.log('error 01: ',e);
    }

return  <View style={styles.flexContainer}>
        
        {fields}
        <View style={styles.flexContainer}>
            {subFields}
        </View>
       
        </View>
  }


  export const renderHeadersToPrint = (item, text) => {
    
        return <View style={styles.header}>
                <Text>
                {item} - {text}
                </Text>
            </View>
  }
    // Create styles
export const styles = StyleSheet.create({
 
    section: {
      width: '33%',
      padding: 10,
      flexGrow: 1
    },
    sectionLarge: {
      width: '100%',
      padding: 5,
      flexGrow: 1,
      borderBottom: 1,
      borderColor: "#666",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        marginLeft: 5,
        border: 1,
        backgroundColor: "#fff",
        padding: 2,
        fontSize: 12,
      }, 
  title: {
      fontSize: 10,
      marginBottom: 5,
      textAlign: 'left',
      fontWeight: 'ultrabold'
      // fontFamily: 'Times-Roman',
  },
  description: {
    // width: '30%',
    fontSize: 10,
    fontWeight: 'light',
    textAlign: 'left',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // backgroundColor: '#fafafa',
  },
  });




