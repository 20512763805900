import React, { useState } from 'react'
import { FaPlus, FaWindowMinimize } from "react-icons/fa";
import { Accordion,  Card} from 'react-bootstrap';
import './styles.css'


export const IconToggle = ({isOpen, label, count }) => {
  
  return <span>
            <div className="input-group-item">
              <span style={{width: "100%"}}>{label}</span>
              
          {isOpen ? <FaWindowMinimize/> : <FaPlus/>}
        
          </div>
        </span>
}


function AccordionTable( props )  {
  const [open, setOpen] = useState(false);

  return (
    <Accordion defaultActiveKey="">
  <Card className="">
    <Accordion.Toggle as={Card.Header} eventKey="0" className="link-toggle header-filter" 
    onClick= {() => setOpen(!open)}> 
     <IconToggle label={props.label} count={props.count} isOpen={open} />
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
      <section>{props.children}</section> 
      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>

)
  }

export default AccordionTable;
