import React from 'react';

import { Formik } from 'formik';
import { Button, Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { indexesShortNames } from '../../config';
import { dataFields, indexesToDashboard } from '../../config';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { ptBR } from 'date-fns/locale';
import './dashboard.css';
registerLocale('ptBr', ptBR);
setDefaultLocale('ptBr');

const validate = values => {
    let errors = {};

    if (values.index_value === ""){
        errors.index_value = "Campo Requerido"
    }

    return errors;
}




export const FormQuery = ({elasticindex, handleSubmit}) =>{
    const indexs = indexesToDashboard(elasticindex);
    return (
        <Formik
            initialValues = {{
                index_value:"", 
                data_filter:{
                    field:"",start:new Date(),finish: new Date()},
                }}
            onSubmit = {(values)=>{
                handleSubmit(values);

            }}
            validate={validate}
        >
            {formData =>(
                <Form onSubmit={formData.handleSubmit} >
                    <Form.Row>
                        <Col xs={2}>
                            <Form.Control as="select" 
                                value={formData.values.index_value}
                                //name='index_value'
                                //onChange={formData.handleChange}>
                                onChange={(e)=>{
                                    formData.setFieldValue("index_value",e.target.value)
                                    formData.setFieldValue("data_filter.field","")
                                }}>
                                <option value="">{"Selecione..."}</option>
                                {indexs.map((index, idx) => {
                                    return <option key={idx} value={index}>{indexesShortNames[index]}</option>
                                })}
                            </Form.Control>
                            <Form.Text className="text-muted error">
                                {formData.errors.index_value ? formData.errors.index_value: ""}
                            </Form.Text>
                        </Col>

                        <Col xs={2}>
                            <Form.Control as="select" 
                                name = 'data_filter.field'
                                value={formData.values.data_filter.field}
                                onChange={formData.handleChange}>
                                <option value="">{"Selecione..."}</option>
                                {dataFields[formData.values.index_value].map((item, idx) => {
                                    return <option key={idx} value={item.name}>{item.label}</option>
                                })}
                            </Form.Control>
                        </Col>

                        <Col  xs="auto">
                            <DatePicker
                                selected={formData.values.data_filter.start}
                                onChange={date => formData.setFieldValue("data_filter.start", date)}
                                placeholder="Data de início da busca"
                                dateFormat="dd/MM/yyyy"
                                />  
                        </Col>

                        <Col  xs="auto">                                
                            <DatePicker
                                selected={formData.values.data_filter.finish}
                                onChange={date => formData.setFieldValue("data_filter.finish", date)}
                                placeholder="Data de fim da busca"
                                dateFormat="dd/MM/yyyy"
                                /> 
                        </Col>
                        
                        <Col xs="auto">
                            <Button type="submit" className="mb-2 nbutton btn dashboard-button">
                                Buscar
                            </Button>
                        </Col>
                        
                    </Form.Row>
                </ Form>
            )}
        </Formik>
        
    );
}



