export const indexesGroupNames = {
    "sisbo-*": "SisBO",
    "sisprocep-*": "SisProcep", 
    "receita-*": "Receita Federal",
    "agp-*": "Sistema legado",
    "sic-*": "Pessoas TJPI",
    "siapen-*": "SIAPEN",
    "arquivos-*": "Arquivos",
    "licitacoes-*": "Licitações",
    "pmpimobile-*": "PM Mobile",
    "contratos-*": "Contratos",
    "sinesp-*": "Sinesp-PPe",
}
export const indexesShortNames = {
    "ppe-boletins": "Boletim de Ocorrência-PPe",
    "sisbo-boletins": "Boletim de Ocorrência",
    "sisbo-personagens": "Personagem",
    "sisprocep-procedimentos": "Procedimento", 
    "sisprocep-vitimas": "Vítima",
    "sisprocep-autores": "Indiciado",
    "receita-pf": "Sócio",
    "receita-pj": "Empresa",
    "agp-usuarios": "Sistema legado",
    "sic-pessoas": "Pessoas TJPI",
    "sicaf-pessoas": "Pessoas",
    "siapen-internos": "Interno no SIAPEN",
    "siapen-visitantes": "Visitante no SIAPEN",
    "arquivos-dinte": "DINTE",
    "arquivos-type": "Por tipo",
    "arquivos-doe": "DOE",
    "licitacoes-licitacoes": "Licitações",
    "licitacoes-responsaveis": "Responsáveis P/ Licitação",
    "licitacoes-publicacao": "Publicações",
    "licitacoes-licitantes": "Licitante",
    "pmpimobile-atendimentos": "Atendimentos",
    "pmpimobile-envolvidos": "Envolvidos",
    "contratos-contratos": "Contratos",
    "contratos-responsaveis": "Responsáveis",
    "sinesp-personagens": "Personagem",
    "sinesp-procedimentos": "Procedimento",
}
export const indexesIcons = {
    "sisbo-boletins": "edit",
    "ppe-boletins": "edit",
    "sisbo-personagens": "person",
    "sisprocep-procedimentos": "collection", 
    "sisprocep-vitimas": "person",
    "sisprocep-autores": "person",
    "receita-pf": "person",
    "receita-pj": "pj",
    "agp-usuarios": "stop",
    "sic-pessoas": "listen",
    "sicaf-pessoas": "person",
    "siapen-internos": "userlock",
    "siapen-visitantes": "userfriend",
    "arquivos-dinte": "server",
    "arquivos-type": "server",
    "arquivos-doe": "server",
    "licitacoes-licitacoes": "server",
    "licitacoes-responsaveis": "person",
    "licitacoes-publicacao": "collection",
    "licitacoes-licitantes": "person",
    "pmpimobile-envolvidos": "person",
    "pmpimobile-atendimentos": "BsFillPersonCheckFill",
    "contratos-contratos": "server",
    "contratos-responsaveis": "person",
    "sinesp-procedimentos": "collection", 
    "sinesp-personagens": "person",
}
export const indexesFullNames = {
    "sisbo": "Sistema de Boletim de Ocorrência - SisBO",
    "sisprocep": "Sistema de Procedimentos Policiais - SisProcep", 
    "receita": "Dados Públicos da Receita Federal",
    "agp": "Sistema legado",
    "sic": "Sistema de Identificação de Cusódia do TJPI - SIC",
    "siapen": "Sistema de Administração Penitenciária - SIAPEN",
    "sicaf": "Sistema de Cadastro de Faccionados",
    "arquivos": "Arquivos",
    "licitacoes": "Licitações SIAF",
    "pmpimobile": "PMPI Mobile",
    "contratos": "Contratos SIAF",
    "sinesp": "Procedimentos Policiais Eletrônicos - PPe",
}