import React, { Component } from 'react';
import { globalConstants } from '../../redux/constants';

import './folder.css';


class SideBar extends Component {
  render() {
    const { hideNewFolderModal } = this.props;
    return (
      <nav className='folder-sidebar'>
        <div>
          <a href='/#' className='btn btn-light button-new' type="button" data-toggle="modal" 
           data-target=".bd-example-modal-lg" onClick={hideNewFolderModal}>
            <i className='fas fa-plus-circle fa-lg'/>
            &nbsp;Novo
          </a>
        </div>
        <ul >
          <li className='sidebar-item'>
            <a   href={`${globalConstants.baseAppName}/folderpage`}>
              <i className='fas fa-folder fa-lg '/>
              &nbsp;Meus Arquivos
            </a>
          </li>
        </ul> 
      </nav>   
    );
  }
}


export default SideBar;
