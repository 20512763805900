import React, { useState, useEffect, useRef } from "react";
import { BoxIndex } from "../../components/indexInfo";
import { elasticServices, groupByIndex, formatDate } from "../../services";
import {renderIcon} from '../../components/renderIcon';
import { indexesShortNames, indexesFullNames, indexesIcons } from '../../config';
import Loading from '../../common/template/loading';
import { Messages } from 'primereact/messages';

import './styles.css';

const CountDocument = ({elasticindex}) => {
    const [location, setLocation] = useState([]);
    const [loading, setLoading] = useState(true);
    const messages = useRef(null);

    useEffect(() => {
        getIndex()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const showError = (msg) => {
        messages.current.show({severity:'error', summary: '', detail: msg, life: 5000});
      }

    const getIndex = async () => {
        const location_old = [];
        await elasticindex.map(async (index_name) => {
            const group = index_name.substring(0, index_name.indexOf("-")) 
            const location_new = {};
            location_new.type = group
            location_new.title = index_name;
            
            location_new.timestamp = await elasticServices.getLastDocument(index_name)
            
            location_new.count = await elasticServices.getIndexCount(index_name)

            if (location_new.count === 0) {
                showError("Occorreu um erro no sistema de busca.");
                setLoading(false)
            }
            
            location_old.push(location_new)
            setTimeout(() => {
                setLocation(groupByIndex(location_old))
                setLoading(false)
            }, 1000);
        }); 
    }

    const renderItem = (item) => {
        const indexes = item?.indexes
        let list = indexes.map((it, index) => {
            return (
            
            <div className="index_info-box" key={index} >
            
                <BoxIndex icon = {renderIcon[indexesIcons[it.title]]}
                    title = {indexesShortNames[it.title]}
                    message = "Quantidade de registros:  "
                    text = {it.count}
                    timestamp = {` Última atualização: ${formatDate(it.timestamp)}`} 
                    />
            </div>
          
            )
        })
        return list
    }

    const  indices = location && location.map((item, index) => {
                    if (item.indexes[0]) {
                    // if (item.indexes[0].timestamp) {
                      return <div className="drop-group" key={index}>
                      <span><strong>{indexesFullNames[item.type]}</strong></span>
                          <hr/>
                      {renderItem(item, index)}
                      
                      </div>
                    }
                    return null
                }
                  );

                  
    const showIndices = () => {
        if(indices.filter((i) => i).length > 0) {
            return <div>
            <span><strong>FONTES DE DADOS DISPONÍVEIS</strong></span>
            <div className="drop-content">
                {indices}
                </div>
            </div>
        }
        else {
            showError("Occorreu um erro no sistema de busca.")
        }
    }
    return <div className="index_info">
    {loading ? <Loading message="Carregando..."/> : showIndices()}
    <Messages ref={messages}></Messages>
    </div>
    
}



export default CountDocument;