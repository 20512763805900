import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { BsX } from 'react-icons/bs'
import { IconContext } from "react-icons";

const ModalBackground = styled.div`
  background: #333;
  opacity: 0.95;
  height: -webkit-fill-available;
  margin-top: 0;
  margin-bottom: 0;
  width: 50%;
  position: fixed;
  top: 0;
  right: 0;
  border: 10px solid #424242;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);

  @media(max-width: 576px) {
    width: 100%;
  }

  
  `;
  
const ModalWrapper = styled.div`
  width: 98%;
  margin-top: 50;
  margin-bottom: 30;
  background: transparent;
  height: 100vh;
  z-index: 999999;
`;

const ModalHeader = styled.div`
  top: 0;
  margin-top: 5%;
  display: flex;
  background: transparent;
  font-size: 0.6875rem;
  line-height: 1.2;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #666;
  height: 3rem;
`;

const ModalTitle = styled.h2`
  width: 100%;
  margin: 0;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 3.5;
  color: white;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-align: center;
`;

const IconContainer = styled.div`
  width: 3rem;
  // height: 3rem;
  /*background: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  cursor: pointer;
`;

const Modal = ({ showCloseIcon, onClose, title, children, className }) => {
  const closeIcon = showCloseIcon ? (
    <IconContainer onClick={onClose}>
         <IconContext.Provider value={{ className: "modal-class-icons" }}>
          {<BsX/>}
          </IconContext.Provider>
    </IconContainer>
  ) : (
    // hold open space for centered title
    <IconContainer />
  );

  const modalMarkup = (
    <ModalBackground className={className}>
      <ModalWrapper>
          {closeIcon}
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        {children}
      </ModalWrapper>
    </ModalBackground>
  );

  return ReactDOM.createPortal(modalMarkup, document.body);
};

Modal.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node.isRequired,
  /**
   * Close handler for modal button
   */
  onClose: PropTypes.func,
  /**
   * Title text to show in header
   */
  title: PropTypes.string.isRequired,
  /**
   * Whether to show a close icon
   */
  showCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  onClose: null,
  showCloseIcon: true,
};

export default Modal;
