import React, { Component } from "react";


import { Link/*, Redirect*/ } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators} from 'redux';

import { djangoActions, alertActions, localActions } from '../../redux/actions';
import { globalConstants } from '../../redux/constants';

import { Message } from '../../components/message_alert';

import { EmailModal } from './emailModal';

import Apresentacao from "../apresentacao/apresentacao";
import Loading from '../../common/template/loading';
import './styles.css';
import Logo from "../../assets/sinfor_logo.png";

// import ConfirmToast from "../../components/ConfirmToast";

// modal
require('dotenv').config()


class loginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        username: "",
        password: "",
      },
      // recaptchaResponse: "",
      // recaptchaExpire: true,
      error: "",
      submitted: false,
      modal: false,
      modalType: "",
      time: 10,
      two_factor: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this); 
    this.props.stopLoading();
    
    // this.recaptchaChange = this.recaptchaChange.bind(this); 
    // this._reCaptchaRef = React.createRef();
    
  }
  
  
  // recaptchaChange(recaptchaResponse){
  //   this.setState({recaptchaResponse})
  //   if (recaptchaResponse === null){
  //     this.setState({recaptchaExpire:true})
  //   }else{
  //     this.setState({recaptchaExpire:false})
  //   }
  // }
  
  
  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }
  
  
  handleSubmit(event) {
    event.preventDefault();
    
    this.setState({ submitted: true });
    const { user } = this.state;
    // const { user, recaptchaExpire } = this.state;
    const { login, warning, error } = this.props;
    // if (recaptchaExpire === true ){
    //   warning('resolva o desafio captcha para continuar!');
    // }else {
    if (!user.username || !user.password) {
      warning('Preencha todos os campos para continuar!');
    } else {
      try {
        login(user);
      } catch (err) {
        error('Houve um problema com o login.');
      }
    }
    // }
  }
  
  
  resend_code(email) {
    const { resendCodeEmail } = this.props;
    resendCodeEmail(email)
    this.closeModal();
  }
  
  reset_password(email) {
    const { resetPassword } = this.props;
    resetPassword(email);
    this.closeModal();
  }
  
  showModal(type, event) {
    event.preventDefault();
    this.setState({ modal: true, modalType: type });
  }
  
  
  closeModal() {
    //limpando o alert após um tempo
    setTimeout(() => {
      alertActions.clear()
      // fechando modal
      this.setState({
        modal: false
      });
    }, 5000);
  }
  
  
  cancelModal() {
    // fechando modal
    this.setState({
      modal: false
    });
  }
  
  render() {
    const { isPreLogged, isLogged, loggedOut, isLoading, clearLastRoute, redirect } = this.props;
    const { alert } = this.props;
    const { user, modal, modalType } = this.state;
    // const { REACT_APP_RECAPTCHA_KEY } =  process.env;

    if (isPreLogged && !isLogged) {
      setTimeout(()=>{
        redirect(`${globalConstants.baseAppName+'/two_factor'}`);
      },500);
    }else if(isLogged && !loggedOut){
      setTimeout(()=>{
        redirect();
      },500);  
    }

    // if (isPreLogged && !loggedOut) {
    //   setTimeout(()=>{
    //     this.setState({two_factor: true})
    //     redirect(`${globalConstants.baseAppName+'/two_factor'}`)
    //     // redirect(lastRoute)
    //   },500);
    // }
    if (loggedOut) {
      clearLastRoute();
    }

 
    const modals = {
      "resend": {
        "request": (email) => this.resend_code(email),
        "show": this.state.modal,
        "onHide": (event) => this.cancelModal(event),
        "textTitle": "Reenviar código de ativação",
        "textBody": "Caso não tenha recebido o código de ativação, solicite digitando o email cadastrado.",
      },
      "recoveryPass": {
        "request": (email) => this.reset_password(email),
        "show": this.state.modal,
        "onHide": (event) => this.cancelModal(event),
        "textTitle": "Esqueceu sua senha?",
        "textBody": "Caso tenha esquecido a senha, solicite a troca digitando o email cadastrado.",
      }
    }
    
    
    let alertComponent = alert.message ? <Message icon={alert.icon} text={alert.message} alert={alert.type} /> : ""
    
    let formSubmit = <form onSubmit={this.handleSubmit}>     
    {alertComponent}
         
    <input className='login-input' type="text" name="username" value={user.username} placeholder="Nome de usuário" onChange={this.handleChange} />
 
    <input className='login-input' type="password" name="password" value={user.password} placeholder="Senha" icon='lock' onChange={this.handleChange} />
    {/* <center>
      <ReCAPTCHA
      sitekey={REACT_APP_RECAPTCHA_KEY}
      onChange={this.recaptchaChange}
      />
    </center> */}
    <button className='login-button'  type="submit"><span>Fazer login</span></button>

    <div style={{ paddingTop: "10px" }}>
      <center>
        ou
    </center>
      <center >
        <div className="fontSmall link-button"
          onClick={this.showModal.bind(this, "recoveryPass")} >Esqueceu a senha?
       </div>
      </center>
    </div>
    <hr />
    {// links para redefinir senha e outros 
    }
    <div>
      <div style={{ paddingTop: "10px" }}>
        <center>
          <div className="fontSmall link-button"
            onClick={this.showModal.bind(this, "resend")} >Reenviar código de ativação
        </div>
        </center>
      </div>
      <div className="" style={{ paddingTop: "10px" }}>
        <center>Não tem uma conta? <Link to={globalConstants.baseAppName + "/registrar"}>Cadastre-se</Link></center>
      </div>
    </div>
    {// links para redefinir senha e outros 
      // modal
    }
    {modal && <EmailModal alert={alert} values={modals[modalType]} />}
  </form>

    let loginAuth = isLoading ? <Loading /> : formSubmit
    
    return (

      <div className="container-login">
        
          {/* <ConfirmToast /> */}

          <Apresentacao idHref={'#login'} label={'Fazer Login'} />

      
        <div id="login" className="div-hight">
          <div className="imgLogoSinfor">
            <img  src={Logo} alt="Sinfor logo" />
          </div>
          <div className="div-hight-center">
            <h2>Login</h2>
          {loggedOut ? <div className={`alert ${alert.type}`}>Logout realizado com sucesso!</div> : ''}
          {loginAuth}
        </div>
        </div>
        {/* <Messages /> */}
      </div>

    );
  }
}
  
function mapStateToProps(state) {
  const { isLogged, loggedOut, isLoading, isPreLogged  } = state.authentication;
  const { alert } = state;
  const { lastRoute } = state.navigation;
  return {
    isLogged,
    loggedOut,
    isLoading, 
    alert,
    lastRoute,
    isPreLogged,
  };
}

function mapDispatchToProps(dispatch){
  const { error, warning } = alertActions;
  const { login, resetPassword, resendCodeEmail, stopLoading } = djangoActions;
  const { clearLastRoute, redirect } = localActions;
  return  bindActionCreators({
    error,
    warning, 
    stopLoading,
    login, 
    redirect,
    resetPassword,
    resendCodeEmail,
    clearLastRoute,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(loginPage);
