import { djangoConstants, localConstants } from '../constants';

export function reportReducer(state = {reportList:[]}, action) {
    switch (action.type) {
        case localConstants.setReportList:
            return {reportList: action.payload.reportList};
        case djangoConstants.logoutSuccess:
            return {reportList:[]}
        case 'persist/REHYDRATE':
            if (action.payload){
                return {...action.payload.report }  
            }  
            return {reportList:[]}      
        
        default:
            return state;
    }
    }