import React, { useState, useEffect} from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IconContext } from "react-icons";

//icons
import { MdPlaylistAdd } from 'react-icons/md';
import { CgPlayListRemove } from 'react-icons/cg';
import { localActions } from '../../redux/actions';

const labels = {
    "false":"Adicionar ao relatório",
    "true":"Remover do relatório",
}

const icons = {
    "false": function (){return <MdPlaylistAdd />},
    "true": function (){return <CgPlayListRemove />},
}


const ToggleAddToReport = ({item, reportList, setReportList}) =>{
    const [inReport, setInReport] = useState(false);

    const add = () =>{
        let reportListWith = reportList.concat(item);
        setReportList(reportListWith);
    } 
        
    const remove = () => {
        let reportListWithout = reportList.filter(listElement=>{
            return item._id !== listElement._id; 
        })
        setReportList(reportListWithout)
    }   


    useEffect(() => {
         setInReport(reportList.filter(listElement=>{
            return item._id === listElement._id;
        }).length>=1);
    },[item._id, reportList, setInReport]);
    
    const onClick = () =>{
        if (inReport){
            remove();
        }else{
            add();
        }
    }
     
    return(
        <div className="d-flex flex-row-reverse" >
            <div style={{marginTop: "10px"}}
            data-toggle="tooltip" data-placement="center" title={labels[inReport]}> 
            <label>
            <IconContext.Provider value={{ className: "details-class-icons" }}>
                {icons[inReport]()}
            </IconContext.Provider>
            <input type="checkbox"
                checked={inReport}
                onChange={onClick}
                />
            </label>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const reportList = state.report.reportList || [];
    return { reportList };

}

const mapDispatchToProps = (dispatch) => {
    const { setReportList } = localActions;
    return bindActionCreators({
        setReportList
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(ToggleAddToReport);