import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import ContentHeader from '../../common/template/contentHeader';
import { Footer } from '../../components/footer';

import './styles.css'

import { connect } from 'react-redux';
import Menu from '../../common/template/horizontalMenu';
import Breadcrumb from '../../common/template/breadcrumb'
import ContentBody from '../../common/template/contentBody';
import { Logo } from '../../components/logo'
import { ScrollTop } from 'primereact/scrolltop';

class BasePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: [],
            app_index: ''

        };
       
    }

    
    render() {
        /** porps */
        const { level1, level2, searchBar, sidebar, sidebarIcon, searchFilter } = this.props;
        
        
        return (
        
                <div className="container">
                    <div className='content-hight'>
                        
                        <ContentHeader brand={true} border={false} color={true} shadow={false}>
                            <Logo/>
                            {searchBar}
                            {sidebarIcon} 
                        </ContentHeader>
                        
                        <Menu bread={<Breadcrumb level1={level1} level2={level2}/>}>
                            {sidebar}
                        </Menu>
                            
                        {searchFilter}
                        <ContentBody>
                            <div className="w3-container ">
                            {this.props.children}
                            </div>
                        </ContentBody>
                        
                        <Footer/>
                    </div>
                <ScrollTop className="custom-scrolltop" icon="pi pi-arrow-up" />
                </div>
        );
    }
}

 function mapStateToProps(state) {
    

     return {  };
 }
 function mapDispatchToProps(dispatch) {
    
     return bindActionCreators({
       
     }, dispatch)
 }

export default connect(mapStateToProps, mapDispatchToProps)(BasePage)