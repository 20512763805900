import { djangoConstants } from '../constants';

export function getUserReducers (state = { user_data: {} }, action) {
    switch (action.type) {
      case djangoConstants.setUser:
        return { user_data: action.payload.user, isValidToken: true }
      case djangoConstants.unsetUser:
        return { isValidToken: false }
      case djangoConstants.logoutSuccess:
        return { user_data:{} }
      case 'persist/REHYDRATE':
        if (action.payload){
          return {...state, ...action.payload.user}     
        }  
        return { user_data: {} }     
      default:
        return {...state};
    }
    
}

