export const djangoConstants = {
  loginSuccess: 'DJANGO_USER_LOGIN_SUCCESS',
  loginRequest: 'DJANGO_USER_LOGIN_REQUEST',
  loginFail: 'DJANGO_USER_LOGIN_FAILURE',

  stopLoading: 'DJANGO_USER_STOP_LOADING_ANIMATION',
  playLoading: 'DJANGO_USER_PLAY_LOADING_ANIMATION',

  twoFactorSuccess: 'DJANGO_USER_TWO_FACTOR_SUCCESS',
  twoFactorRequest: 'DJANGO_USER_TWO_FACTOR_REQUEST',
  twoFactorFail: 'DJANGO_USER_TWO_FACTOR_FAILURE',

  twoFactorRecoveryConfirm:"DJANGO_USER_TWO_FACTOR_RECOVERY_CONFIRM",

  registerRequest: 'DJANGO_USER_REGISTER_REQUEST',
  registerSucess: 'DJANGO_USER_REGISTER_SUCCESS',
  registerFail: 'DJANGO_USER_REGISTER_FAILURE',

  logoutRequest: 'DJANGO_USER_LOGOUT_REQUEST',
  logoutSuccess: 'DJANGO_USER_LOGOUT_SUCCESS',
  
  
  setToken:"DJANGO_SET_TOKEN",
  setUser:"DJANGO_SET_USER",
  setListIndex: "DJANGO_SET_LIST_INDEX",
  setAppIndex: "DJANGO_SET_APP_INDEX",
  unsetUser:"DJANGO_INVALID_TOKEN",
  
  // alerts
  getAlerts: 'DJANGO_GET_ALERTS',
  getAlertsError: "DJANGO_GET_ALERTS_ERROR",
  deleteAlert: 'DJANGO_DELETE_ALERT',
  setAlert: "DJANGO_SET_ALERT",
  editAlert: "DJANGO_UPDATE_ALERT",

  // admin
  setLevelList:"DJANGO_GET_ADM_LEVEL_LIST",
  setUserList:"DJANGO_GET_ADM_USER_LIST",


}