import React, { Component } from 'react';

// import MenuItem from './menuItem';
// import { globalConstants } from '../../redux/constants';
import GoBack from '../../components/goBack'
import './horizontalMenu.css';

class Menu extends Component {

    render() {

        return (
            <div className="menu shadow">
                <div className="row">

                {this.props.bread}

                <ul>
                <GoBack/>

                    <li>
                    {/* <MenuItem path={`${globalConstants.baseAppName +'/results'}`} label=' Pesquisas' icon='search' /> */}
                    {/* <MenuItem path={`${globalConstants.baseAppName +'/pessoas'}`} label='Pessoas' icon='users' /> */}
                    </li>
                    {/* <li>
                        <MenuItem path={`${globalConstants.baseAppName +'/dashboard'}`} label='Dashboard' icon='chart-line' />
                    </li>

                    <li>
                        <MenuItem path={`${globalConstants.baseAppName +'/images'}`} label='Imagens' icon='image' />
                    </li>
                    <li>
                       <MenuItem path={`${globalConstants.baseAppName +'/mapas'}`} label='Mapas' icon='map-marker-alt' />
                    </li>
                    <li>
                        <MenuItem path={`${globalConstants.baseAppName + '/alertpage'}`} label='Alertas' icon='bell' />
                    </li> */}
                </ul>
                </div>

                {this.props.children}

            </div>
        )
    }
}

export default Menu;