
function millisInSeconds(millis){
  return Math.round(millis/1000)
}

export async function getDateDiff(finalDate){
  const now = await new  Date();
  const expireDate = await new Date(finalDate);
  let diff = await expireDate.getTime() - await now.getTime();
  return await millisInSeconds(diff);
  
}