import { alertConstants } from '../../constants';

export const alertActions = {
    success,
    warning,
    error,
    clear,
    clearIn,
};

function success(message) {
    return dispatch =>{
        dispatch( { type: alertConstants.success, message });
    } 
}

function warning(message) {
    return dispatch =>{
        dispatch({ type: alertConstants.warning, message });
    } 
}

function error(message) {
    return dispatch =>{
        dispatch({ type: alertConstants.error, message });
    } 
}

function clear() {
    return dispatch =>{
        dispatch({ type: alertConstants.clear });
    } 
}


function clearIn(time = 10){
    return dispatch =>{
        setTimeout(()=>{
            dispatch({ type: alertConstants.clear });
        }, time * 1000);
    } 
}