import React, { Component } from 'react';
import Logo from '../../assets/sinfor_logo.png';
import './styles.css';
import GoBack from '../../components/goBack';

export default class NotFound extends Component {

    render() {
       const err = <h2 style={{color:'red'}}>Ops! Erro.</h2>
       const desc = <span><b>A página que você procura não foi encontrada.</b></span>
        return (
            <div className="div-container">
                    <img className="imgLogoHome" src={Logo} alt="Sinfor logo" />
                   
                    <div className="col-md-12 div-bar" >
                    {err} 
                    {desc} 
                    <GoBack/>
                    </div>
              
            </div>
        );
    }
} 