import React from 'react';

import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { globalConstants } from '../../redux/constants';
import BasePage from '../homePage/base';
import AlertDismissibleExample from '../../components/alert'

import './admin.css'
import { authenticatedDjangoActions } from '../../redux/actions';

class AdminHome extends React.Component {   
    async componentDidMount(){
        //await this.props.setLevelList();
    }
    render() {
        const { is_admin_react,is_gestor, alert } = this.props;
        let alertComponent = <AlertDismissibleExample variant={alert.type} title={alert.title} message={alert.message}/>
        const CheckStatus = () => { 
            return (
                <div className="container-admin flex flex-wrap">
                { (!is_admin_react && !is_gestor) && 
                    <p>Você não tem permissão para acessar esse recurso</p>
                }
                {  (is_admin_react || is_gestor) && 
                    <Link className=" item flex-item-1" to={`${globalConstants.baseAppName}/admin/users`}>Gestão de Usuários</Link>                      
                }

                </div>  
            );
        }
        return (
            <BasePage level1={<li className="current"><b>{'Gestão'}</b></li>} >
                <div style={{position: 'absolute', top: 0, right: 0 }}>
                    {alert.type ? alertComponent : ''}
                </div> 
                <div className="w3-container ">
                        
                    <h5>Administração do Sistema</h5>
                    <CheckStatus />
                </div>
            </BasePage>
        );
    }
}
function mapStateToProps(state) {
    const { node_unidade } = state.user.user_data;
    const { is_admin_react, is_gestor } = state.user.user_data;
    const { admin } = state;
    const {alert} = state;

        
    return {
        node_unidade, admin, alert, is_admin_react, is_gestor
    };
}

function mapDispatchToProps(dispatch){
    const { setLevelList } = authenticatedDjangoActions;
    return bindActionCreators({
        setLevelList
    },dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);             