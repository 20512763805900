import { djangoConstants } from "../constants";

export function setLevelList(state = {}, action) {
    switch (action.type) {
        case djangoConstants.setLevelList:
            return {...state, lvlList: action.payload.lvlList};
        case djangoConstants.setUserList:
            return {...state, userList: action.payload.userList};
        default:
            return state;
    }
}