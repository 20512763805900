import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ReactiveList,
  // CategorySearch, 
  DataSearch,
  // SingleDropdownRange,
  ResultCard,
  ReactiveBase
} from '@appbaseio/reactivesearch';
// import Content from '../../common/template/contentBody'
import { djangoActions } from '../../redux/actions';
import { bindActionCreators } from 'redux';
import './styles_imagePage.css';
import BasePage from '../homePage/base'
import {  tokenElastic, REACT_APP_PROXY_ELASTIC_URL } from '../../services/index'

const { ResultCardsWrapper } = ReactiveList;

const onResultStats = (stats) => (
  <div className="flex justify-end resultstatus">
    {stats.numberOfResults} resultados encontrados em {stats.time}ms
  </div>
);


// const onData = (data) => (

//   <div >
//     <ResultCard key={data._id} >
//       {/* <a href={data.image.original}>  */}

//       <ResultCard.Image src={data.image.medium} />

//       {/* </a> */}

//       <ResultCard.Title
//         dangerouslySetInnerHTML={{
//           __html: data.name
//         }}
//       />

//       <ResultCard.Description>
//         <span>
//           Gênero: {data.genres}
//         </span>
//         <div>
//           <span>
//             Data: {data.premiered}
//           </span>
//         </div>
//       </ResultCard.Description>
//     </ResultCard>
//   </div>
//   // ),
//   // url= data.listing_url,
// )

class ImagePage extends Component {

  componentDidMount() {
    const { user_data,
      //  data,
      setFields,
      //  get_user 
    } = this.props
    // get_user(data.token)
    setFields(user_data);
  }



  render() { 
    // const { REACT_APP_PROXY_ELASTIC_URL } = process.env;
        
    return (
      <BasePage level1={<li className="current"><b>{'Imagens'}</b></li>} >
       <h5>Pesquisa por imagens</h5>

           
          {/* <div className="left-col">

            <SingleDropdownRange
              className="range"
              componentId="datapremiered"
              dataField="premiered"
              placeholder="Lançado em..."
              title="Data de lançamento"
              filterLabel="Lançamento"
              data={[
                {
                  start: '1990-01-01',
                  end: '1995-12-31',
                  label: '1990-1995',
                },
                {
                  start: '1995-01-01',
                  end: '2000-12-31',
                  label: '1995-2000',
                },
                {
                  start: '2000-01-01',
                  end: '2005-12-31',
                  label: '2000-2005',
                },
                {
                  start: '2005-01-01',
                  end: '2010-12-31',
                  label: '2005-2010',
                },
                {
                  start: '2010-01-01',
                  end: '2015-12-31',
                  label: '2010-2015',
                },
                {
                  start: '2015-01-01',
                  end: '2020-12-31',
                  label: '2015-2020',
                },
              ]}
            />
          </div> */}

         
            <ReactiveBase
              app="sic-imagens,sicaf-imagens"
              url={REACT_APP_PROXY_ELASTIC_URL+'/api_elastic'}
              credentials={tokenElastic}
            >
              <DataSearch
                className="search"
                componentId="searchresult"
                dataField={["name"]}
                categoryField="name"
                fieldWeights={[1, 3]}
                placeholder="Procurar por foto"
                autosuggest={true}
                highlight={false}
                highlightField="genres"
                queryFormat="or"
                fuzziness={0}
                debounce={100}
                react={{
                  and: ['item.name']
                }}
                showFilter={true}
                filterLabel="Filter"
                URLParams={false}
              />


              <ReactiveList
                componentId="result"
                dataField="name"
                title="Results"
                size={30}
                pagination={true}
                react={{
                  and: ["searchresult"]
                }}
                style={{
                  width: "100%",
                  textAlign: "center"
                }}
                renderResultStats={stats => onResultStats(stats)}
                // renderItem={data => onData(data)}
                innerClass={{
                  resultStats: 'result-stats',
                  list: 'list',
                  listItem: 'list-item',
                  image: 'image',
                }}
                render={({ data, index }) => (
                  <div className="imagem"> 
                   <ResultCardsWrapper key={index} >
                    {data.map(item => (
                      <ResultCard key={item._id}>
                        {/* <a href={item.image.original}>  */}

                        <ResultCard.Image src={item.avatar_file_patch} />

                        {/* </a> */}

                        <ResultCard.Title
                          dangerouslySetInnerHTML={{
                            __html: item.name
                          }}
                        />

                        <ResultCard.Description>
                          {/* <span>
                            Gênero: {item.genres}
                          </span> */}
                          <div>
                            <span>
                              Nasimento: {item.created_at}
                            </span>
                          </div>
                        </ResultCard.Description>
                      </ResultCard>
                    ))}
                  </ResultCardsWrapper>
                  </div>
                )}
              />
            </ReactiveBase>
      </BasePage>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.authentication;
  const { fields } = state.fields;
  const { alert } = state;
  const { searched } = state;
  const { user_data } = state.user;
  return { data, alert, fields, searched, user_data };

}

function mapDispatchToProps(dispatch) {
  const { setFields, get_user } = djangoActions;
  return bindActionCreators({
    setFields,
    get_user
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagePage);
