import { elasticConstants } from '../constants';

export function editCommentsReducer(state=null,action){
  switch (action.type) {
    case elasticConstants.editComments:
      return action.payload.edit_comment
    case elasticConstants.cancelEditComments:
      return null
    default:
      return state 
  }

}



export function commentsReducer(state = [], action) {
  switch (action.type) {
    case elasticConstants.getComments:
      return action.payload.comments;
    default:
      return state 
  }
}