import React from "react";
//import { Formik } from 'formik';

// css
import '../../alert.css';
import './../form.css';

// import dados pros formularios
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

////redux
import { connect } from "react-redux";

//functions and config
import { filterIndexs, typeList } from '../../functions';
import { indexesShortNames, default_alert_indexes } from '../../../../config';

// import dos formularios especificos ao type de alerta
import Frequency from './Frequency';
import BlackList from './BlackList';
import NewTerm from './NewTerm';

// função para pegar formulario tipo 
export const selectAdvancedForm = {
  "frequency": function () {
    return <Frequency />
  },
  "blacklist": function () {
    return <BlackList  />
  },
  "new_term": function () {
    return <NewTerm  />
  },
}



//Component
const AdvancedForm = ({ listIndex}) => {
  const {values, errors, handleChange, setFieldValue} = useFormikContext();
  const indexs = filterIndexs(listIndex, default_alert_indexes);

  let nameError;
  let indexError;
  let typeError;

  //name
  try{
    nameError = errors.advancedForm.name;
  }catch (e){
    nameError = " ";
    
  }
  //index
  try{
    indexError = errors.advancedForm.index;
  }catch (e){
    indexError = " ";
  }
  //type
  try{
    typeError = errors.advancedForm.type;
  }catch (e){
    typeError = " ";
  }



  return (
    <div>
      <div className='textList padding-redux'>      
        
        {/** name */}    
        <Form.Group>
          <Form.Label>Título</Form.Label>    
          <br/><small>Digite um titulo para o alerta</small>            
          <Form.Control type="text" value={values.advancedForm.name}
            onChange={(e)=>{
              console.log(e)
              setFieldValue('advancedForm.name',e.target.value)
              setFieldValue('standartForm.name',e.target.value)
            }}/>
            <Form.Text className="text-muted error">
              {nameError}
          </Form.Text>
        </Form.Group>
              
        {/** form - index */}
        <Form.Group>
          <Form.Label>Bases de dados</Form.Label> 
          <br/><small>Selecione a origem dos dados</small> 
          <Form.Control as="select" name="advancedForm.index" value={values.advancedForm.index} 
            onChange={(e)=>{
              setFieldValue('advancedForm.index',e.target.value)
              setFieldValue('standartForm.index',e.target.value)
            }}>
              <option value="">Selecione...</option>
              { indexs.map((index, idx)=>{
                return <option key={idx} value={index}>{indexesShortNames[index]}</option>
              })}
          </Form.Control>
          <Form.Text className="text-muted error">
            {indexError} 
          </Form.Text>
        </Form.Group>
            
        {/** form - type */}
        <Form.Group>
          <Form.Label>Tipo de Alerta</Form.Label>               
          <Form.Control as="select" name="advancedForm.type" value={values.advancedForm.type}  
            onChange={ handleChange }>
              { typeList.map((type, idx)=>{
                return <option key={idx} value={type.type}>{type.name}</option>
              })}
          </Form.Control>
          <Form.Text className="text-muted error">
            {typeError}
          </Form.Text>
        </Form.Group>
        {/** varições dos formulários */}    
        {selectAdvancedForm[values.advancedForm.type]()}

      </div>
  
    </div>
  );
}

const mapStateToProps = state => ({
  listIndex:state.indexed.listIndex,
});

export default connect(mapStateToProps)(AdvancedForm);