export const fieldsToImage = {
    "avatar_file_patch": "https://www.tjpi.jus.br/sic/system/photos/avatars/", 
    "fotos": "https://10.0.52.108/media/", 
    
}
export const externalResourceImg = {
    "sic-fotos": "/sic/system/photos/avatars/", 
    "sicaf-fotos": "/media/", 
    
}

export const descriptionImg = {
    "sic-fotos": {"title":"name", "subtitle":"descprition"}, 
    "sicaf-fotos": {"title":"legenda", "subtitle":""},
}
